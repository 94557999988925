import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Overlay from '../Overlay';
import SvgIcon from '../SvgIcon';
import { keyCodes } from '../../lib/constants';
import './Modal.scss';

const Modal = ({
    children,
    footer,
    header,
    headerTitle,
    contentPadding,
    noContentPadding,
    noContentScroll,
    fillViewport,
    background,
    onClose,
    noDimensionRestrictions,
    overlayTheme,
    search,
    isBookSectionMobile,
    isVideos,
    interviewCard,
    swapStoryModal,
    languageModal,
}) => {
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.keyCode === keyCodes.esc && onClose) {
                onClose();
            }
        };

        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onClose]);

    const baseClassName = 'pb-modal';

    const isRightToLeftDirection =
        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur';

    const classes = classNames({
        [baseClassName]: true,
        [`${baseClassName}--content-padding`]: contentPadding,
        [`${baseClassName}--no-content-padding`]: noContentPadding,
        [`${baseClassName}--footer`]: footer,
        [`${baseClassName}--header`]: header,
        [`${baseClassName}--header-title`]: headerTitle,
        [`${baseClassName}--fill-viewport`]: fillViewport,
        [`${baseClassName}--no-content-scroll`]: noContentScroll,
        [`${baseClassName}--bg-${background}`]: background,
        [`${baseClassName}--no-dimension-restrictions`]:
            noDimensionRestrictions,
        [`${baseClassName}--closable`]: onClose,
        [`${baseClassName}--interview-card`]: interviewCard,
        [`${baseClassName}--book-section`]: isBookSectionMobile || isVideos,
        [`${baseClassName}--swap-story-modal`]: swapStoryModal,
    });

    const myLanguage = localStorage.getItem('locale');

    const closeClasses = classNames({
        [languageModal
            ? `${baseClassName}__language-modal-close`
            : isRightToLeftDirection
            ? `${baseClassName}__close-arabic`
            : `${baseClassName}__close`]: true,
        [`${baseClassName}__close-search`]: search,
    });

    return (
        <div className={classes}>
            <Overlay theme={overlayTheme} />
            <div className={`${baseClassName}__container`}>
                <div className={`${baseClassName}__bounds`}>
                    <div
                        className={
                            languageModal
                                ? `${baseClassName}__language-content-wrapper`
                                : `${baseClassName}__content-wrapper`
                        }
                    >
                        <div className={`${baseClassName}__content`}>
                            {children}
                        </div>
                    </div>
                    {header && (
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? `${baseClassName}__header-lo`
                                    : `${baseClassName}__header`
                            }
                        >
                            {header}
                        </div>
                    )}
                    {headerTitle && (
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? `${baseClassName}__header-lo-title`
                                    : myLanguage === 'ar'
                                    ? `${baseClassName}__header-ar-title`
                                    : `${baseClassName}__header-title`
                            }
                        >
                            {headerTitle}
                        </div>
                    )}
                    {footer && (
                        <div className={`${baseClassName}__footer`}>
                            {footer}
                        </div>
                    )}
                    {onClose && (
                        <div
                            className={closeClasses}
                            onClick={onClose}
                            dataGTM="modal-close"
                            type="button"
                        >
                            <SvgIcon
                                name={languageModal ? 'close-mw' : 'close'}
                                dataGTM="modal-close"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.node,
    footer: PropTypes.node,
    header: PropTypes.node,
    noContentPadding: PropTypes.bool,
    noContentScroll: PropTypes.bool,
    fillViewport: PropTypes.bool,
    noDimensionRestrictions: PropTypes.bool,
    onClose: PropTypes.func,
    /* Default background is default */
    background: PropTypes.oneOf(['default', 'transparent']),
    overlayTheme: PropTypes.shape(Overlay.propTypes.theme),
    search: PropTypes.bool,
};

Modal.defaultProps = {
    background: 'default',
    search: false,
};

export default Modal;
