import React from 'react';
import PropTypes from 'prop-types';
import './RTRBookReaderContent.scss';
import Link from '../../../Link';
import SvgIcon from '../../../SvgIcon';
import BookTags from '../../../Book/components/BookTags';

const RTRBookClosingPage = ({
    isRightToLeftDirection,
    t,
    bookData,
    online,
    book,
}) => {
    return (
        <div
            className="bookReaderPageWrapper"
            style={{ height: '80vh', width: '99%', marginLeft: '1%' }}
        >
            <div className="RightBookPage closingPage">
                <div className="closingPageHeader">
                    <div className="RTRLogo" style={{ margin: '0.5rem auto' }}>
                        {' '}
                        <SvgIcon name={'rtr-logo'} size="rtr-logo" />
                    </div>
                </div>
                <div className="closingPageContent">
                    <BookTags
                        tags={book.tags}
                        online={online}
                        t={t}
                        type={'storyReaderFinalPage'}
                    />
                    <div className="closingPageContentData">
                        <div
                            className={
                                isRightToLeftDirection
                                    ? 'closingPageContentDataTitle-Rtl'
                                    : 'closingPageContentDataTitle'
                            }
                        >
                            {t('book.storyFinalPageContentTitle')}
                        </div>
                        <div
                            className={
                                isRightToLeftDirection
                                    ? 'closingPageContentDataPara1-Rtl'
                                    : 'closingPageContentDataPara1'
                            }
                        >
                            {t('book.storyFinalPageContentPara1')}
                        </div>
                        <div
                            className={
                                isRightToLeftDirection
                                    ? 'closingPageContentDataPara2-Rtl'
                                    : 'closingPageContentDataPara2'
                            }
                        >
                            {t('book.storyFinalPageContentPara2')}
                        </div>
                    </div>
                </div>
                <div
                    className="closingPageFooter"
                    style={{ paddingBottom: '1.5rem' }}
                >
                    <p className="disclaimerLink">
                        <span>
                            {t('Book.contentFooterDescriptionDisclaimer')}
                        </span>
                        <Link
                            className="LicenceDiscriptionLink"
                            href={
                                'https://literacycloud.org/terms-and-conditions'
                            }
                        >
                            {' '}
                            {'https://literacycloud.org/terms-and-conditions'}
                        </Link>
                    </p>
                    <p className="bookLicenseContainer">
                        {bookData.license_type.image_url && (
                            <img
                                className="bookLicense"
                                src={bookData.license_type.image_url}
                                alt="license"
                            />
                        )}
                    </p>
                    <p className="licenseDescription">
                        {bookData.license_type.message}
                    </p>
                </div>
            </div>
        </div>
    );
};



RTRBookClosingPage.propTypes = {
    isRightToLeftDirection: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        license_type: PropTypes.shape({
            image_url: PropTypes.string,
            message: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    book: PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

export default RTRBookClosingPage;
