import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { keyCodes } from '../../lib/constants';
import './TextField.scss';
import InputLabel from './components/InputLabel';
import Input from './components/Input';
import InputIcon from './components/InputIcon';
import InputListBox from './components/InputListBox';

const TextField = ({
    options,
    value,
    defaultValue,
    onOptionSelect,
    onBlur,
    onChange,
    onFocus,
    disabled,
    fontFamily,
    icon,
    id,
    label,
    maxLength,
    parentClassName,
    rows = 4,
    size = 'm',
    theme = 'default',
    type = 'text',
    error,
    onIconClick,
    name,
    autoFocus,
    min,
    bordered = true,
    searchInput,
    btnName,
    createNewReadingList,
    custom,
    isPublication,
    search = false,
    onKeyPress,
    placeholder,
    isDropDownIcon,
}) => {
    const [hasValue, setHasValue] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [stateOptions, setStateOptions] = useState(null);
    const [optionsSelectIndex, setOptionsSelectIndex] = useState(undefined);

    useEffect(() => {
        const currentEl = inputEl.current;
        if (currentEl) {
            currentEl.addEventListener('keydown', onKeyDown);
        }

        return () => {
            if (currentEl) {
                currentEl.removeEventListener('keydown', onKeyDown);
            }
        };
    }, []);

    useEffect(() => {
        const validValue = isValid(value) || isValid(defaultValue);
        setHasValue(validValue);
    }, [value, defaultValue]);

    useEffect(() => {
        if (value !== undefined) {
            const hasValue = isValid(value);
            setHasValue(hasValue);

            if (options !== undefined) {
                const updatedOptions = getOptions(options, value);
                setStateOptions(updatedOptions);
                setOptionsSelectIndex(undefined);
            }
        }
    }, [value, options]);

    const inputEl = useRef(null);

    const isValid = (value) => {
        return (
            value !== '' &&
            value !== undefined &&
            value !== null &&
            !(Array.isArray(value) && value.length === 0)
        );
    };

    const onKeyDown = (e) => {
        if (stateOptions) {
            if (e.keyCode === keyCodes.upArrow) {
                selectPrevious();
                e.preventDefault();
            } else if (e.keyCode === keyCodes.downArrow) {
                selectNext();
                e.preventDefault();
            } else if (e.keyCode === keyCodes.return) {
                processOptionSelection(stateOptions[optionsSelectIndex]);
                e.preventDefault();
            }
        }
    };

    const selectPrevious = () => {
        let newSelection;

        if (optionsSelectIndex !== undefined && optionsSelectIndex - 1 !== -1) {
            newSelection = optionsSelectIndex - 1;
        } else {
            newSelection = 0;
        }

        if (newSelection !== undefined) {
            setOptionsSelectIndex(newSelection);
        }
    };

    const selectNext = () => {
        if (stateOptions) {
            let newSelection;
            if (optionsSelectIndex === undefined) {
                newSelection = 0;
            } else if (optionsSelectIndex + 1 < stateOptions.length) {
                newSelection = optionsSelectIndex + 1;
            } else {
                newSelection = stateOptions.length - 1;
            }

            if (newSelection !== undefined) {
                setOptionsSelectIndex(newSelection);
            }
        }
    };

    const processOptionSelection = (value) => {
        if (onOptionSelect) {
            onOptionSelect(value);
        }
    };

    const getOptions = (options, value) => {
        if (options && options.length && value && value.length > 0) {
            const filteredOptions = filterOptionsByQuery(options, value);

            if (
                filteredOptions &&
                filteredOptions.length &&
                !(filteredOptions.length === 1 && filteredOptions[0] === value)
            ) {
                return filteredOptions;
            }
        }

        return null;
    };

    const filterOptionsByQuery = (options, query) => {
        return options.filter((s) => {
            return s.toLowerCase().indexOf(query.toLowerCase()) === 0;
        });
    };

    const baseClassName = 'pb-text-field';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--theme-${theme}`]: theme,
        [`${baseClassName}--blank`]: !isFocused && !hasValue,
        [`${baseClassName}--focus`]: isFocused,
        [`${baseClassName}--icon`]: icon,
        [`${baseClassName}--${type}`]: type,
        [`${baseClassName}--${size}`]: size,
        [`${baseClassName}--font-family-${fontFamily}`]: fontFamily,
        [`${baseClassName}--error`]: isDirty ? error : null,
        [`${baseClassName}--disabled`]: disabled,
        [parentClassName]: parentClassName,
    };

    const inputWrapperClasses = {
        [`${baseClassName}__input-wrapper`]: !search,
        [`${baseClassName}__input-wrapper-search`]: search,
    };

    const handleOnChange = (e) => {
        setHasValue(isValid(e.target.value));
        setIsDirty(true);

        if (onChange) {
            onChange(e);
        }
    };

    const handleOnFocus = (e) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(e);
        }
    };

    const handleOnBlur = (e) => {
        setIsFocused(false);

        if (onBlur) {
            onBlur(e);
        }
    };

    const handleListBoxSelect = (value) => {
        focus();
        processOptionSelection(value);
    };

    const focus = () => {
        const currentEl = inputEl.current;
        if (currentEl) {
            currentEl.focus();
        }
    };

    const inputProps = {
        onChange: handleOnChange,
        onFocus: handleOnFocus,
        onBlur: handleOnBlur,
        onKeyPress: onKeyPress,
        defaultValue: defaultValue,
        disabled: disabled,
        name: name,
        min: min,
        ...(value != null && { value }),
    };

    return (
        <div className={classNames(classes)}>
            <div className={classNames(inputWrapperClasses)}>
                <div
                    className={
                        bordered
                            ? `${baseClassName}__box`
                            : custom
                            ? `${baseClassName}__custom-box`
                            : null
                    }
                />
                {label && (
                    <InputLabel
                        baseClassName={baseClassName}
                        searchInput={searchInput}
                        createNewReadingList={createNewReadingList}
                        id={id}
                        label={label}
                    />
                )}
                <Input
                    type={type}
                    inputEl={inputEl}
                    isPublication={isPublication}
                    baseClassName={baseClassName}
                    id={id}
                    autoFocus={autoFocus}
                    inputProps={inputProps}
                    rows={rows}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    name={name}
                    btnName={btnName}
                />
                <InputIcon
                    isDropDownIcon={isDropDownIcon}
                    baseClassName={baseClassName}
                    onIconClick={onIconClick}
                    disabled={disabled}
                    size={size}
                    icon={icon}
                />
                {options && options.length && (
                    <InputListBox
                        baseClassName={baseClassName}
                        options={options}
                        selectedIndex={optionsSelectIndex}
                        onSelect={handleListBoxSelect}
                    />
                )}
            </div>

            {error && isDirty && (
                <div className={`${baseClassName}__error-text`}>{error}</div>
            )}
        </div>
    );
};

TextField.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onOptionSelect: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    fontFamily: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    parentClassName: PropTypes.string,
    rows: PropTypes.number,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    theme: PropTypes.oneOf(['default', 'dark', 'light']),
    type: PropTypes.string,
    error: PropTypes.string,
    onIconClick: PropTypes.func,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
    min: PropTypes.number,
    bordered: PropTypes.bool,
    searchInput: PropTypes.bool,
    btnName: PropTypes.string,
    createNewReadingList: PropTypes.func,
    custom: PropTypes.bool,
    isPublication: PropTypes.bool,
    search: PropTypes.bool,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    isDropDownIcon: PropTypes.bool,
};

export default TextField;
