import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import AllBooks from '../AllBooks';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { imageLinks, sortOptions } from '../../lib/constants';

const defaultFilterValues = {
    category: [],
    publisher: [],
    level: [],
    language: [],
    sort: sortOptions.newArrivals,
    tags: [],
    query: '',
    style: [],
};

const tabFilters = {
    books: 'readFilters',
    lists: 'listsFilters',
};

const mapStateToProps = ({ allBooks, allFilters }) => ({
    headerImage: allBooks.headerImage,
    appliedFilters: allFilters[allFilters.filterType],
    filterType: allFilters.filterType,
    allFilters,
});

const mapDispatchToProps = {
    applyFilter,
    resetFilters,
};

function toDashCase(str) {
    return str.replace(/[^A-Z0-9]+/gi, '-').toLowerCase();
}

const AllBooksContainer = ({
    allFilters,
    applyFilter,
    resetFilters,
    filterType: propFilterType,
    headerImage,
    appliedFilters,
    isSearchVariant,
    searchPage,
    recommendedBooks,
    trendingBooks,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const { tab, item, ...queryAppliedFilters } = queryString.parse(
            location.search
        );
        const filterType =
            tabFilters[tab] || tabFilters[item] || propFilterType;
        const currentFilters = allFilters[filterType];
        const updatedFilters = { ...currentFilters, ...queryAppliedFilters };

        Object.entries(updatedFilters).forEach(([key, value]) => {
            if (
                Array.isArray(defaultFilterValues[key]) &&
                !Array.isArray(value)
            ) {
                updatedFilters[key] = [value];
            }
        });

        applyFilter({ filterType, filter: updatedFilters });

        return () => {
            resetFilters();
        };
    }, []);

    const getTab = () => queryString.parse(location.search).tab;
    const getItem = () => queryString.parse(location.search).item;

    const handleApplyFilter = useCallback(
        (type, value) => {
            const filterIsArray = Array.isArray(appliedFilters[type]);
            const newValue = filterIsArray
                ? [...appliedFilters[type], value]
                : value;
            const updatedField = { [type]: newValue };

            navigate({
                search: queryString.stringify({
                    ...appliedFilters,
                    ...updatedField,
                    tab: getTab(),
                }),
            });
        },
        [appliedFilters, navigate]
    );

    const handleRemoveFilter = useCallback(
        (type, value) => {
            if (!appliedFilters[type]) return;
            const newValue = Array.isArray(appliedFilters[type])
                ? appliedFilters[type].filter((item) => item !== value)
                : '';
            const updatedField = { [type]: newValue };

            navigate({
                search: queryString.stringify({
                    ...appliedFilters,
                    ...updatedField,
                    tab: getTab(),
                }),
            });
        },
        [appliedFilters, navigate]
    );

    const handleSortChange = useCallback(
        (option) => handleApplyFilter('sort', option),
        [handleApplyFilter]
    );

    const handleTabChange = useCallback((newTab, existingFilter) => {
        const filters = {
            query: existingFilter.query,
            tags: existingFilter.tags,
        };
        const filterType = tabFilters[newTab];

        navigate({
            search: queryString.stringify({
                ...allFilters[filterType],
                ...filters,
                tab: newTab,
            }),
        });
    }, []);

    if (isSearchVariant && !(appliedFilters.query || appliedFilters.tags)) {
        return <Navigate to="/stories" />;
    }

    const tab = getTab();
    const item = getItem();

    return (
        <AllBooks
            applyFilter={handleApplyFilter}
            removeFilter={handleRemoveFilter}
            appliedFilters={{ ...appliedFilters, tab }}
            item={item}
            location={location}
            isSearchVariant={isSearchVariant}
            searchQuery={appliedFilters.query}
            tags={appliedFilters.tags}
            headerBgUrl={
                appliedFilters.isStoryCategory
                    ? headerImage
                    : imageLinks.headerBG
            }
            headerTitle={
                appliedFilters.isStoryCategory
                    ? appliedFilters.categoryHeaderTitle
                    : null
            }
            icon={
                appliedFilters.isStoryCategory
                    ? toDashCase(appliedFilters.categoryHeaderTitle)
                    : 'book-alt'
            }
            onSortOptionChanged={handleSortChange}
            onTabChange={handleTabChange}
            searchPage={searchPage}
            recommendedBooks={recommendedBooks}
            trendingBooks={trendingBooks}
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllBooksContainer);
