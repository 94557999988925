import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import LoaderBlock from '../LoaderBlock';
import {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
} from '../../redux/allBooksActions';
import FiltersBar from '../FiltersBar';
import './AddBooks.scss';
import Grid from '../Grid';
import ManageBookCard from '../ManageBookCard';
import useDeviceType from '../../hooks/useDeviceType';
import { filtersBarSortOptions } from './constants/constants';
import { isRightToLeftDirection } from '../../util/utils';
import NoResults from './components/NoResults';

const AddBooks = ({
    t,
    location,
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
    appliedFilters,
    navigate,
    parentClassName,
    BooksList,
    newList,
    viewport,
    applyFilter,
    onSortOptionChanged,
    removeFilter,
    filters,
    isFetchingBooks,
    totalBooksCount,
}) => {
    const [filtersSearchText, setFiltersSearchText] = useState({
        category: '',
        publisher: '',
        level: '',
        language: '',
    });

    const { isTabletDevice: isAddBookMobile } = useDeviceType();

    useEffect(() => {
        fetchAllBooksFiltersWorkflow().then(() =>
            fetchAllBooksWorkflow(appliedFilters)
        );
    }, []);

    const updateFiltersSearchText = (key, text) => {
        setFiltersSearchText((prevState) => ({
            ...prevState,
            [key]: text,
        }));
    };

    const baseClassName = 'pb-add-books';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const newListBooksId = {};
    if (newList && newList.books && newList.books.length > 0) {
        newList.books.forEach((book) => {
            newListBooksId[book.id] = true;
        });
    }

    return (
        <div className={classNames(classes)}>
            <div className="__addbooks-section">
                {BooksList && BooksList.length >= 0 && (
                    <div className="__filter-section">
                        {' '}
                        <FiltersBar
                            viewport={viewport}
                            noTopBorder
                            pullUp
                            filters={filters}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            appliedFilters={appliedFilters}
                            categorySearchValue={filtersSearchText.category}
                            updateCategorySearchValue={(e) =>
                                updateFiltersSearchText(
                                    'category',
                                    e.target.value
                                )
                            }
                            publisherSearchValue={filtersSearchText.publisher}
                            updatePublisherSearchValue={(e) =>
                                updateFiltersSearchText(
                                    'publisher',
                                    e.target.value
                                )
                            }
                            levelSearchValue={filtersSearchText.level}
                            updateLevelSearchValue={(e) =>
                                updateFiltersSearchText('level', e.target.value)
                            }
                            languageSearchValue={filtersSearchText.language}
                            updateLanguageSearchValue={(e) =>
                                updateFiltersSearchText(
                                    'language',
                                    e.target.value
                                )
                            }
                            resultsCount={totalBooksCount}
                            sortOptions={filtersBarSortOptions(t)}
                            applySortOption={onSortOptionChanged}
                            appliedSortOption={appliedFilters.sort}
                            isAddBook
                            isAddBookMobile={isAddBookMobile}
                            categoryFilterGTM="add-book-category-filter"
                            levelFilterGTM="add-book-level-filter"
                            languageFilterGTM="add-book-language-filter"
                            favoriteSortGTM="add-book-favorite-sort"
                            readSortGTM="add-book-read-sort"
                            alphabetSortGTM="add-book-alphabetic-sort"
                        />
                    </div>
                )}
                {isFetchingBooks || !BooksList ? (
                    <LoaderBlock />
                ) : (
                    <div>
                        {BooksList.length > 0 ? (
                            <Grid variant="4up">
                                {BooksList &&
                                    BooksList.map((bookItem) => {
                                        const addBtn =
                                            newListBooksId[bookItem.id] ||
                                            false;

                                        return (
                                            <div className="__themedBookItem-section">
                                                <ManageBookCard
                                                    key={bookItem.id}
                                                    book={bookItem}
                                                    isAddBook
                                                    addBookImageGTM="add-books-book-image"
                                                    asdBookTitleGTM="add-books-book-title"
                                                    addBookAuthorGTM="add-books-book-author"
                                                    addBookIllustratorsGTM="add-books-book-illustrator"
                                                    addBookLevelGTM="add-books-book-level"
                                                    removeFromBookListGTM="remove-from-book-list"
                                                    addToBookListGTM="add-to-book-list"
                                                    addBtn={addBtn}
                                                />
                                            </div>
                                        );
                                    })}
                            </Grid>
                        ) : null}
                        <div
                            className={
                                isRightToLeftDirection
                                    ? '__no-result-section-rtl'
                                    : '__no-result-section'
                            }
                        >
                            {!totalBooksCount && <NoResults t={t} />}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
};

const mapStateToProps = ({
    allBooks,
    viewport,
    user: { profile },
    allFilters: { readFilters },
}) => ({
    ...allBooks,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
});

AddBooks.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object,
    fetchAllBooksFiltersWorkflow: PropTypes.func.isRequired,
    fetchAllBooksWorkflow: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    navigate: PropTypes.func,
    parentClassName: PropTypes.string,
    BooksList: PropTypes.array,
    newList: PropTypes.shape({
        books: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
    viewport: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    onSortOptionChanged: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    isFetchingBooks: PropTypes.bool.isRequired,
    totalBooksCount: PropTypes.number.isRequired,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(AddBooks)
);
