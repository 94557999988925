import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';

const reducePropsToState = (propsList) => {
    const innermostProps = propsList[propsList.length - 1];
    return innermostProps
        ? {
              className: innermostProps.className,
              remove: innermostProps.remove,
          }
        : null;
};

const handleStateChangeOnClient = (props) => {
    const { className, remove } = props || {};

    if (className) {
        if (remove) {
            document.body.classList.remove(className);
        } else {
            document.body.classList.add(className);
        }
    }
};

const DocumentClass = ({ children }) => children || null;

DocumentClass.propTypes = {
    className: PropTypes.string.isRequired,
    remove: PropTypes.bool,
};

export default withSideEffect(
    reducePropsToState,
    handleStateChangeOnClient
)(DocumentClass);
