import React, { useState, useEffect } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withRouter from '../../../../util/withRouter';
import './ReadingListInnerMenuTabs.scss';
import { loggedInTabs } from '../../constants/loggedInTabs';

const ReadingListInnerMenuTabs = ({
    t,
    location,
    parentClassName,
    isLoggedIn,
    navigate,
}) => {
    const [selectedTab, setSelectedTab] = useState(loggedInTabs(t)[0]);

    useEffect(() => {
        const currentTab =
            location.pathname === '/readinglists'
                ? loggedInTabs(t)[0]
                : loggedInTabs(t)[1];
        setSelectedTab(currentTab);
    }, []);

    const baseClassName = 'pb-reading-list-inner-menu-tabs';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const onClick = (tab) => {
        setSelectedTab(tab);
        navigate(tab.url);
    };

    return (
        <div className={classNames(classes)}>
            {isLoggedIn ? (
                <div className="subMenuTabs">
                    {loggedInTabs(t).map((tab, index) => {
                        return (
                            <div className={'NavigationItem'} key={index}>
                                <div
                                    className={
                                        selectedTab.url === tab.url
                                            ? 'active'
                                            : 'inactive'
                                    }
                                    onClick={() => onClick(tab)}
                                >
                                    {tab.name}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="Tabs">
                    <div className={'NavigationItem'}>
                        <div className="active">
                            {t('global.themed-reading-lists')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ReadingListInnerMenuTabs.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    parentClassName: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(translate()(ReadingListInnerMenuTabs));
