import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import './RTRBookReaderContent.scss';
import {
    getBookDataWorkflow,
    fetchBookWorkflow,
} from '../../../../redux/bookActions';
import { connect } from 'react-redux';
import PageFlipAnimation from '../../../PageFlip/PageFlipAnimation';
import withRouter from '../../../../util/withRouter';
import BioModal from '../BioModal';
import BookSummaryModal from '../BookSummaryModal';
import RTRReaderContentMobile from './RTRReaderContentMobile';

const RTRBookReaderContent = ({
    t,
    bookData,
    pageNo,
    totalPageCount,
    prevPage,
    nextPage,
    book,
    online,
    onFavoriteClicked,
    likesCount,
    isLoggedIn,
    viewport,
    setBookReaderStatusWorkflow,
    readAgain,
    navigate,
    handleTouchStart,
    handleTouchMove,
}) => {
    const [isModalVisible, setIsModalVisible] = useState({
        noSpaceForMoreOfflineBooks: false,
    });
    const [isBioModalInfo, setIsBioModalInfo] = useState({
        isBioModalOpen: false,
        first_name: 'ww',
        last_name: 'ww',
        email: '22',
        bio: '',
    });
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const onOpenBioModal = (e, person) => {
        setIsBioModalInfo({
            isBioModalOpen: true,
            first_name: person.first_name,
            last_name: person.last_name,
            email: person.email,
            bio: person.bio,
        });
    };

    const onOpenModal = () => {
        setIsModalVisible({
            noSpaceForMoreOfflineBooks: true,
        });
    };

    const onCloseBioModal = () => {
        setIsBioModalInfo({
            isBioModalOpen: false,
            first_name: '',
            last_name: '',
            email: '',
            bio: '',
        });
    };

    const onCloseModal = () => {
        setIsModalVisible({
            noSpaceForMoreOfflineBooks: false,
        });
    };

    const closePopup = () => {
        setModalIsOpen(false);
    };
    return (
        <div className="RTRBookReaderContent">
            {window.innerWidth > 1030 && (
                <div id="bookReaderPageWrapper">
                    <PageFlipAnimation
                        detailPages={bookData}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        currentPage={pageNo}
                        t={t}
                        book={book}
                        online={online}
                        onFavoriteClicked={onFavoriteClicked}
                        likesCount={likesCount}
                        isLoggedIn={isLoggedIn}
                        viewport={viewport}
                        onOpenBioModal={onOpenBioModal}
                        onOpenModal={onOpenModal}
                        setBookReaderStatusWorkflow={
                            setBookReaderStatusWorkflow
                        }
                        readAgain={readAgain}
                        pageNo={pageNo}
                        isBookRated={bookData.is_book_rated}
                        history={navigate}
                    />

                    {isBioModalInfo.isBioModalOpen && (
                        <BioModal
                            onCloseBioModal={onCloseBioModal}
                            isBioModalInfo={isBioModalInfo}
                        />
                    )}

                    {isModalVisible.noSpaceForMoreOfflineBooks && (
                        <BookSummaryModal
                            onCloseModal={onCloseModal}
                            bookData={bookData}
                        />
                    )}
                </div>
            )}

            <RTRReaderContentMobile
                handleTouchStart={handleTouchStart}
                handleTouchMove={handleTouchMove}
                mobilePageStatus={pageNo}
                bookData={bookData}
                onOpenModal={onOpenModal}
                t={t}
                isModalVisible={isModalVisible}
                onOpenBioModal={onOpenBioModal}
                isBioModalInfo={isBioModalInfo}
                onCloseBioModal={onCloseBioModal}
                book={book}
                online={online}
                onFavoriteClicked={onFavoriteClicked}
                isLoggedIn={isLoggedIn}
                likesCount={likesCount}
                totalPageCount={totalPageCount}
                setBookReaderStatusWorkflow={setBookReaderStatusWorkflow}
                readAgain={readAgain}
                modalIsOpen={modalIsOpen}
                closePopup={closePopup}
                navigate={navigate}
            />
        </div>
    );
};

const mapDispatchToProps = {
    getBookDataWorkflow,
    fetchBookWorkflow,
};
const mapStateToProps = (state) => {
    return {
        isBookDataFetched: state.book.isBookDataFetched,
    };
};


RTRBookReaderContent.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        is_book_rated: PropTypes.bool.isRequired,
    }).isRequired,
    pageNo: PropTypes.number.isRequired,
    totalPageCount: PropTypes.number.isRequired,
    prevPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isFavourite: PropTypes.bool.isRequired,
        isFavouriting: PropTypes.bool.isRequired,
        likesCount: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    likesCount: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    viewport: PropTypes.string.isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    readAgain: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    handleTouchStart: PropTypes.func.isRequired,
    handleTouchMove: PropTypes.func.isRequired,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(RTRBookReaderContent)
    )
);
