import { replace } from 'react-router-redux';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { fetchAllReferencesFiltersWorkflow, fetchReferencesWorkflow } from '../../redux/referencesActions';
import { fetchMeWorkflow } from '../../redux/userActions';

export const mapStateToProps = ({ user, user: { profile }, allFilters: { readFilters }, references, viewport, help }) => ({
    ...references,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    filters: references.filters,
    name: profile.name,
    helpStatus: help.helpStatus,
    isLoggedIn: user.isLoggedIn
});

export const mapDispatchToProps = {
    fetchAllReferencesFiltersWorkflow,
    fetchReferencesWorkflow,
    fetchMeWorkflow,
    replace,
    applyFilter,
    resetFilters,
};