import React from 'react';
import PropTypes from 'prop-types';
import './RTRBookReaderContent.scss';
import RTRBookFavourites from '../../../RTRBookFavourites';
import ShareMenu from '../../../RTRShareMenu';
import { isRightToLeftDirection } from '../../../../util/utils';

const RTRBookReadAgain = ({
    t,
    bookData,
    setBookReaderStatusWorkflow,
    book,
    pageNo,
    readAgain,
    resetAgain,
    online,
    onFavoriteClicked,
    isLoggedIn,
    likesCount,
}) => {
    return (
        <div className="bookReaderPageWrapper" style={{ height: '80vh' }}>
            <div className="leftBookPage firstPage">
                <div
                    className="bookCoverPageContainer readAgainPage"
                    style={{ margin: '0px' }}
                >
                    <div>
                        {' '}
                        <h1 className="bookTitle">{bookData.title}</h1>
                    </div>
                    <div
                        className="bookFinishReading"
                        onClick={() => {
                            setBookReaderStatusWorkflow(
                                book.id,
                                pageNo,
                                'finish'
                            );
                            window.history.back();
                        }}
                    >
                        <span>{t('Book.contentFinishReading')}</span>
                    </div>

                    <div
                        className="bookReadAgain readAgainFont"
                        onClick={() => {
                            setBookReaderStatusWorkflow(
                                book.id,
                                pageNo,
                                'finish'
                            );
                            readAgain();
                            resetAgain();
                        }}
                    >
                        {t('Book.contentReadAgain')}
                    </div>

                    <div
                        className={`bookShareAndLikes ${
                            isRightToLeftDirection
                                ? 'bookShareAndLikes-rtl'
                                : ''
                        }`}
                    >
                        <div className="bookLikes favouriteCount">
                            {book && book.likesCount >= 0 && online && (
                                <div className="d-inline">
                                    {' '}
                                    <RTRBookFavourites
                                        t={t}
                                        isFavourite={book.isFavourite}
                                        favouriteToggleHandler={() => {
                                            onFavoriteClicked(book.id);
                                        }}
                                        isFavouriting={book.isFavouriting}
                                        isLoggedIn={isLoggedIn}
                                        logInMsg={t('global.please-log-in', {
                                            action: t('global.like', 1),
                                            content_type: t('global.story', 1),
                                        })}
                                        likes={likesCount}
                                        bookSlug={book.slug}
                                        bookFavorite="book-favorite"
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className="RTRBookShare"
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        >
                            <ShareMenu
                                title={''}
                                href={window.location.href}
                                desc={''}
                                landingPageBook={''}
                                align={() => {
                                    return true;
                                }}
                            />
                        </div>
                    </div>
                    <h2 className="bookThanksHeading">
                        {t('Book.contentThankYou')}
                    </h2>
                    <p className="bookDedicationText">
                        {bookData['dedication_language']}
                    </p>
                    {bookData.donor != null ? (
                        <div className="bookSponserImages">
                            <img
                                src={bookData.donor.logo_url}
                                alt="dedicatorImages"
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

RTRBookReadAgain.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        donor: PropTypes.shape({
            logo_url: PropTypes.string,
        }),
        dedication_language: PropTypes.string.isRequired,
    }).isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isFavourite: PropTypes.bool.isRequired,
        isFavouriting: PropTypes.bool.isRequired,
        likesCount: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    pageNo: PropTypes.number.isRequired,
    readAgain: PropTypes.func.isRequired,
    resetAgain: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
};

export default RTRBookReadAgain;
