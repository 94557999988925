import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import withRouter from '../../../util/withRouter';
import ButtonGroup from '../../ButtonGroup';
import Button from '../../Button';
import Modal from '../../Modal';

const NoSpaceForMoreOfflineBooksModal = ({
    onClose,
    viewport,
    t,
    maximum,
    history,
}) => {
    const header = <h2>{t('NoSpaceForMoreOfflineBooksModal.title')}</h2>;
    const footer = (
        <ButtonGroup mergeTop mergeBottom={!viewport.large} mergeSides>
            <Button
                label={t('NoSpaceForMoreOfflineBooksModal.manage')}
                variant="primary"
                onClick={() => {
                    onClose();
                    history.push('/offline');
                }}
            />
            <Button
                label={t('NoSpaceForMoreOfflineBooksModal.close')}
                onClick={onClose}
            />
        </ButtonGroup>
    );

    return (
        <Modal header={header} footer={footer} onClose={onClose}>
            <p>{t('NoSpaceForMoreOfflineBooksModal.warning', { maximum })}</p>
        </Modal>
    );
};

NoSpaceForMoreOfflineBooksModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    maximum: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default translate()(withRouter(NoSpaceForMoreOfflineBooksModal));
