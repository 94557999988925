import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
} from 'react-router-redux';
import ReactGA from 'react-ga';
import { I18n } from 'react-polyglot';
import logger from 'redux-logger';

import locales from './i18n';
import { setupLoggly } from './lib/loggly';
import googleAnalyticsMiddleware from './redux/googleAnalyticsMiddleware';

// Reducers
import allFiltersReducer from './redux/allFiltersReducer';
import profileReducer from './redux/profileReducer';
import viewportReducer from './redux/viewportReducer';
import userReducer from './redux/userReducer';
import readingListsReducer from './redux/readingListsReducer';
import slimNotificationReducer from './redux/slimNotificationReducer';
import bookReducer from './redux/bookReducer';
import offlineBooksReducer from './redux/offlineBooksReducer';
import homeReducer from './redux/homeReducer';
import allBooksReducer from './redux/allBooksReducer';
import networkReducer from './redux/networkReducer';
import allListsReducer from './redux/allListsReducer';
import listsSearchReducer from './redux/readingListsSearchReducer';
import videosReducer from './redux/videoReducer';
import referencesReducer from './redux/referencesReducer';
import menuToggleReducer from './redux/menuToggleReducer';
import helpReducers from './redux/helpReducers';
import readAloudsReducer from './redux/readAloudsReducer';
import bookCommentReducer from './redux/bookCommentReducer';

// Main App component
import AppContainer from './components/AppContainer';

//index.scss
import './index.scss';
import Router from './router/Router';

// Set up our redux store.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createHistory();

//  to prevent display of multiple analytics call in the header
// ReactGA.initialize(process.env.REACT_APP_GA_PROPERTY_ID);

history.listen((location) => {
    window.scrollTo(0, 0);
    ReactGA.pageview(location.pathname);
});

const middleware = [thunk, routerMiddleware(history)];

// Load Google Analytics
if (process.env.REACT_APP_GA_PROPERTY_ID) {
    // disabling the below line to prevent multiple analytics call in prod env
    // initGoogleAnalytics(process.env.REACT_APP_GA_PROPERTY_ID);
    middleware.push(googleAnalyticsMiddleware);
}

// if (process.env.NODE_ENV !== 'production') {

// }

middleware.push(logger);

const store = createStore(
    combineReducers({
        router: routerReducer,
        viewport: viewportReducer,
        profile: profileReducer,
        user: userReducer,
        readingLists: readingListsReducer,
        slimNotification: slimNotificationReducer,
        book: bookReducer,
        offlineBooks: offlineBooksReducer,
        home: homeReducer,
        allBooks: allBooksReducer,
        allFilters: allFiltersReducer,
        network: networkReducer,
        allLists: allListsReducer,
        listsSearch: listsSearchReducer,
        videos: videosReducer,
        references: referencesReducer,
        menuToggle: menuToggleReducer,
        help: helpReducers,
        readAlouds: readAloudsReducer,
        bookComments: bookCommentReducer,
    }),
    composeEnhancers(applyMiddleware(...middleware), autoRehydrate())
);

persistStore(store, {
    whitelist: ['offlineBooks'],
});

// Set up Loggly for development mode.
if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_LOGGLY_KEY
) {
    setupLoggly(process.env.REACT_APP_LOGGLY_KEY);
}

// var NotFoundPage = () => <div>Sorry Not found</div>;

// Set up locale
let ConnectedI18n = ({ children }) => {
    const locale = localStorage.getItem('locale') || 'en';
    const messages = locales[locale];
    return (
        <I18n locale={locale} messages={messages}>
            {children}
        </I18n>
    );
};

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ConnectedI18n>
                <React.Fragment>
                    {/* <ConnectedRouter history={history}> */}
                    {/* <AppContainer> */}
                    <Router />
                    {/* </AppContainer> */}
                    {/* </ConnectedRouter> */}
                </React.Fragment>
            </ConnectedI18n>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

// if ('serviceWorker' in navigator && process.env.REACT_APP_FEATURE_OFFLINE) {
//     navigator.serviceWorker.register('/service-worker.js');

// }
