import React from 'react';
import PropTypes from 'prop-types';
import RatingModal from 'react-modal';
import SvgIcon from '../../../SvgIcon';
import Link from '../../../Link';
import BookRating from '../../../BookRating';
import { isRightToLeftDirection } from '../../../../util/utils';

const RTRMobileLastPage = ({
    t,
    bookData,
    isLoggedIn,
    modalIsOpen,
    closePopup,
    book,
    navigate,
}) => {
    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            position: 'fixed',
            top: '15%',
            left: '15%',
            right: '15%',
            bottom: '15%',
            border: '1px solid #ccc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '0px',
            outline: 'none',
            padding: '0px',
            zIndex: '999',
            overflowY: 'hidden',
            background: '#ffffff',
        },
    };
    return (
        <div style={{ width: '100%' }}>
            <div className="bookPage-Mobile  closingPage" id="bookPage-Mobile">
                <div className="closingPageHeader">
                    <div className="RTRLogo">
                        <SvgIcon name={'rtr-logo'} size="rtr-logo" />
                    </div>
                </div>
                <div className="closingPageContentData-Mobile">
                    <div
                        className={
                            isRightToLeftDirection
                                ? 'closingPageContentDataTitle-Mobile-Rtl'
                                : 'closingPageContentDataTitle-Mobile'
                        }
                    >
                        {t('book.storyFinalPageContentTitle')}
                    </div>
                    <div
                        className={
                            isRightToLeftDirection
                                ? 'closingPageContentDataPara1-Mobile-Rtl'
                                : 'closingPageContentDataPara1-Mobile'
                        }
                    >
                        {t('book.storyFinalPageContentPara1')}
                    </div>
                    <div
                        className={
                            isRightToLeftDirection
                                ? 'closingPageContentDataPara2-Mobile-Rtl'
                                : 'closingPageContentDataPara2-Mobile'
                        }
                    >
                        {t('book.storyFinalPageContentPara2')}
                    </div>
                </div>
                <div className="closingPageFooter">
                    <p className="disclaimerLink">
                        <span>
                            {t('Book.contentFooterDescriptionDisclaimer')}
                        </span>
                        <Link
                            className="bookAuthorsLink"
                            href={
                                'https://literacycloud.org/terms-and-conditions'
                            }
                        >
                            {'https://literacycloud.org/terms-and-conditions'}
                        </Link>
                    </p>
                    <p className="bookLicenseContainer">
                        <img
                            className="bookLicense"
                            src={
                                bookData.license_type.image_url != null
                                    ? bookData.license_type.image_url
                                    : 'https://literacycloud.org/system/room-to-read/license_images/7/original/CC_BY_4.png?1595242870'
                            }
                            alt="license"
                        />
                    </p>
                    <p className="licenseDescription">
                        {bookData.license_type.message}
                    </p>
                </div>
            </div>

            {isLoggedIn &&
                !bookData.is_book_rated &&
                window.navigator.onLine &&
                modalIsOpen && (
                    <RatingModal
                        isOpen={modalIsOpen}
                        style={customStyles}
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.style.overflow = 'auto';
                        }}
                    >
                        <BookRating
                            t={t}
                            handleClose={closePopup}
                            bookId={book.id}
                            bookSlug={book.slug}
                            history={navigate}
                        />
                    </RatingModal>
                )}
        </div>
    );
};


RTRMobileLastPage.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        license_type: PropTypes.shape({
            image_url: PropTypes.string,
            message: PropTypes.string.isRequired,
        }).isRequired,
        is_book_rated: PropTypes.bool.isRequired,
    }).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};


export default RTRMobileLastPage;
