import React from 'react';
import PropTypes from 'prop-types';

const RTRMobileBookPages = ({ bookData, mobilePageStatus }) => {
    return (
        <div className="bookPage-Mobile" id="bookPage-Mobile">
            <img
                className="BookPageImg"
                src={bookData.pages[mobilePageStatus - 1].url}
                alt={mobilePageStatus}
            />
        </div>
    );
};

RTRMobileBookPages.propTypes = {
    bookData: PropTypes.shape({
        pages: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    mobilePageStatus: PropTypes.number.isRequired,
};

export default RTRMobileBookPages;
