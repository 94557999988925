import React, { useState } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import ButtonGroup from '../../ButtonGroup';
import Button from '../../Button';
import Modal from '../../Modal';

const DeleteCommentModal = ({
    onClose,
    onConfirm,
    viewport,
    t,
    isDeleteReportedCommentloading,
}) => {
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setError(value === '');
    };

    const handleSubmit = () => {
        if (inputValue === '') {
            setError(true);
        } else {
            setError(false);
            onConfirm(inputValue);
            onClose();
        }
    };

    const footer = (
        <ButtonGroup mergeTop mergeBottom={!viewport.large} mergeSides>
            {isDeleteReportedCommentloading ? (
                <Loader />
            ) : (
                <Button
                    label={t('book.submit')}
                    variant="submit"
                    onClick={handleSubmit}
                />
            )}
            <Button label={t('global.cancel')} onClick={onClose} />
        </ButtonGroup>
    );

    return (
        <Modal footer={footer} onClose={onClose}>
            <div className="DeleteCommentModalContainer">
                <input
                    id="deleteCommentModalInput"
                    className="DeleteCommentModalInput"
                    type="text"
                    placeholder={t('comments.reportInputPlaceholder')}
                    value={inputValue}
                    onChange={handleChange}
                />
                {error && (
                    <p className="DeleteCommentModalError">
                        {t('comments.error')}
                    </p>
                )}
            </div>
        </Modal>
    );
};

DeleteCommentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    isDeleteReportedCommentloading: PropTypes.bool.isRequired,
};

export default translate()(DeleteCommentModal);
