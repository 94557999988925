import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Link from '../../Link';
import { isRightToLeftDirection } from '../../../util/utils';
import { API_URL } from '../../../api';

const DeleteReadingListModal = ({
    t,
    readingListsBookDetail,
    closeDeleteModal,
    navigate,
}) => {
    const [disable, setDisable] = useState(false);

    const nextPath = (path) => {
        navigate(path);
    };

    const deleteReadingList = (list) => {
        fetch(`${API_URL}/lists/${list.id}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.ok) {
                    setTimeout(() => {
                        nextPath('/myreadinglists');
                    }, 2500);
                } else {
                    console.log('failed');
                    alert('failed');
                }
            });
    };
    return (
        <Modal>
            <div
                className={
                    isRightToLeftDirection
                        ? '__delete-text-arabic'
                        : '__delete-text'
                }
            >
                {t('myReadingDeleteModal.pre-link')}
                <span className="__list-title">
                    {readingListsBookDetail.title}
                </span>
                {t('myReadingDeleteModal.post-link')}
            </div>

            <div className="__delete-button-section">
                <div
                    className={
                        isRightToLeftDirection
                            ? '__cancel-button-arabic'
                            : '__cancel-button'
                    }
                >
                    <Link isInternal paddingTop dataGTM="no-button">
                        {disable ? (
                            <span
                                className="__done-button-disabled"
                                data-gtm="no-button"
                            >
                                {t('global.no')}
                            </span>
                        ) : (
                            <span
                                className="__done-button"
                                onClick={closeDeleteModal}
                                data-gtm="no-button"
                            >
                                {t('global.no')}
                            </span>
                        )}
                    </Link>
                </div>
                <div>
                    {disable ? (
                        <span
                            className="__done-button-disabled"
                            data-gtm="yes-button"
                        >
                            <img
                                src="/public/loader.svg"
                                alt="loader"
                                className="svgLoaderRL"
                                style={{ margin: '0px 5px' }}
                            />
                            {t('global.yes')}
                        </span>
                    ) : (
                        <span
                            className="__done-button"
                            data-gtm="yes-button"
                            onClick={() => {
                                deleteReadingList(readingListsBookDetail);
                                setDisable(true);
                            }}
                        >
                            {t('global.yes')}
                        </span>
                    )}
                </div>
            </div>
        </Modal>
    );
};

DeleteReadingListModal.propTypes = {
    t: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
    }).isRequired,
    closeDeleteModal: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default DeleteReadingListModal;
