import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './CreateNewReadingList.scss';
import TextField from '../TextField';
import { createNewReadingListActionAsync } from '../../redux/readingListsActions';
import withRouter from '../../util/withRouter';
import CreateNewReadingListButton from './components/CreateNewReadingListButton/CreateNewReadingListButton';

const CreateNewReadingList = ({
    t,
    edit: isEdit,
    listDetails,
    navigate,
    createNewReadingListActionAsync,
    editSubmit,
    parentClassName,
}) => {
    const [readingListName, setReadingListName] = useState('');
    const [readingListDesc, setReadingListDesc] = useState('');

    useEffect(() => {
        if (listDetails) {
            listDetails.title && setReadingListName(listDetails.title);

            listDetails.description &&
                setReadingListDesc(listDetails.description);
        }
    }, []);

    const BtnDisabled = !isEdit;

    const baseClassName = 'pb-create-new-reading-list';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const onTitleChange = (e) => setReadingListName(e.target.value);
    const onDescriptionChange = (e) => setReadingListDesc(e.target.value);

    return (
        <div className={classNames(classes)}>
            <DocumentTitle
                title={`${t('Readinglist.create-new-reading-list')} - ${t(
                    'global.site-title'
                )}`}
            />
            <div className="__create-new-readinglist-section">
                <div className="__create-new-readinglist-header">
                    {isEdit
                        ? t('Readinglist.edit-reading-list')
                        : t('Readinglist.create-new-reading-list')}
                </div>
                <div className="__create-new-readinglist-fields">
                    <TextField
                        id="search-modal-input"
                        label={t('Readinglist.reading-list-name')}
                        value={readingListName}
                        size="l"
                        theme="dark"
                        onChange={onTitleChange}
                        bordered={false}
                        createNewReadingList
                    />
                    <br />
                    <br />
                    <TextField
                        id="search-modal-input"
                        label={t('Readinglist.reading-list-description')}
                        value={readingListDesc}
                        size="l"
                        theme="dark"
                        onChange={onDescriptionChange}
                        bordered={true}
                        multiline
                        createNewReadingList
                        type="multiline"
                        rows="4"
                        maxLength={120}
                    />
                    <span className="description-length">
                        {readingListDesc.length}/120
                    </span>
                </div>

                <CreateNewReadingListButton
                    t={t}
                    readingListName={readingListName}
                    readingListDesc={readingListDesc}
                    edit={isEdit}
                    listDetails={listDetails}
                    editSubmit={editSubmit}
                    createNewReadingListActionAsync={
                        createNewReadingListActionAsync
                    }
                    baseClassName={baseClassName}
                    BtnDisabled={BtnDisabled}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ viewport }) => {
    return {
        viewport,
    };
};

const mapDispatchToProps = {
    createNewReadingListActionAsync,
};

CreateNewReadingList.propTypes = {
    t: PropTypes.func.isRequired,
    edit: PropTypes.bool,
    listDetails: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    navigate: PropTypes.object.isRequired,
    createNewReadingListActionAsync: PropTypes.func.isRequired,
    editSubmit: PropTypes.func,
    parentClassName: PropTypes.string,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(CreateNewReadingList)
    )
);
