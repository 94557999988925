import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PageFlipAnimation.scss';
import Modal from 'react-modal';
import BookRating from '../BookRating';
import SvgIcon from '../SvgIcon';
import { isRightToLeftDirection } from '../../util/utils';
import withRouter from '../../util/withRouter';
import PageFlipBook from './components/PageFlipBook';
Modal.setAppElement('body');

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        position: 'fixed',
        top: '20%',
        left: '35%',
        right: '35%',
        bottom: '20%',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
        zIndex: '999',
        overflowY: 'hidden',
        background: '#ffffff',
    },
};

const PageFlipAnimation = ({
    detailPages,
    pageNo,
    nextPage,
    prevPage,
    t,
    book,
    online,
    onFavoriteClicked,
    likesCount,
    isLoggedIn,
    viewport,
    setBookReaderStatusWorkflow,
    readAgain,
    onOpenBioModal,
    onOpenModal,
    isBookRated,
    navigate,
}) => {
    const { id, slug } = book;

    const totalPages =
        detailPages.pages.length % 2
            ? (detailPages.pages.length + 1) / 2
            : detailPages.pages.length / 2;

    const browserView =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;

    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [isBookOpen, setIsBookOpen] = useState(true);
    const [isBookClosed, setIsBookClosed] = useState({
        isClosed: true,
        isAtBeginning: true,
    });
    const [flippedPapers, setFlippedPapers] = useState([]);
    const [flippedPages, setFlippedPages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let lastFlipped = [];
        for (let i = 1; i <= pageNo; i++) {
            lastFlipped.push(`p${i}`);
        }

        setFlippedPapers(lastFlipped);
        setFlippedPages(lastFlipped);
        setCurrentPageNum(pageNo + 1);
    }, []);

    const closeBook = (isAtBeginning) => {
        setIsBookOpen(false);
        if (isAtBeginning) {
            setIsBookClosed({
                isClosed: true,
                isAtBeginning: true,
            });
        } else {
            setIsBookClosed({
                isClosed: true,
                isAtBeginning: false,
            });
        }
    };

    const openBook = () => {
        setIsBookOpen(true);
    };

    const closePopup = () => {
        setIsModalOpen(false);
    };

    const goPrevPage = () => {
        if (currentPageNum > 1) {
            let newFlippedPapers = [];
            if (currentPageNum === 2) {
                closeBook(true);
                newFlippedPapers = flippedPapers.filter(
                    (paper) => paper !== 'p1'
                );
            } else if (currentPageNum === totalPages + 1) {
                openBook();
                newFlippedPapers = flippedPapers.filter(
                    (paper) => paper !== `p${totalPages}`
                );
            } else {
                newFlippedPapers = flippedPapers.filter(
                    (paper) => paper !== `p${currentPageNum - 1}`
                );
            }
            setFlippedPapers(newFlippedPapers);
            setFlippedPages(newFlippedPapers);
            setCurrentPageNum((prev) => prev - 1);

            prevPage(currentPageNum - 2);
        }
    };

    const goNextPage = () => {
        console.log('object123', currentPageNum);
        if (currentPageNum < totalPages + 1) {
            let newFlippedPapers = [];
            if (currentPageNum === 1) {
                openBook();
                newFlippedPapers = [...flippedPapers, 'p1'];
            } else if (currentPageNum === totalPages) {
                newFlippedPapers = [...flippedPapers, `p${totalPages}`];
                closeBook(false);
            } else {
                newFlippedPapers = [...flippedPapers, `p${currentPageNum}`];
            }

            setFlippedPapers(newFlippedPapers);
            setCurrentPageNum((prev) => prev + 1);
            setTimeout(() => {
                setFlippedPages(newFlippedPapers);
            }, 500);
            nextPage(currentPageNum);
            if (
                currentPageNum * 2 === detailPages.pages.length - 1 ||
                currentPageNum * 2 === detailPages.pages.length
            ) {
                setIsModalOpen(true);
            }
        }
    };

    const resetAgain = () => {
        setFlippedPapers(['p1']);
        setFlippedPages(['p1']);
        setCurrentPageNum(2);
    };

    let updatedSlides = detailPages.pages
        .map((slide, i) => {
            if (i % 2 === 0) {
                return detailPages.pages.slice(i, i + 2);
            }
            return null;
        })
        .filter(Boolean);

    return (
        <div className="Flip">
            <div
                id="prev-btn"
                className={`${isBookOpen ? 'bookOpen' : ''} ${
                    isBookClosed.isClosed ? 'bookClose' : ''
                }`}
                onClick={() => goPrevPage()}
            >
                {currentPageNum > 2 && (
                    <SvgIcon
                        name={
                            isRightToLeftDirection
                                ? 'reader-view-next-page'
                                : 'reader-view-previous-page'
                        }
                        size="reader-view-navigation"
                    />
                )}
            </div>

            <PageFlipBook
                isBookOpen={isBookOpen}
                isBookClosed={isBookClosed}
                book={book}
                updatedSlides={updatedSlides}
                flippedPapers={flippedPapers}
                flippedPages={flippedPages}
                totalPages={totalPages}
                detailPages={detailPages}
                t={t}
                online={online}
                onFavoriteClicked={onFavoriteClicked}
                likesCount={likesCount}
                isLoggedIn={isLoggedIn}
                setBookReaderStatusWorkflow={setBookReaderStatusWorkflow}
                readAgain={readAgain}
                pageNo={pageNo}
                viewport={viewport}
                resetAgain={resetAgain}
                isRightToLeftDirection={isRightToLeftDirection}
                browserView={browserView}
                onOpenBioModal={onOpenBioModal}
                onOpenModal={onOpenModal}
            />

            <div
                id="next-btn"
                className={`${isBookOpen ? 'bookOpen' : ''} ${
                    isBookClosed.isClosed ? 'bookClose' : ''
                }`}
                onClick={() => goNextPage()}
            >
                {currentPageNum * 2 <= detailPages.pages.length && (
                    <SvgIcon
                        name={
                            isRightToLeftDirection
                                ? 'reader-view-previous-page'
                                : 'reader-view-next-page'
                        }
                        size="reader-view-navigation"
                    />
                )}
            </div>

            {isLoggedIn && !isBookRated && window.navigator.onLine && (
                <Modal
                    isOpen={isModalOpen}
                    style={customStyles}
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.style.overflow = 'auto';
                    }}
                >
                    <BookRating
                        t={t}
                        handleClose={closePopup}
                        bookId={id}
                        bookSlug={slug}
                        history={navigate}
                    />
                </Modal>
            )}
        </div>
    );
};

PageFlipAnimation.propTypes = {
    detailPages: PropTypes.shape({
        pages: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ).isRequired,
    }).isRequired,
    pageNo: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        slug: PropTypes.string.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func,
    likesCount: PropTypes.number,
    isLoggedIn: PropTypes.bool.isRequired,
    viewport: PropTypes.string,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    readAgain: PropTypes.func,
    onOpenBioModal: PropTypes.func,
    onOpenModal: PropTypes.func,
    isBookRated: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired,
};

PageFlipAnimation.defaultProps = {
    onFavoriteClicked: () => {},
    likesCount: 0,
    viewport: 'default',
    readAgain: () => {},
    onOpenBioModal: () => {},
    onOpenModal: () => {},
};

export default withRouter(PageFlipAnimation);
