import React from 'react';
import Link from '../../Link';

const NeutralEmoji = ({
    setHappyEmojiClicked,
    setNeutralEmojiClicked,
    setSadEmojiClicked,
    setRatingId,
    neutralEmojiClicked,
    dataId,
}) => {
    const neutralEmojiHandler = () => {
        setNeutralEmojiClicked(!neutralEmojiClicked);
        setHappyEmojiClicked(true);
        setSadEmojiClicked(true);
        setRatingId(neutralEmojiClicked ? dataId[1].id : '');
    };
    return (
        <Link normal onClick={() => neutralEmojiHandler()}>
            {neutralEmojiClicked ? (
                <img
                    className="emoji_rating"
                    src={require('../../../assets/Okay_outline.svg')}
                    alt="okay_outline"
                />
            ) : (
                <img
                    className="emoji_rating"
                    src={require('../../../assets/Okay_filled.svg')}
                    alt="okay_filled"
                />
            )}
        </Link>
    );
};

export default NeutralEmoji;
