import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../util/utils';
import './Sizer.scss';

const availableSizes = [
    'xxxs',
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'lm',
    'lx',
    'xl',
    'xxl',
    'xxxl',
];

const Sizer = ({
    parentClassName,
    children,
    width,
    height,
    maxHeight,
    maxWidth,
    scrollX,
    scrollY,
}) => {
    const baseClassName = 'pb-sizer';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--height-${height}`]: height,
        [`${baseClassName}--width-${width}`]: width,
        [`${baseClassName}--max-height-${maxHeight}`]: maxHeight,
        [`${baseClassName}--max-width-${maxWidth}`]: maxWidth,
        [`${baseClassName}--scroll-x`]: scrollX,
        [isRightToLeftDirection
            ? `${baseClassName}--scroll-y-arabic`
            : `${baseClassName}--scroll-y`]: scrollY,
        [parentClassName]: parentClassName,
    };

    return <div className={classNames(classes)}>{children}</div>;
};

Sizer.propTypes = {
    parentClassName: PropTypes.string,
    children: PropTypes.node,
    height: PropTypes.oneOf(availableSizes),
    maxHeight: PropTypes.oneOf(availableSizes),
    maxWidth: PropTypes.oneOf(availableSizes),
    scrollX: PropTypes.bool,
    scrollY: PropTypes.bool,
    width: PropTypes.oneOf(availableSizes),
};

export default Sizer;
