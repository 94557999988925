import React from 'react';
import PropTypes from 'prop-types';
import RTRBookTitleData from '../../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookTitleData';
import RTRBookReadAgain from '../../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookReadAgain';
import RTRBookClosingPage from '../../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookClosingPage';

const PageFlipBook = ({
    isBookOpen,
    isBookClosed,
    book,
    updatedSlides,
    flippedPapers,
    flippedPages,
    totalPages,
    detailPages,
    t,
    online,
    onFavoriteClicked,
    likesCount,
    isLoggedIn,
    setBookReaderStatusWorkflow,
    readAgain,
    pageNo,
    viewport,
    resetAgain,
    isRightToLeftDirection,
    browserView,
    onOpenBioModal,
    onOpenModal,
}) => {
    return (
        <div
            id="book"
            className={`book ${isBookOpen ? 'bookOpen' : ''} ${
                isBookClosed.isClosed
                    ? isBookClosed.isAtBeginning
                        ? 'bookCloseBeginning'
                        : 'bookClose'
                    : ''
            } ${book.orientation === 'vertical' ? ' portrait' : ' landScape'}`}
        >
            {updatedSlides.map((slide, i) => {
                const pageNum = i + 1;
                return (
                    <div
                        id={`p${pageNum}`}
                        className={`paper ${
                            flippedPapers.includes(`p${pageNum}`)
                                ? 'flipped'
                                : ''
                        } `}
                        style={{
                            zIndex: flippedPages.includes(`p${pageNum}`)
                                ? 0
                                : totalPages + 1 - pageNum,
                        }}
                        key={i}
                    >
                        <div className="front">
                            <div id={`f${pageNum}`} className="front-content">
                                {slide &&
                                    slide[0] &&
                                    (slide[0].url ? (
                                        <img
                                            src={slide[0].url}
                                            alt="book-reader"
                                            className="fileImage"
                                        />
                                    ) : slide[0].dynamicPage === 3 ? (
                                        <RTRBookReadAgain
                                            bookData={detailPages}
                                            t={t}
                                            book={book}
                                            online={online}
                                            onFavoriteClicked={
                                                onFavoriteClicked
                                            }
                                            likesCount={likesCount}
                                            isLoggedIn={isLoggedIn}
                                            setBookReaderStatusWorkflow={
                                                setBookReaderStatusWorkflow
                                            }
                                            readAgain={readAgain}
                                            pageNo={pageNo}
                                            resetAgain={resetAgain}
                                        />
                                    ) : slide[0].dynamicPage === 4 ? (
                                        <RTRBookClosingPage
                                            bookData={detailPages}
                                            t={t}
                                            book={book}
                                            online={online}
                                            viewport={viewport}
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                        />
                                    ) : (
                                        ''
                                    ))}
                            </div>
                        </div>
                        <div
                            className={`back ${
                                browserView ? 'backSafari' : ''
                            }`}
                        >
                            <div id={`b${pageNum}`} className="back-content">
                                {slide &&
                                    slide[1] &&
                                    (slide[1].url ? (
                                        <img
                                            src={slide[1].url}
                                            alt=""
                                            className="fileImage"
                                        />
                                    ) : slide[1].dynamicPage === 3 ? (
                                        <RTRBookReadAgain
                                            bookData={detailPages}
                                            t={t}
                                            book={book}
                                            online={online}
                                            onFavoriteClicked={
                                                onFavoriteClicked
                                            }
                                            likesCount={likesCount}
                                            isLoggedIn={isLoggedIn}
                                            setBookReaderStatusWorkflow={
                                                setBookReaderStatusWorkflow
                                            }
                                            readAgain={readAgain}
                                            pageNo={pageNo}
                                            resetAgain={resetAgain}
                                        />
                                    ) : slide[1].dynamicPage === 4 ? (
                                        <RTRBookClosingPage
                                            bookData={detailPages}
                                            t={t}
                                            book={book}
                                            online={online}
                                            viewport={viewport}
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                        />
                                    ) : slide[1].dynamicPage === 2 ? (
                                        <RTRBookTitleData
                                            bookData={detailPages}
                                            t={t}
                                            book={book}
                                            online={online}
                                            onFavoriteClicked={
                                                onFavoriteClicked
                                            }
                                            likesCount={likesCount}
                                            isLoggedIn={isLoggedIn}
                                            onOpenBioModal={onOpenBioModal}
                                            onOpenModal={onOpenModal}
                                        />
                                    ) : (
                                        ''
                                    ))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

PageFlipBook.propTypes = {
    isBookOpen: PropTypes.bool.isRequired,
    isBookClosed: PropTypes.shape({
        isClosed: PropTypes.bool.isRequired,
        isAtBeginning: PropTypes.bool.isRequired,
    }).isRequired,
    book: PropTypes.shape({
        orientation: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
    }).isRequired,
    updatedSlides: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                dynamicPage: PropTypes.number,
            })
        )
    ).isRequired,
    flippedPapers: PropTypes.arrayOf(PropTypes.string).isRequired,
    flippedPages: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalPages: PropTypes.number.isRequired,
    detailPages: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    likesCount: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    readAgain: PropTypes.bool.isRequired,
    pageNo: PropTypes.number.isRequired,
    viewport: PropTypes.object.isRequired,
    resetAgain: PropTypes.func.isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
    browserView: PropTypes.bool.isRequired,
    onOpenBioModal: PropTypes.func.isRequired,
    onOpenModal: PropTypes.func.isRequired,
};

export default PageFlipBook;
