import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Joyride from 'react-joyride';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import closeIcon from '../../assets/closeIcon.png';
import heartFilled from '../../assets/heart-filled.svg';
import heartUnFilled from '../../assets/heart-unfilled.svg';
import { favouriteVideoWorkflow } from '../../redux/videoActions';
import BookDetailSummary from '../BookDetailSummary';
import Button from '../Button';
import DocumentHeader from '../DocumentHeader';
import DownloadRange from '../DownloadRange';
import IframeContainer from '../IframeContainer/IframeContainer';
import Link from '../Link';
import Modal from '../Modal';
import ShareMenu from '../ShareMenu';
import Comments from '../Comments';
import SvgIcon from '../SvgIcon';
import VideoPlayer from '../VideoPlayer';
import SimilarVideos from './SimilarVideos';
import './VideoDetail.scss';

const mapDispatchToProps = {
    favouriteVideoWorkflow,
};

const VideoDetail = (props) => {
    const {
        t,
        parentClassName,
        online,
        isLoggedIn,
        viewport,
        videoDetail,
        videosVideoThumbnailGTM,
        videosVideoWatchGTM,
        videosVideoDownloadGTM,
        videosVideoFavoriteGTM,
        myLanguage,
        roles,
        helpStatus,
        readCount,
        videoId,
        readsCount,
        likesCount: initialLikesCount,
        isFavourite: initialIsFavourite,
        favouriteVideoWorkflow,
        videosVideoCloseGTM,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const [likesCount, setLikesCount] = useState(initialLikesCount);
    const [isFavourite, setIsFavourite] = useState(initialIsFavourite);

    useEffect(() => {
        setLikesCount(initialLikesCount);
    }, [readsCount, initialLikesCount]);

    const videoModalOpen = useCallback(() => {
        setIsModalVisible(true);
        setIsVideoPlay(true);
        readCount(videoId);
    }, [readCount, videoId]);

    const videoModalClose = useCallback(() => {
        setIsModalVisible(false);
        setIsVideoPlay(false);
    }, []);

    const onFavoriteClicked = useCallback(() => {
        favouriteVideoWorkflow(videoId);
        if (isFavourite) {
            setLikesCount((prev) => prev - 1);
            setIsFavourite(false);
        } else {
            setLikesCount((prev) => prev + 1);
            setIsFavourite(true);
        }
    }, [favouriteVideoWorkflow, videoId, isFavourite]);

    const videoPlayerModal = () => (
        <Modal>
            <div className="__close">
                <Link
                    onClick={videoModalClose}
                    dataGTM={videosVideoCloseGTM}
                >
                    <img
                        className="__profile-images"
                        src={closeIcon}
                        alt=""
                        data-gtm={videosVideoCloseGTM}
                    />
                </Link>
            </div>
            <div>
                <VideoPlayer
                    name={videoDetail.name}
                    videoSrc={videoDetail.materialUrl}
                    videoPlay={isVideoPlay}
                />
            </div>
        </Modal>
    );

    const classes = {
        'pb-video-detail': true,
        [parentClassName]: parentClassName,
    };

    const heartEl = isFavourite ? (
        <img
            src={heartFilled}
            alt="heart-filled"
            className="__heart-unfilled"
        />
    ) : (
        <img
            src={heartUnFilled}
            alt="heart-unfilled"
            className="__heart-unfilled"
        />
    );

    const favouriteStat = likesCount >= 0 && online && (
        <div className={parentClassName}>
            <Link
                normal
                favouriteShare
                onClick={isLoggedIn ? onFavoriteClicked : null}
                dataGTM={videosVideoFavoriteGTM}
            >
                {heartEl}
                {isFavourite}
                <span style={{ marginLeft: '3px', fontSize: '1rem' }}>
                    {likesCount}
                </span>
            </Link>
        </div>
    );

    const baseClassName = 'pb-video-detail';

    const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

    const {
        id,
        thumbUrl,
        name,
        summary,
        duration,
        similar_videos,
        dpi_url,
        isDownloadable,
        youtube_video_identifier
    } = videoDetail;

    let steps = [
        {
            target: '.pb-link--fav-share',
            content: t('Video.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.__video-watch-btn',
            content: t('Video.watchHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__video-download-btn',
            content: t('Video.saveOfflineHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__comments',
            content: t('Video.disqusHelpGuide'),
            placementBeacon: 'left',
        },
    ];

    return (
        <div className={classNames(classes)}>
            <DocumentHeader
                title={videoDetail.name}
                description={videoDetail.summary}
                keywords={videoDetail.slug}
                imageUrl={videoDetail.thumbUrl}
                imgAlt={videoDetail.name}
            />
            <Joyride
                enabled
                steps={steps}
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={false}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <DocumentTitle
                title={`${videoDetail.name} - ${t('global.site-title')}`}
            />
            <div className="__video-detail-section">
                {
                    youtube_video_identifier ? null :
                        <div className="__video-detail-left">
                            <Link
                                onClick={() => videoModalOpen()}
                                dataGTM={videosVideoThumbnailGTM}
                            >
                                <div
                                    className="__video-detail-left-content"
                                    data-gtm={videosVideoThumbnailGTM}
                                >
                                    <div
                                        className="__video-play"
                                        data-gtm={videosVideoThumbnailGTM}
                                    >
                                        <SvgIcon
                                            name="play"
                                            size="l"
                                            dataGTM={videosVideoThumbnailGTM}
                                        />
                                    </div>
                                    <div
                                        className="__thumbnailImg"
                                        data-gtm={videosVideoThumbnailGTM}
                                    >
                                        <img
                                            src={thumbUrl}
                                            alt=""
                                            data-gtm={videosVideoThumbnailGTM}
                                        />
                                    </div>
                                    <div
                                        className="__video-duration"
                                        data-gtm={videosVideoThumbnailGTM}
                                    >
                                        {duration}
                                    </div>
                                </div>
                            </Link>
                        </div>
                }

                <div className={youtube_video_identifier ? "__video-detail" : "__video-detail-right"}>
                    <div className="__video-detail-right-content">
                        <div
                            className={
                                myLanguage === 'lo'
                                    ? '__video-detail-lo-title'
                                    : isRightToLeftDirection
                                        ? '__video-detail-title __text-align-right'
                                        : '__video-detail-title'
                            }
                        >
                            {name}
                        </div>

                        {/* Youtube Embedded Player*/}
                        {
                            youtube_video_identifier ?
                                <IframeContainer
                                    title={name}
                                    videoId={youtube_video_identifier}
                                />
                                : null
                        }
                        <div className="__status-share">
                            <div style={{ display: 'flex' }}>
                                <div className={
                                    isRightToLeftDirection
                                        ? "__video-like-count-rtl"
                                        : "__video-like-count"
                                }>
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? `${baseClassName}__like-count-rtl`
                                                : `${baseClassName}__like-count`
                                        }
                                    >
                                        {favouriteStat}
                                    </div>
                                    <div>
                                        <span>
                                            <SvgIcon
                                                name="playIcon"
                                                size="sm"
                                                pushRight
                                                svgIcon
                                                playButton
                                            />
                                        </span>
                                        <span>{readsCount}</span>
                                    </div>
                                </div>

                                {youtube_video_identifier ?
                                    null :
                                    <div className="__share-section">
                                        <div>
                                            <ShareMenu
                                                title={name}
                                                href={window.location.href + '/'}
                                                desc={summary}
                                                hideTitle
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="__video-detail-desc">
                            <BookDetailSummary
                                title={t('Book.summary')}
                                description={summary}
                                hideSummary
                            />
                        </div>
                        {
                            youtube_video_identifier ? null :
                                <div className="__video-btn-section">
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? '__video-watch-btn-rtl margin-left'
                                                : '__video-watch-btn'
                                        }
                                    >

                                        <Button
                                            iconLeft="video"
                                            buttonWatchBlue
                                            fullWidth
                                            label={t(
                                                'Video.watch'
                                            )}
                                            variant="primary"
                                            onClick={() => videoModalOpen()}
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                            dataGTM={videosVideoWatchGTM}
                                        />
                                    </div>
                                    <div>
                                        {isDownloadable ? (
                                            <div className="__video-download-btn">
                                                <DownloadRange
                                                    dataGTM={videosVideoDownloadGTM}
                                                    dpi_url={dpi_url}
                                                />
                                            </div>
                                        ) : (
                                            <div className="__video-download-disable-btn">
                                                <DownloadRange
                                                    dataGTM={videosVideoDownloadGTM}
                                                    dpi_url={dpi_url}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {isLoggedIn ? (
                <div className={`${baseClassName}__disqus-section`}>
                    <div className="__disqussion">
                        <Comments
                            {...props}
                            source="video"
                            id={id}
                            roles={roles}
                        />
                    </div>
                </div>
            ) : null}
            <div>
                <SimilarVideos
                    isLoggedIn={isLoggedIn}
                    myLanguage={myLanguage}
                    offline={!online}
                    similarVideos={similar_videos}
                    t={t}
                    viewport={viewport}
                />
            </div>
            {isModalVisible && videoPlayerModal()}
        </div>
    );
}

VideoDetail.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        helpStatus: state.help.helpStatus,
        roles: state.user.profile.roles,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate()(VideoDetail));
