import React from 'react';
import Loader from '../Loader';

import './LoaderBlock.scss';

const LoaderBlock = () => {
    const baseClassName = 'pb-loader-block';

    return (
        <div className={baseClassName}>
            <div className={`${baseClassName}__wrapper`}>
                <Loader size="l" />
            </div>
        </div>
    );
};

export default LoaderBlock;
