import classNames from 'classnames';
import Carousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Link from '../Link';
import SvgIcon from '../SvgIcon';

import './CardsCarousel.scss';

const CardsCarousel = ({
    children,
    viewport,
    parentClassName,
    cardsInFrame = {
        small: 2,
        medium: 3,
        large: 4,
        xxlarge: 6,
    },
    currentIndex = 0,
    highlightCurrent,
    popoutControls,
    isBookReaderPage = false,
}) => {
    const [carousels, setCarousels] = useState({});
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(false);
    const carouselRef = useRef(null);

    const setCarouselData = (key, ref) => {
        setCarousels((prev) => ({
            ...prev,
            [key]: ref,
        }));
    };



    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [children]);

    const previousSlide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const carousel = carouselRef.current;
        carousel && carousel.previousSlide();
        disablePrevious('previousSlide')
        setNextDisabled(false)
    };

    const nextSlide = (e) => {
        e.preventDefault();
        const carousel = carouselRef.current;
        carousel && carousel.nextSlide();
        disableNext()
        disablePrevious('nextSlide')
    };

    const disableNext = () => {
        const carousel = carouselRef.current;
        console.log('carouselRef.currensssss =', carousel.state.currentSlide + carousel.state.slidesToScroll, carousel.state.slideCount)
        return (
            carousel &&
            !carousel.props.wrapAround &&
            carousel.state.currentSlide + carousel.state.slidesToScroll >=
            carousel.state.slideCount
        );
    };

    const disablePrevious = (type) => {
        const carousel = carouselRef.current;
        if (type === 'nextSlide' && carousel.state.currentSlide === 0) {
            return setPreviousDisabled(false)
        }

        if (type === 'nextSlide' && carousel.state.currentSlide === (carousel.state.slideCount - carousel.state.slidesToScroll)) {
            return setNextDisabled(true)
        }

        if (type === 'previousSlide' && carousel.state.currentSlide === carousel.state.slidesToScroll) {
            disablePrevious('')
            return setPreviousDisabled(true)
        }

        if (carousel) {
            return (
                setPreviousDisabled(carousel.state.currentSlide === 0)
            );
        }
    };

    const determineCardsInFrame = () => {
        if (viewport.xxlarge && cardsInFrame.xxlarge) {
            return cardsInFrame.large;
        } else if (viewport.xlarge && cardsInFrame.xlarge) {
            return cardsInFrame.large;
        } else if (viewport.large && cardsInFrame.large) {
            return cardsInFrame.medium;
        } else if (viewport.mediumToLarge && cardsInFrame.medium) {
            return cardsInFrame.medium;
        } else if (viewport.medium && cardsInFrame.medium) {
            return cardsInFrame.small;
        } else if (viewport.small && cardsInFrame.small) {
            return cardsInFrame.small;
        }
    };

    const cards = determineCardsInFrame();

    const baseClassName = 'pb-cards-carousel';
    const classes = classNames(baseClassName, {
        [`${baseClassName}--highlight-current`]: highlightCurrent,
        [`${baseClassName}--popout-controls`]: popoutControls,
        [parentClassName]: parentClassName,
    });

    useEffect(() => {
        disableNext()
        disablePrevious()
    }, [previousSlide, nextSlide]);

    return (
        <div className={classes}>
            {children.length > 4 && isBookReaderPage && (
                <Link
                    parentClassName="bookReader__link--previous"
                    onClick={previousSlide}
                    disabled={previousDisabled}
                    theme="dark"
                    dataGTM="card-carousel-left"
                >
                    <img
                        className="RTRSimilarbooksPrev"
                        src={require('../../assets/RTRSimilarbooksPrev.svg')}
                        alt="Previous"
                    />
                </Link>
            )}
            <Carousel
                ref={(ref) => {
                    carouselRef.current = ref;
                    console.log('previousDisabledpreviousDisabled = ', previousDisabled);
                    // setCarouselData('carousel', ref);
                    if (ref && !carouselRef.current) {
                        // carouselRef.current = ref;
                        setCarouselData('carousel', ref);
                    }
                }}
                decorators={[]}
                slidesToShow={cards}
                slidesToScroll="auto"
                slideIndex={currentIndex}
            >
                {React.Children.toArray(children).map((child, i) => {
                    const cardBaseClassName = `${baseClassName}__card`;
                    const cardClasses = classNames(cardBaseClassName, {
                        [`${cardBaseClassName}--active`]:
                            currentIndex >= 0 && i === currentIndex,
                    });
                    return (
                        <div
                            className={cardClasses}
                            key={`${baseClassName}__card-${i}`}
                        >
                            {child}
                        </div>
                    );
                })}
            </Carousel>
            {children.length > 4 && isBookReaderPage && (
                <Link
                    parentClassName="bookReader__link--next"
                    onClick={nextSlide}
                    disabled={nextDisabled}
                    theme="dark"
                    dataGTM="card-carousel-right"
                >
                    <img
                        className="RTRSimilarbooksNext"
                        src={require('../../assets/RTRSimilarbooksNext.svg')}
                        alt="Next"
                    />
                </Link>
            )}
            {children.length > 4 && !isBookReaderPage && (
                <div>
                    <Link
                        parentClassName={`${baseClassName}__link ${baseClassName}__link--previous`}
                        onClick={previousSlide}
                        disabled={previousDisabled}
                        theme="dark"
                        dataGTM="card-carousel-left"
                    >
                        <SvgIcon name="Left" size="l" />
                    </Link>
                    <Link
                        parentClassName={`${baseClassName}__link ${baseClassName}__link--next`}
                        onClick={nextSlide}
                        disabled={nextDisabled}
                        theme="dark"
                        dataGTM="card-carousel-right"
                    >
                        <SvgIcon name="Right" size="l" />
                    </Link>
                </div>
            )}
        </div>
    );
};

CardsCarousel.propTypes = {
    children: PropTypes.node.isRequired,
    viewport: PropTypes.object.isRequired,
    parentClassName: PropTypes.string,
    cardsInFrame: PropTypes.object,
    currentIndex: PropTypes.number,
    highlightCurrent: PropTypes.bool,
    popoutControls: PropTypes.bool,
};

export default CardsCarousel;
