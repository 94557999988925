import React from 'react';
import PropTypes from 'prop-types';
import RTRMobileFirstPage from './RTRMobileFirstPage';
import RTRMobileClosingPage from './RTRMobileClosingPage';
import RTRMobileLastPage from './RTRMobileLastPage';
import RTRMobileBookPages from './RTRMobileBookPages';

const RTRReaderContentMobile = ({
    handleTouchStart,
    handleTouchMove,
    mobilePageStatus,
    bookData,
    onOpenModal,
    t,
    isModalVisible,
    onOpenBioModal,
    isBioModalInfo,
    onCloseBioModal,
    book,
    online,
    onFavoriteClicked,
    isLoggedIn,
    likesCount,
    totalPageCount,
    setBookReaderStatusWorkflow,
    readAgain,
    modalIsOpen,
    closePopup,
    navigate,
}) => {
    return (
        <div className="RTRBookReaderContent-Mobile">
            <div
                className="bookReaderPageWrapper"
                id="bookReaderPageWrapper"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
            >
                {mobilePageStatus === 0 && (
                    <RTRMobileFirstPage
                        t={t}
                        bookData={bookData}
                        onOpenModal={onOpenModal}
                        isModalVisible={isModalVisible}
                        onOpenBioModal={onOpenBioModal}
                        isBioModalInfo={isBioModalInfo}
                        onCloseBioModal={onCloseBioModal}
                        book={book}
                        online={online}
                        onFavoriteClicked={onFavoriteClicked}
                        isLoggedIn={isLoggedIn}
                        likesCount={likesCount}
                    />
                )}
                {mobilePageStatus === totalPageCount + 1 && (
                    <RTRMobileClosingPage
                        t={t}
                        bookData={bookData}
                        totalPageCount={totalPageCount}
                        setBookReaderStatusWorkflow={
                            setBookReaderStatusWorkflow
                        }
                        book={book}
                        readAgain={readAgain}
                        online={online}
                        onFavoriteClicked={onFavoriteClicked}
                        isLoggedIn={isLoggedIn}
                        likesCount={likesCount}
                    />
                )}
                {mobilePageStatus === totalPageCount + 2 && (
                    <RTRMobileLastPage
                        t={t}
                        bookData={bookData}
                        isLoggedIn={isLoggedIn}
                        modalIsOpen={modalIsOpen}
                        closePopup={closePopup}
                        book={book}
                        navigate={navigate}
                    />
                )}
                {!(mobilePageStatus === 0) &&
                    !(
                        mobilePageStatus === totalPageCount + 1 &&
                        mobilePageStatus === totalPageCount + 2
                    ) &&
                    !(mobilePageStatus === totalPageCount + 1) &&
                    bookData.pages[mobilePageStatus - 1] && (
                        <RTRMobileBookPages
                            bookData={bookData}
                            mobilePageStatus={mobilePageStatus}
                        />
                    )}
            </div>
        </div>
    );
};



RTRReaderContentMobile.propTypes = {
    handleTouchStart: PropTypes.func.isRequired,
    handleTouchMove: PropTypes.func.isRequired,
    mobilePageStatus: PropTypes.number.isRequired,
    bookData: PropTypes.shape({
        pages: PropTypes.array.isRequired,
    }).isRequired,
    onOpenModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isModalVisible: PropTypes.object.isRequired,
    onOpenBioModal: PropTypes.func.isRequired,
    isBioModalInfo: PropTypes.object.isRequired,
    onCloseBioModal: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
    totalPageCount: PropTypes.number.isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    readAgain: PropTypes.bool.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default RTRReaderContentMobile;
