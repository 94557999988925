import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import LoaderBlock from '../../LoaderBlock';
import Pagination from '../../Pagination';
import Grid from '../../Grid';
import { fetchReferencesWorkflow } from '../../../redux/referencesActions';
import { recordGaEvents } from '../../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../../redux/slimNotificationActions';
import ContentStyler from '../../ContentStyler';
import Block from '../../Block';
import ResourceCard from '../../ResourceCard';
import NoResults from './NoResults';
import './BooksSearchResultsContainer.scss';

const ResourceGridEl = ({ references, t }) => {
    return (
        <Grid variant="4up">
            {references.map((referenceItem) => (
                <div key={referenceItem.id}>
                    <ResourceCard
                        id={referenceItem.id}
                        slug={referenceItem.slug}
                        title={referenceItem.title}
                        contentUrl={referenceItem.contentUrl}
                        likesCount={referenceItem.likesCount}
                        readsCount={referenceItem.readsCount}
                        isFavourite={referenceItem.is_favourite}
                        coverImage={referenceItem.thumbUrl}
                        resourceThumbnailGTM="search-resource-thumbnail"
                        resourceTitleGTM="search-resource-title"
                    />
                </div>
            ))}
        </Grid>
    );
};

const BooksSearchResultsContainer = ({
    t,
    references,
    isFetchingReferences,
    isFetchingMoreReferences,
    loadedPages,
    totalReferencesCount,
    hits,
    viewport,
    appliedFilters,
    searchQuery,
    fetchReferencesWorkflow,
}) => {
    useEffect(() => {
        fetchReferencesWorkflow(appliedFilters);
    }, [appliedFilters, fetchReferencesWorkflow]);

    const onLoadMore = () => {
        fetchReferencesWorkflow(appliedFilters, loadedPages + 1);
    };

    const myLanguage = localStorage.getItem('locale');
    const shouldShowLoadMore =
        loadedPages && loadedPages < totalReferencesCount;

    const resourceTitleEl = searchQuery ? (
        <h3>
            {myLanguage !== 'ne'
                ? `${hits} ${t('Search.results-for-count')} ‘${searchQuery}’`
                : `‘${searchQuery}’ ${t(
                      'Search.results-for-count-1'
                  )} ${hits} ${t('Search.results-for-count-2')}`}
        </h3>
    ) : (
        <React.Fragment>
            <h3>{t('global.resources')}</h3>
            <h3 className="__normal">({hits})</h3>
        </React.Fragment>
    );

    return (
        <Block background="transparent">
            <ContentStyler>
                <div className="__search-result-title">{resourceTitleEl}</div>
            </ContentStyler>
            <div className="book-section">
                {isFetchingReferences || !references ? (
                    <LoaderBlock />
                ) : (
                    <div>
                        {references.length > 0 ? (
                            <div>
                                <ResourceGridEl references={references} t={t} />
                                {shouldShowLoadMore && (
                                    <Pagination
                                        onClick={onLoadMore}
                                        loading={isFetchingMoreReferences}
                                        dataGTM="resources-view-more"
                                    />
                                )}
                            </div>
                        ) : null}
                        {!totalReferencesCount && <NoResults />}
                    </div>
                )}
            </div>
        </Block>
    );
};

const mapDispatchToProps = {
    fetchReferencesWorkflow,
    recordGaEvents,
    addSlimNotification,
};

const mapStateToProps = ({
    references,
    viewport,
    allFilters: { readFilters },
}) => ({
    references: references.references,
    isFetchingReferences: references.isFetchingReferences,
    isFetchingMoreReferences: references.isFetchingMoreReferences,
    loadedPages: references.loadedPages,
    totalReferencesCount: references.totalReferencesCount,
    hits: references.resourceHits,
    viewport,
    appliedFilters: readFilters,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(BooksSearchResultsContainer));
