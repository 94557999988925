import React from 'react';
import PropTypes from 'prop-types';
import EditReadingListButton from './EditReadingListButton';
import CreateReadingListButton from './CreateReadingListButton';
import DisabledReadingListButton from './DisabledReadingListButton';

const CreateNewReadingListButton = ({
    t,
    readingListName,
    readingListDesc,
    edit,
    listDetails,
    editSubmit,
    createNewReadingListActionAsync,
    baseClassName,
    BtnDisabled,
}) => {
    const buttonText = edit
        ? t('Readinglist.Update')
        : t('Readinglist.add-books');
    return (
        <div className="__add-book-button-section">
            {readingListName && readingListDesc ? (
                edit ? (
                    <EditReadingListButton
                        listDetails={listDetails}
                        editSubmit={editSubmit}
                        readingListDesc={readingListDesc}
                        readingListName={readingListName}
                        buttonText={buttonText}
                    />
                ) : (
                    <CreateReadingListButton
                        createNewReadingListActionAsync={
                            createNewReadingListActionAsync
                        }
                        readingListDesc={readingListDesc}
                        readingListName={readingListName}
                        buttonText={buttonText}
                    />
                )
            ) : (
                <DisabledReadingListButton
                    baseClassName={baseClassName}
                    BtnDisabled={BtnDisabled}
                    buttonText={buttonText}
                    isEdit={edit}
                />
            )}
        </div>
    );
};

CreateNewReadingListButton.propTypes = {
    t: PropTypes.func.isRequired,
    readingListName: PropTypes.string.isRequired,
    readingListDesc: PropTypes.string.isRequired,
    edit: PropTypes.bool.isRequired,
    listDetails: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    editSubmit: PropTypes.func.isRequired,
    createNewReadingListActionAsync: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    BtnDisabled: PropTypes.bool.isRequired,
};

export default CreateNewReadingListButton;
