import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import { links } from '../../../lib/constants';
import { isRightToLeftDirection } from '../../../util/utils';

const MyReadingListDetailOptions = ({
    t,
    manageReadingList,
    readingListsBookDetail,
    openDeleteModal,
    openEditModal,
}) => {
    return (
        <div
            className={
                isRightToLeftDirection
                    ? '__myReadinglist-action-buttons-arabic'
                    : '__myReadinglist-action-buttons'
            }
        >
            <div onClick={() => manageReadingList(readingListsBookDetail)}>
                <Link
                    href={links.managereadinglists(readingListsBookDetail.slug)}
                    isInternal
                    paddingTop
                    dataGTM="manage-reading-list"
                >
                    <img
                        style={{
                            margin: '0 3px 3px 3px',
                            height: '1rem',
                        }}
                        src={require('../../../assets/settings.svg')}
                        alt="Manage Reading List Icon"
                    />
                    <span
                        className="__action-button-text"
                        data-gtm="manage-reading-list"
                    >
                        {t('Readinglist.manage-reading-list')}
                    </span>
                </Link>
            </div>
            <div className="__delete-button" onClick={openDeleteModal}>
                <img
                    style={{
                        margin: '0 3px 3px 3px',
                        height: '1rem',
                    }}
                    src={require('../../../assets/delete.svg')}
                    alt="Manage Reading List Icon"
                />
                <span className="__action-button-text">
                    {t('Readinglist.delete-reading-list')}
                </span>
            </div>
            <div className="__edit-button" onClick={openEditModal}>
                <img
                    style={{
                        margin: '0 3px 3px 3px',
                        height: '1rem',
                    }}
                    src={require('../../../assets/edit.svg')}
                    alt="Manage Reading List Icon"
                />
                <span className="__action-button-text">
                    {t('Readinglist.editFieldData')}
                </span>
            </div>
        </div>
    );
};

MyReadingListDetailOptions.propTypes = {
    t: PropTypes.func.isRequired,
    manageReadingList: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }).isRequired,
    openDeleteModal: PropTypes.func.isRequired,
    openEditModal: PropTypes.func.isRequired,
};
export default MyReadingListDetailOptions;
