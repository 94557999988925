
export const getLanguageList = (icon) => [
    {
        name: 'English ',
        queryValue: 'en',
        flagIcon: icon,
        localeCode: '(EN)',
    },
    {
        name: 'Bahasa Indonesia ',
        queryValue: 'id',
        flagIcon: icon,
        localeCode: '(ID)',
    },
    {
        name: 'မြန်မာ',
        queryValue: 'my',
        flagIcon: icon,
        localeCode: '(MY)',
    },
    {
        name: 'हिंदी',
        queryValue: 'hi',
        flagIcon: icon,
        localeCode: '(HI)',
    },
    {
        name: 'Tiếng Việt ',
        queryValue: 'vi',
        flagIcon: icon,
        localeCode: '(VI)',
    },
    {
        name: 'KiSwahili ',
        queryValue: 'sw',
        flagIcon: icon,
        localeCode: '(SW)',
    },
    {
        name: 'मराठी',
        queryValue: 'mr',
        flagIcon: icon,
        localeCode: '(MR)',
    },
    {
        name: 'नेपाली',
        queryValue: 'ne',
        flagIcon: icon,
        localeCode: '(NE)',
    },
    {
        name: 'বাংলা',
        queryValue: 'bn',
        flagIcon: icon,
        localeCode: '(BN)',
    },
    {
        name: 'සිංහල',
        queryValue: 'si',
        flagIcon: icon,
        localeCode: '(SI)',
    },
    {
        name: 'ខ្មែរ',
        queryValue: 'km',
        flagIcon: icon,
        localeCode: '(KM)',
    },
    {
        name: 'ລາວ',
        queryValue: 'lo',
        flagIcon: icon,
        localeCode: '(LO)',
    },
    {
        name: '繁體中文',
        queryValue: 'zh_TW',
        flagIcon: icon,
        localeCode: '(ZH-HK)',
    },
    {
        name: 'தமிழ்',
        queryValue: 'ta',
        flagIcon: icon,
        localeCode: '(TA)',
    },
    {
        name: 'Filipino ',
        queryValue: 'fil',
        flagIcon: icon,
        localeCode: '(FIL)',
    },
    {
        name: '简体中文',
        queryValue: 'zh_CN',
        flagIcon: icon,
        localeCode: '(ZH-CN)',
    },
    {
        name: 'Español',
        queryValue: 'es',
        flagIcon: icon,
        localeCode: '(ES)',
    },
    {
        name: 'عربى',
        queryValue: 'ar',
        flagIcon: icon,
        localeCode: '(AR)',
    },
    {
        name: 'Luganda',
        queryValue: 'lg',
        flagIcon: icon,
        localeCode: '(LG)',
    },
    {
        name: 'اردو',
        queryValue: 'ur',
        flagIcon: icon,
        localeCode: '(UR)',
    },
    {
        name: 'తెలుగు',
        queryValue: 'te',
        flagIcon: icon,
        localeCode: '(TE)',
    },
    {
        name: 'ಕನ್ನಡ',
        queryValue: 'kn',
        flagIcon: icon,
        localeCode: '(KN)',
    },
    {
        name: 'Runyankore-Rukiga',
        queryValue: 'nyn',
        flagIcon: icon,
        localeCode: '(NYN)',
    },
    {
        name: 'Gujarati',
        queryValue: 'gu',
        flagIcon: icon,
        localeCode: '(GU)',
    }
]