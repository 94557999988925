import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Banner } from '../../../api';
import HeroCarousel from '../../HeroCarousel';

const ReadingListBanner = () => {
    const [bannerData, setBannerData] = useState([]);

    useEffect(() => {
        Banner.fetchBannerImage('reading_lists')
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setBannerData(response.data.bannerImages);
            })
            .catch((error) => {
                setBannerData([]);
            });
    }, []);
    return <HeroCarousel banners={bannerData} padding130={false} />;
};

ReadingListBanner.propTypes = {
    banners: PropTypes.array,
};
export default ReadingListBanner;
