import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Sizer from '../Sizer';
import List from '../List';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import './BookRelatedCollection.scss';
import { fetchReadingListBooksWorkflow } from '../../redux/readingListsActions';

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
};
const mapStateToProps = () => ({});

const BookRelatedCollection = ({
    parentClassName,
    title,
    book,
    bookRelatedReadingLists,
    isRightToLeftDirection,
    fetchReadingListBooksWorkflow,
}) => {
    const baseClassName = 'pb-book-related-collection';

    const fetchBooks = (slug) => {
        fetchReadingListBooksWorkflow(slug);
    };

    return (
        <div className={baseClassName}>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__title-rtl`
                        : `${baseClassName}__title`
                }
            >
                {title}
            </div>

            <Sizer maxHeight="ml" scrollY>
                <List bookRelatedCollection={true}>
                    {book.lists.map((list, i) => (
                        <div
                            key={i}
                            className={`${baseClassName}__summary-collection`}
                            onClick={() => fetchBooks(list.slug)}
                            data-gtm={bookRelatedReadingLists}
                        >
                            <Link
                                bookRelatedCollection={true}
                                isInternal={true}
                                key={i}
                                href={'/readinglists/' + list.slug}
                                dataGTM={bookRelatedReadingLists}
                            >
                                {list.title}
                            </Link>

                            <div className={`${baseClassName}__collection`}>
                                <Link
                                    href={'/readinglists/' + list.slug}
                                    dataGTM={bookRelatedReadingLists}
                                >
                                    <SvgIcon
                                        name="arrow-right"
                                        size="m"
                                        pushRight
                                        data-gtm={bookRelatedReadingLists}
                                    />
                                </Link>
                            </div>
                        </div>
                    ))}
                </List>
            </Sizer>
        </div>
    );
};

BookRelatedCollection.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    book: PropTypes.shape({
        lists: PropTypes.arrayOf(
            PropTypes.shape({
                slug: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    bookRelatedReadingLists: PropTypes.string,
    isRightToLeftDirection: PropTypes.bool,
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(BookRelatedCollection)
);
