import { sortOptions } from '../../../lib/constants';

export const languages = {
    en: 'English',
    id: 'Bahasa Indonesia',
    my: 'Burmese',
    hi: 'Hindi',
    vi: 'Tiếng Việt',
    sw: 'KiSwahili',
    mr: 'Marathi',
    ne: 'Nepali',
    bn: 'Bengali',
    si: 'Sinhala',
    km: 'Khmer',
    lo: 'Lao',
    zh_TW: 'Chinese (Traditional)',
    ta: 'Tamil',
    fil: 'Filipino',
    zh_CN: 'Chinese (Simplified)',
    es: 'Spanish',
    ar: 'Arabic',
    gu: 'Gujarati',
};

export const tabFilters = {
    books: 'readFilters',
    lists: 'listsFilters',
    images: 'imagesFilters',
    people: 'peopleFilters',
};

export const defaultFilterValues = {
    category: [],
    publisher: [],
    level: [],
    language: [],
    sort: sortOptions.newArrivals,
    tags: [],
    query: '',
    style: [],
};
