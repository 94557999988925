import { sortOptions } from '../../../lib/constants';

export const filtersBarSortOptions = (t) => [
    {
        name: t('sort.relevance'),
        queryValue: sortOptions.relevance,
    },
    {
        name: t('sort.new-arrivals-images'),
        queryValue: sortOptions.newArrivals,
    },
    {
        name: t('sort.most-viewed'),
        queryValue: sortOptions.mostViewed,
    },
    {
        name: t('sort.most-liked'),
        queryValue: sortOptions.mostLiked,
    },
];
