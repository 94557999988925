import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk } from 'lodash';
import './HorizontalGrid.scss';

const HorizontalGrid = ({ children, rows = 1, cellWidth = 'm' }) => {
    const baseClassName = 'pb-horizontal-grid';

    const childrenGroupedByRow = chunk(React.Children.toArray(children), rows);

    const classes = classNames(baseClassName, {
        [`${baseClassName}--${cellWidth}`]: cellWidth,
    });

    const columnsEls = childrenGroupedByRow.map((col, colIndex) => (
        <div className={`${baseClassName}__col`} key={`col-${colIndex}`}>
            {col.map((child, cellIndex) => (
                <div
                    className={`${baseClassName}__cell`}
                    key={`cell-${cellIndex}`}
                >
                    {child}
                </div>
            ))}
        </div>
    ));

    return (
        <div className={classes}>
            <div className={`${baseClassName}__wrapper`}>{columnsEls}</div>
        </div>
    );
};

HorizontalGrid.propTypes = {
    children: PropTypes.node,
    rows: PropTypes.number,
    cellWidth: PropTypes.oneOf([
        'm',
        'l',
        'xl',
        'xxl',
        'book-card',
        'image-card',
        'category-card',
        'interview-card',
    ]),
};

export default HorizontalGrid;
