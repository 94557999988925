import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';

const BioModal = ({ onCloseBioModal, isBioModalInfo }) => {
    return (
        <Modal
            style={{ zIndex: 9999 }}
            header={'Biography'}
            footer={''}
            onClose={onCloseBioModal}
        >
            <p>
                <span>{`Name: ${isBioModalInfo.first_name} ${isBioModalInfo.last_name}`}</span>
            </p>
            <p>
                <span>{`Email: ${isBioModalInfo.email}`}</span>
            </p>
            {isBioModalInfo.bio && (
                <p>
                    <h3>{`Biography: `}</h3>
                    <p>{isBioModalInfo.bio}</p>
                </p>
            )}
        </Modal>
    );
};

BioModal.propTypes = {
    onCloseBioModal: PropTypes.func.isRequired,
    isBioModalInfo: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        bio: PropTypes.string,
    }).isRequired,
};

export default BioModal;
