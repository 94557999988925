import React from 'react';
import PropTypes from 'prop-types';
import MyReadingListDetail from './components/MyReadingListDetail';

const MyReadingListDetailContainer = ({ parentClassName }) => {
    return (
        <div className={parentClassName}>
            <MyReadingListDetail />
        </div>
    );
};

MyReadingListDetailContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default MyReadingListDetailContainer;
