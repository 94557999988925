import React from 'react';
import PropTypes from 'prop-types';
import SvgSymbol from '../SvgSymbol';
import './SvgIcon.scss';

const baseClassName = 'pb-svg-icon';

const SvgIcon = ({
    name,
    parentClassName,
    variant,
    pushRight,
    pushLeft,
    size,
    svgIcon,
    pushLeftMedium,
    pushIcon,
    pushLeftSmall,
    rightAbsolute,
    svgIconBooks,
    bookIcon,
    playButton,
    bookCardSvg,
    governmentApprovedLogo,
    flip,
    favouriteIcon,
    shareIcon,
    replyArrow,
    labelText,
    infoGrey,
    errorPage,
    pushCenter,
    dataGTM,
}) => {
    const classNames = [baseClassName];
    const isRightToLeftDirection =
        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur';

    // Conditional className additions
    if (size && size !== 'm') {
        classNames.push(`${baseClassName}--${size}`);
    }

    classNames.push(`${baseClassName}--type-${name}`);

    if (parentClassName) {
        classNames.push(parentClassName);
    }

    if (name === 'close') {
        classNames.push(`${baseClassName}--type-close`);
    }

    if (variant) {
        classNames.push(`${baseClassName}--${variant}`);
    }

    if (pushLeft) {
        classNames.push(`${baseClassName}--push-left`);
    }

    if (pushRight) {
        classNames.push(`${baseClassName}--push-right`);
    }

    if (pushLeftMedium) {
        classNames.push(`${baseClassName}--push-left-medium`);
    }

    if (svgIcon) {
        classNames.push(
            isRightToLeftDirection
                ? `${baseClassName}--svg-icon-rtl`
                : `${baseClassName}--svg-icon`
        );
    }

    if (pushIcon) {
        classNames.push(`${baseClassName}--push-icon`);
    }

    if (pushLeftSmall) {
        classNames.push(`${baseClassName}--push-left-small`);
    }

    if (rightAbsolute) {
        classNames.push(`${baseClassName}--right-absolute`);
    }

    if (svgIconBooks) {
        classNames.push(
            isRightToLeftDirection
                ? `${baseClassName}--svg-icon-books-rtl`
                : `${baseClassName}--svg-icon-books`
        );
    }

    if (bookIcon) {
        classNames.push(`${baseClassName}--book-icon`);
    }

    if (playButton) {
        classNames.push(`${baseClassName}--play-button`);
    }

    if (bookCardSvg) {
        classNames.push(`${baseClassName}--book-card-svg`);
    }

    if (governmentApprovedLogo) {
        classNames.push(`${baseClassName}--government-approved-title`);
    }

    if (flip) {
        classNames.push(`${baseClassName}--flip-image`);
    }

    if (favouriteIcon) {
        classNames.push(
            isRightToLeftDirection
                ? `${baseClassName}--favourite-icon-rtl`
                : `${baseClassName}--favourite-icon`
        );
    }

    if (shareIcon) {
        classNames.push(
            isRightToLeftDirection
                ? `${baseClassName}--share-icon-rtl`
                : `${baseClassName}--share-icon`
        );
    }

    if (replyArrow) {
        classNames.push(
            isRightToLeftDirection
                ? `${baseClassName}--reply-arrow-rtl`
                : `${baseClassName}--reply-arrow`
        );
    }

    if (labelText) {
        classNames.push(`${baseClassName}--label-text`);
    }

    if (infoGrey) {
        classNames.push(`${baseClassName}--info-grey`);
    }

    if (errorPage) {
        classNames.push(`${baseClassName}--error-page`);
    }

    if (pushCenter) {
        classNames.push(`${baseClassName}--push-center`);
    }

    return (
        <SvgSymbol
            name={name}
            parentClassName={classNames.join(' ')}
            dataGTM={dataGTM}
        />
    );
};

SvgIcon.propTypes = {
    name: PropTypes.string,
    parentClassName: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'accent']),
    pushRight: PropTypes.bool,
    pushLeft: PropTypes.bool,
    size: PropTypes.oneOf([
        's',
        'm',
        'sm',
        'l',
        'xl',
        's-m',
        'sm',
        'm-l',
        'l-xl',
        'xl-xxl',
        'si',
        'mi',
        'custom',
    ]),
    svgIcon: PropTypes.bool,
    pushLeftMedium: PropTypes.bool,
    pushIcon: PropTypes.bool,
    pushLeftSmall: PropTypes.bool,
    rightAbsolute: PropTypes.bool,
    svgIconBooks: PropTypes.bool,
    bookIcon: PropTypes.bool,
    playButton: PropTypes.bool,
    bookCardSvg: PropTypes.bool,
    governmentApprovedLogo: PropTypes.bool,
    flip: PropTypes.bool,
    favouriteIcon: PropTypes.bool,
    shareIcon: PropTypes.bool,
    replyArrow: PropTypes.bool,
    labelText: PropTypes.bool,
    infoGrey: PropTypes.bool,
    errorPage: PropTypes.bool,
    pushCenter: PropTypes.bool,
    dataGTM: PropTypes.string,
};

export default SvgIcon;
