import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-polyglot';
import { videoReadCountWorkflow } from '../../redux/videoActions';
import CardShelf from '../CardShelf';
import VideoCard from '../VideoCard';

const renderVideoEls = (
    t,
    videos,
    sectionClicked,
    offline,
    isSimilarReadAloud,
    isSimilarVideo,
    myLanguage,
    isLoggedIn
) => {
    return videos.map((videoItem) => (
        <VideoCard
            isLoggedIn={isLoggedIn}
            myLanguage={myLanguage}
            key={videoItem.id}
            videoID={videoItem.id}
            videoSlug={videoItem.slug}
            videoTitle={videoItem.name}
            videoThumbnail={videoItem.thumbUrl}
            videoUrl={videoItem.materialUrl}
            videoDownloadUrl={videoItem.downloadUrl}
            videoLikes={videoItem.likesCount}
            videoReads={videoItem.readsCount}
            videoTime={videoItem.duration}
            isFavourite={videoItem.is_favourite}
            readCount={videoReadCountWorkflow}
            hideMenu
            videoThumbnailGTM="related-video-thumbnail"
            videoTitleGTM="related-video-title"
            sourceData={isSimilarReadAloud ? 'isSimilarReadAloud' : 'isSimilarVideo'}
            isSimilarVideo={isSimilarVideo}
            isSimilarReadAloud={isSimilarReadAloud}
            youtubeIdentifier={videoItem.youtube_video_identifier}
        />
    ));
};

const VideoShelf = ({
    videos,
    viewport,
    t,
    sectionClicked,
    offline,
    isSimilarReadAloud,
    isSimilarVideo,
    myLanguage,
    isLoggedIn,
}) => {
    const baseClassName = 'pb-book-shelf';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            <CardShelf cellWidth="book-card" viewport={viewport}>
                {renderVideoEls(
                    t,
                    videos,
                    sectionClicked,
                    offline,
                    isSimilarReadAloud,
                    isSimilarVideo,
                    myLanguage,
                    isLoggedIn
                )}
            </CardShelf>
        </div>
    );
}

VideoShelf.propTypes = {
    viewport: PropTypes.object.isRequired,
    videos: PropTypes.arrayOf(PropTypes.shape(VideoCard.propTypes)).isRequired,
    t: PropTypes.func.isRequired,
    sectionClicked: PropTypes.func,
    offline: PropTypes.bool,
    isSimilarReadAloud: PropTypes.bool,
    isSimilarVideo: PropTypes.bool,
    myLanguage: PropTypes.string,
    isLoggedIn: PropTypes.bool,
};

VideoShelf.defaultProps = {};

export default translate()(VideoShelf);
