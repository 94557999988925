import queryString from 'query-string';
import { links } from '../../lib/constants';
import { getAppliedFilters } from './utils';

// Fetch Videos and Update Navigation
export const handleFetchVideos = (location, navigate, fetchVideosWorkflow, fetchAllVideosFiltersWorkflow) => {
    const { language } = queryString.parse(
        location.search
    );
    fetchAllVideosFiltersWorkflow().then(response => {
        let filters = getAppliedFilters(location);
        filters.role = [];
        filters.role = filters.role.concat(response.data.current_role);
        filters.language = language || response.data.current_language;

        navigate({ search: `?${queryString.stringify(filters)}` }, { replace: true });

        fetchVideosWorkflow(filters);
    })
}

// Fetch Me and Videos
export const handleFetchMeAndVideos = async (fetchMeWorkflow, location, navigate, fetchVideosWorkflow, fetchAllVideosFiltersWorkflow, history) => {
    const response = await fetchMeWorkflow();
    if (response.data.isLoggedIn) {
        handleFetchVideos(location, navigate, fetchVideosWorkflow, fetchAllVideosFiltersWorkflow);
    } else {
        history.push(links.fetchTabLogin('videos'));
    }
}

// Handle Search
export const onSearch = (query, setIsSearchModalOpen, location, navigate, fetchVideosWorkflow) => {
    setIsSearchModalOpen(false);

    const filters = { query, sort: 'Relevance' };

    navigate({ search: `?${queryString.stringify(filters)}` }, { replace: true });
    fetchVideosWorkflow(filters);
};