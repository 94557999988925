import React from 'react';
import PropTypes from 'prop-types';
import './RTRBookReaderContent.scss';
import RTRBookFavourites from '../../../RTRBookFavourites';
import SvgIcon from '../../../SvgIcon';
import { isRightToLeftDirection } from '../../../../util/utils';

const RTRBookTitleData = ({
    t,
    bookData,
    onOpenModal,
    onOpenBioModal,
    book,
    online,
    onFavoriteClicked,
    isLoggedIn,
    likesCount,
}) => {
    return (
        <div className="bookReaderPageWrapper" style={{ height: '80vh' }}>
            <div className="leftBookPage firstPage">
                <div
                    className="bookCoverPageContainer"
                    style={{ margin: '0px' }}
                >
                    <div>
                        <h1 className="bookTitle">{bookData.title}</h1>
                    </div>
                    <div
                        className="bookReadingLevel"
                        style={{
                            background: bookData.story_level_colour,
                            width: '6rem',
                            margin: '1rem auto',
                        }}
                    >
                        <span>{bookData.reading_level}</span>
                    </div>
                    <div>
                        <p className="bookSummary">
                            {bookData.synopsis.length > 300
                                ? `${bookData.synopsis.slice(0, 300)}  `
                                : bookData.synopsis}
                            {bookData.synopsis.length > 300 ? (
                                <div
                                    onClick={onOpenModal}
                                    className="bookReadMore"
                                    data-toggle="modal"
                                    data-target="#exampleModalLong"
                                >
                                    {t('Book.contentReadMore')}
                                </div>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>
                    <div>
                        <p className="bookAuthors">
                            <span>{t('global.author')} </span>
                            &nbsp;
                            {bookData.authors.map((author, i) => {
                                return (
                                    <span
                                        className="bookAuthorsLink"
                                        onClick={(e) =>
                                            onOpenBioModal(e, author)
                                        }
                                    >
                                        {`${author.first_name} ${author.last_name}`}
                                        <span>
                                            {i < bookData.authors.length - 1
                                                ? ', '
                                                : ''}
                                        </span>
                                    </span>
                                );
                            })}
                        </p>
                        <p className="bookIllustrators">
                            <span>{t('global.illustrator', 1)} </span>
                            &nbsp;
                            {bookData.illustrators.map((illustrator, i) => {
                                return (
                                    <span
                                        className="bookIllustratorsLink"
                                        onClick={(e) =>
                                            onOpenBioModal(e, illustrator)
                                        }
                                    >
                                        {`${illustrator.first_name} ${illustrator.last_name}`}
                                        <span>
                                            {i <
                                            bookData.illustrators.length - 1
                                                ? ', '
                                                : ''}
                                        </span>
                                    </span>
                                );
                            })}
                        </p>
                        {bookData.translators.length > 0 && (
                            <p className="bookTranslators">
                                <span>{t('global.translator')} </span>
                                &nbsp;
                                {bookData.translators.map((translator, i) => {
                                    return (
                                        <span
                                            className="bookTranslatorsLink"
                                            onClick={(e) =>
                                                onOpenBioModal(e, translator)
                                            }
                                        >
                                            {`${translator.first_name} ${translator.last_name}`}
                                            <span>
                                                {i <
                                                bookData.translators.length - 1
                                                    ? ', '
                                                    : ''}
                                            </span>
                                        </span>
                                    );
                                })}
                            </p>
                        )}
                        <p className="bookPublisherLogo">
                            <img
                                src={bookData.publishers.image}
                                alt={bookData.publishers.name}
                                style={{ width: 'auto' }}
                            />
                        </p>
                        <div
                            className={`RTRlikesContainer ${
                                isRightToLeftDirection
                                    ? 'RTRlikesContainer-rtl'
                                    : ''
                            }`}
                        >
                            <span
                                className={`bookLikes ${
                                    isRightToLeftDirection
                                        ? 'bookLikes-rtl'
                                        : ''
                                }`}
                            >
                                {book && book.likesCount >= 0 && online && (
                                    <div
                                        className=" RTRFavourites"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                        }}
                                    >
                                        <RTRBookFavourites
                                            t={t}
                                            isFavourite={book.isFavourite}
                                            favouriteToggleHandler={() => {
                                                onFavoriteClicked(book.id);
                                            }}
                                            isFavouriting={book.isFavouriting}
                                            isLoggedIn={isLoggedIn}
                                            logInMsg={t(
                                                'global.please-log-in',
                                                {
                                                    action: t('global.like', 1),
                                                    content_type: t(
                                                        'global.story',
                                                        1
                                                    ),
                                                }
                                            )}
                                            likes={likesCount}
                                            bookSlug={book.slug}
                                            bookFavorite="book-favorite"
                                        />
                                    </div>
                                )}
                            </span>
                            <span
                                className="bookReadCount"
                                style={{
                                    width: '20px',
                                    height: '15px',
                                }}
                            >
                                <SvgIcon
                                    name={'Read'}
                                    size="custom-reader-view"
                                />
                                <span>{bookData['read_count']}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

RTRBookTitleData.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        story_level_colour: PropTypes.string.isRequired,
        reading_level: PropTypes.string.isRequired,
        synopsis: PropTypes.string.isRequired,
        authors: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
                bio: PropTypes.string,
                email: PropTypes.string,
            })
        ).isRequired,
        illustrators: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
                bio: PropTypes.string,
                email: PropTypes.string,
            })
        ).isRequired,
        translators: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
                bio: PropTypes.string,
                email: PropTypes.string,
            })
        ),
        publishers: PropTypes.shape({
            image: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        read_count: PropTypes.number.isRequired,
    }).isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onOpenBioModal: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isFavourite: PropTypes.bool.isRequired,
        isFavouriting: PropTypes.bool.isRequired,
        likesCount: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
};

export default RTRBookTitleData;
