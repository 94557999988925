import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';

const ErrorModal = ({ t, errorMessage, setShowModal }) => {
    return (
        <Modal>
            <div className="error-modal-div">
                <span className="error-message">{errorMessage}</span>
                <span
                    className="__done-button close-button"
                    onClick={() => {
                        setShowModal(false);
                    }}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                    }}
                >
                    {t('global.close')}
                </span>
            </div>
        </Modal>
    );
};

ErrorModal.propTypes = {
    t: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    setShowModal: PropTypes.func.isRequired,
};
export default ErrorModal;
