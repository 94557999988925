import { replace } from 'react-router-redux';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { fetchMeWorkflow } from '../../redux/userActions';
import { fetchAllVideosFiltersWorkflow, fetchVideosWorkflow } from '../../redux/videoActions';

export const mapStateToProps = ({ user, videos, viewport, help }) => ({
    ...videos,
    // isFetchingVideos: videos.isFetchingVideos,
    isFetchingMoreVideos: videos.isFetchingMoreVideos,
    loadedPages: videos.loadedPages,
    totalVideosCount: videos.totalVideosCount,
    filters: videos.filters,
    videoHits: videos.hits,
    viewport,
    helpStatus: help.helpStatus,
    isLoggedIn: user.isLoggedIn
});

export const mapDispatchToProps = {
    fetchAllVideosFiltersWorkflow,
    fetchVideosWorkflow,
    fetchMeWorkflow,
    replace,
    applyFilter,
    resetFilters,
};
