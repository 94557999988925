import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

const HappyEmoji = ({
    setHappyEmojiClicked,
    setNeutralEmojiClicked,
    setSadEmojiClicked,
    setRatingId,
    happyEmojiClicked,
    dataId,
}) => {
    const happyEmojiHandler = () => {
        setHappyEmojiClicked(!happyEmojiClicked);
        setNeutralEmojiClicked(true);
        setSadEmojiClicked(true);
        setRatingId(happyEmojiClicked ? dataId[0].id : '');
    };
    return (
        <Link normal onClick={() => happyEmojiHandler()}>
            {happyEmojiClicked ? (
                <img
                    className="emoji_rating"
                    src={require('../../../assets/Happy_outline.svg')}
                    alt="happy_outline"
                />
            ) : (
                <img
                    className="emoji_rating"
                    src={require('../../../assets/Happy_filled.svg')}
                    alt="happy_filled"
                />
            )}
        </Link>
    );
};

HappyEmoji.propTypes = {
    setHappyEmojiClicked: PropTypes.func.isRequired,
    setNeutralEmojiClicked: PropTypes.func.isRequired,
    setSadEmojiClicked: PropTypes.func.isRequired,
    setRatingId: PropTypes.func.isRequired,
    happyEmojiClicked: PropTypes.bool.isRequired,
    dataId: PropTypes.array.isRequired,
};

export default HappyEmoji;
