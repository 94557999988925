import React from 'react';
import PropTypes from 'prop-types';
import Tags from './Tags';
import {isRightToLeftDirection} from "../../../util/utils"
import '../Book.scss';

const BookTags = ({ online, tags, t, storyReaderFinalPage, type }) => {
    const baseClassName = 'pb-book';
  
    return (
        <div className="__booktags">
            {!online || !tags || !tags.length ? null : (
                <div className={isRightToLeftDirection ? `${baseClassName}__tags-rtl` : `${baseClassName}__tags`}>
                    <div className={storyReaderFinalPage ? `${baseClassName}__ReaderSimilarTopicsTitle` : `${baseClassName}__SimilarTopicsTitle`}>
                        {t('Book.similarTopics')}
                    </div>
                    <Tags tags={tags} baseClassName={baseClassName} type={type} />
                </div>
            )}
        </div>
    );
};

BookTags.propTypes = {
    online: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    storyReaderFinalPage: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
};

export default BookTags;
