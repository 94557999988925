import React from 'react';
import PropTypes from 'prop-types';
import ThemedReadingListContainer from './ThemedReadingListContainer';
import MyReadingListContainer from './MyReadingListContainer';
import Link from '../../Link';

const ReadingListContents = ({
    themedReadingList,
    themedReadingLists,
    loadedPages,
    totalListsCount,
    isFetchingMoreReadingList,
    isFetchingReadingList,
    viewport,
    location,
    filters,
    sortOptions,
    t,
    myReadingLists,
    myReadingListsLoadedPages,
    totalMyReadingListsCount,
    isFetchingMoreMyReadingList,
}) => {
    const myLanguage = localStorage.getItem('locale');

    return (
        <React.Fragment>
            {themedReadingList ? (
                <div className="__reading-list-container-section">
                    <ThemedReadingListContainer
                        myLanguage={myLanguage}
                        themedReadingLists={themedReadingLists}
                        loadedPages={loadedPages}
                        totalListsCount={totalListsCount}
                        isFetchingMoreReadingList={isFetchingMoreReadingList}
                        isFetchingReadingList={isFetchingReadingList}
                        viewport={viewport}
                        location={location}
                        filters={filters}
                        sortOptions={sortOptions}
                        t={t}
                    />
                </div>
            ) : (
                <div className="__my-readinglist-container-section">
                    {myReadingLists && myReadingLists.length > 0 ? (
                        <MyReadingListContainer
                            myLanguage={myLanguage}
                            myReadingLists={myReadingLists}
                            myReadingListsLoadedPages={
                                myReadingListsLoadedPages
                            }
                            totalMyReadingListsCount={totalMyReadingListsCount}
                            isFetchingMoreMyReadingList={
                                isFetchingMoreMyReadingList
                            }
                            loadedPages={loadedPages}
                            location={location}
                            filters={filters}
                            sortOptions={sortOptions}
                            t={t}
                            isMyReadingList
                        />
                    ) : (
                        <div className="__no-reading-list">
                            <span>
                                {t('Readinglist.Readinglist-not-available')},{' '}
                            </span>
                            <Link
                                href="/myreadinglists/createreadinglist"
                                theme="blue-text"
                            >
                                {t('Readinglist.create-new-reading-list')}
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

ReadingListContents.propTypes = {
    themedReadingList: PropTypes.bool.isRequired,
    themedReadingLists: PropTypes.array,
    loadedPages: PropTypes.number,
    totalListsCount: PropTypes.number,
    isFetchingMoreReadingList: PropTypes.bool,
    isFetchingReadingList: PropTypes.bool,
    viewport: PropTypes.string,
    location: PropTypes.object,
    filters: PropTypes.object,
    sortOptions: PropTypes.array,
    t: PropTypes.func.isRequired,
    myReadingLists: PropTypes.array,
    myReadingListsLoadedPages: PropTypes.number,
    totalMyReadingListsCount: PropTypes.number,
    isFetchingMoreMyReadingList: PropTypes.bool,
};

export default ReadingListContents;
