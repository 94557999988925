import React from 'react';
import PropTypes from 'prop-types';

const EditReadingListButton = ({
    listDetails,
    editSubmit,
    readingListName,
    readingListDesc,
    buttonText,
}) => {
    const editSubmitButton = () => {
        editSubmit({
            title: readingListName,
            description: readingListDesc,
        });
    };

    return (
        <div className="edit-reading-list-btn">
            {listDetails && (
                <span
                    className="__action-button-text"
                    onClick={() => editSubmitButton()}
                >
                    {buttonText}
                </span>
            )}
        </div>
    );
};

EditReadingListButton.propTypes = {
    listDetails: PropTypes.object.isRequired,
    editSubmit: PropTypes.func.isRequired,
    readingListName: PropTypes.string.isRequired,
    readingListDesc: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default EditReadingListButton;
