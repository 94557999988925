import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Link from '../../Link';
import { isRightToLeftDirection } from '../../../util/utils';

const ThemedReadingListBackLink = ({ t }) => {
    return (
        <Block offlineSection>
            <ContentStyler>
                <div
                    className={
                        isRightToLeftDirection
                            ? '__themedReadinglist-header-arabic'
                            : '__themedReadinglist-header'
                    }
                >
                    <Link normal isInternal={true} href={'/readinglists'}>
                        {t('ReadingList.backLink')}
                    </Link>
                </div>
            </ContentStyler>
        </Block>
    );
};

ThemedReadingListBackLink.propTypes = {
    t: PropTypes.func.isRequired,
};

export default ThemedReadingListBackLink;
