import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import u from 'updeep';
import Img from '../Img';
import Link from '../Link';
import NoSpaceForMoreOfflineBooksModal from '../Book/components/NoSpaceForMoreOfflineBooksModal';
import pageCountImg from '../../assets/border-image.png'
import {
    addBookToReadingListActionAsync,
    removeBookFromReadingListActionAsync,
} from '../../redux/readingListsActions';
import {
    saveOfflineWorkflow,
    unsaveOffline,
} from '../../redux/offlineBooksActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import {
    imageSrcsetSizes,
    MAX_OFFLINE_BOOKS_COUNT,
    links,
    gaEventCategories,
    gaEventActions,
} from '../../lib/constants';
import { addSlimNotification } from '../../redux/slimNotificationActions';

import './ManageBookCard.scss';

const mapStateToProps = ({
    book,
    offlineBooks,
    user: { isLoggedIn, profile },
    viewport,
}) => ({
    offlineBooks,
    isSavingOffline: offlineBooks.isSavingOffline,
    userEmail: profile.email,
    isLoggedIn,
    profile,
    viewport,
    offlineBooksCount: offlineBooks.books.length,
});

const mapDispatchToProps = {
    saveOfflineWorkflow,
    unsaveOffline,
    recordGaEvents,
    addSlimNotification,
    removeBookFromReadingListActionAsync,
    addBookToReadingListActionAsync,
};

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ManageBookCard extends Component {
    static defaultProps = {
        illustrators: [],
        authors: [],
        originalAuthors: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            isShowAddButton: props.addBtn,
            isShowRemoveButton: !props.addBtn,
            isModalVisible: {
                noSpaceForMoreOfflineBooks: false,
            },
        };
    }

    handleClick = e => {
        if (this.props.disabled || this.props.loading) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            if (this.props.onClick) {
                e.preventDefault();
                this.props.onClick(e);
            }
        }
    };

    onClickLogin = () => {
        window.location.href = links.login();
    };

    onAddToOfflineClicked = book => {
        const {
            saveOfflineWorkflow,
            recordGaEvents,
            userEmail,
            addSlimNotification,
            t,
        } = this.props;

        this.setState({ showLoader: true });

        saveOfflineWorkflow(book)
            .then(() => {
                this.setState({ showLoader: false });
                addSlimNotification({
                    type: 'info',
                    content: t(
                        'BookCard.added-to-offline-library-notification'
                    ),
                });
            })
            .then(() =>
                recordGaEvents({
                    eventCategory: gaEventCategories.offline,
                    eventAction: gaEventActions.add,
                    userEmail: userEmail,
                    dimension5: book.slug,
                    metric2: 1,
                })
            );
    };

    onOpenModal = modalName => {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: true,
                    },
                },
                this.state
            )
        );
    };

    onCloseModal(modalName) {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: false,
                    },
                },
                this.state
            )
        );
    }

    addToBookList = book => {
        this.setState({
            isShowAddButton: false,
            isShowRemoveButton: false,
        });
        this.props.addBookToReadingListActionAsync(book);
    };

    removeFromBookList = book => {
        this.setState({
            isShowAddButton: true,
            isShowRemoveButton: true,
        });
        this.props.removeBookFromReadingListActionAsync(book);
    };

    render() {
        const {
            book,
            t,
            fontSize,
            disabled,
            loading,
            offline,
            viewport,
            faded,
            hideReadCountStat,
            bookDetails,
            pagesCount,
            onClick,
            manageReadingListId,
            isManageReadingList,
            isAddBook,
            addBookImageGTM,
            asdBookTitleGTM,
            addBookAuthorGTM,
            addBookIllustratorsGTM,
            removeFromBookListGTM,
            addToBookListGTM,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const {
            level,
            slug,
            coverImage,
            authors,
            illustrators,
            recommended,
            readsCount,
            storyLevelColour
        } = book;

        const title = book.name ? book.name : book.title;

        const baseClassName = 'pb-manage-book-card';


        const bookPath = `/stories/${slug}`;

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--font-size-${fontSize}`]: fontSize,
            [`${baseClassName}--disabled`]: disabled || loading,
            [`${baseClassName}--loading`]: loading,
            [`${baseClassName}--recommended`]: recommended,
            [`${baseClassName}--faded`]: faded,
            [`${baseClassName}--has-footer`]:
                recommended || (readsCount && !hideReadCountStat),
        };
        const levelStyles = {
            [`${baseClassName}--level-${level}`]: level,
            [`${baseClassName}__manage-level`]: true,
        };
        const titleClasses = {
            [`${baseClassName}__title`]: true,
        };

        let bookImage = (
            <Link
                isInternal={onClick ? false : true}
                parentClassName={`${baseClassName}__link`}
                href={bookPath}
                onClick={onClick ? this.handleClick : null}
                dataGTM={addBookImageGTM}
            >
                <div
                    className={`${baseClassName}__image-wrapper`}
                    href={bookPath}
                    data-gtm={addBookImageGTM}
                >
                    <div
                        className={`${baseClassName}__image`}
                        data-gtm={addBookImageGTM}
                    >
                        <Img
                            image={coverImage}
                            alt={title}
                            lazyLoad
                            sizes={imageSrcsetSizes.grid2up6up}
                            offline={offline}
                            dataGTM={addBookImageGTM}
                        />
                    </div>
                </div>
            </Link>
        );

        let bookTitle = (
            <Link
                isInternal={onClick ? false : true}
                parentClassName={`${baseClassName}__link`}
                href={bookPath}
                onClick={onClick ? this.handleClick : null}
                dataGTM={asdBookTitleGTM}
            >
                <div
                    className={classNames(titleClasses)}
                    data-gtm={asdBookTitleGTM}
                >
                    <div
                        className={`${baseClassName}__book-title`}
                        data-gtm={asdBookTitleGTM}
                    >
                        {title}
                    </div>
                </div>
            </Link>
        );

        return (
            <div className={classNames(classes)}>
                <div className={`${baseClassName}__container`}>
                    {!bookDetails ? (
                        <div className={`${baseClassName}__wrapper`}>
                            {bookImage}
                            <div className={`${baseClassName}__meta-wrapper`}>
                                <div className={`${baseClassName}__meta`}>
                                    <div className={classNames(titleClasses)}>
                                        <div
                                            className={`${baseClassName}__book-title`}
                                        >
                                            {bookTitle}
                                        </div>
                                    </div>
                                    <div
                                        className={`${baseClassName}__book-author`}
                                    >
                                        {!isRightToLeftDirection && <span
                                            className={`${baseClassName}__author-by`}
                                        >
                                            {t('global.author') + " "}

                                        </span>}
                                        {authors &&
                                            authors.map((item, i) => (
                                                <span
                                                    key={i}
                                                    className={`${baseClassName}__author-name`}
                                                >
                                                    <Link
                                                        href={
                                                            '/users/' +
                                                            item.slug
                                                        }
                                                        isInternal
                                                        dataGTM={
                                                            addBookAuthorGTM
                                                        }
                                                    >
                                                        {item.name}
                                                        <span
                                                            data-gtm={
                                                                addBookAuthorGTM
                                                            }
                                                        >
                                                            {i <
                                                                authors.length - 1
                                                                ? ', '
                                                                : ''}
                                                        </span>

                                                    </Link>
                                                </span>
                                            ))}
                                        {isRightToLeftDirection && <span
                                            className={`${baseClassName}__author-by`}
                                        >
                                            {' ' + t('global.author')}
                                        </span>}

                                    </div>
                                    <div
                                        className={`${baseClassName}__book-illustrated`}
                                    >
                                        <span
                                            className={`${baseClassName}__illustrated-by`}
                                        >
                                            {t('global.illustrator', 1)}
                                        </span>{' '}
                                        {illustrators &&
                                            illustrators.map((item, i) => (
                                                <span
                                                    key={i}
                                                    className={`${baseClassName}__illustrated-name`}
                                                >
                                                    <Link
                                                        href={
                                                            '/users/' +
                                                            item.slug
                                                        }
                                                        isInternal
                                                        dataGTM={
                                                            addBookIllustratorsGTM
                                                        }
                                                    >
                                                        {item.name}
                                                        <span
                                                            data-gtm={
                                                                addBookIllustratorsGTM
                                                            }
                                                        >
                                                            {i <
                                                                illustrators.length -
                                                                1
                                                                ? ', '
                                                                : ''}
                                                        </span>
                                                    </Link>
                                                </span>
                                            ))}
                                    </div>
                                </div>
                                {isManageReadingList ? (
                                    <div
                                        className={`${baseClassName}__add-remove-button-section`}
                                    >
                                        <Link
                                            isInternal
                                            onClick={() =>
                                                this.removeFromBookList(
                                                    book,
                                                    manageReadingListId
                                                )
                                            }
                                            dataGTM={removeFromBookListGTM}
                                        >
                                            <div
                                                className="__add-remove-button"
                                                data-gtm={removeFromBookListGTM}
                                            >
                                                {t('global.remove')}
                                            </div>
                                        </Link>
                                    </div>
                                ) : null}
                                {isAddBook ? (
                                    <div
                                        className={`${baseClassName}__add-remove-button-section`}
                                    >
                                        {this.state.isShowRemoveButton ? (
                                            <Link
                                                isInternal
                                                onClick={() =>
                                                    this.addToBookList(book)
                                                }
                                                dataGTM={addToBookListGTM}
                                            >
                                                <div
                                                    className="__add-remove-button"
                                                    data-gtm={addToBookListGTM}
                                                >
                                                    {t('global.add')}
                                                </div>
                                            </Link>
                                        ) : (
                                                <Link
                                                    isInternal
                                                    onClick={() =>
                                                        this.removeFromBookList(
                                                            book,
                                                            manageReadingListId
                                                        )
                                                    }
                                                    dataGTM={removeFromBookListGTM}
                                                >
                                                    <div
                                                        className="__add-remove-button"
                                                        data-gtm={
                                                            removeFromBookListGTM
                                                        }
                                                    >
                                                        {t('global.remove')}
                                                    </div>
                                                </Link>
                                            )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                            <div>
                                <div
                                    className={`${baseClassName}__wrapper-details`}
                                >
                                    <div
                                        className={`${baseClassName}__image-wrapper`}
                                        href={bookPath}
                                    >
                                        <div className={`${baseClassName}__image`}>
                                            <Img
                                                bookDetails
                                                image={coverImage}
                                                alt={title}
                                                lazyLoad
                                                sizes={imageSrcsetSizes.grid2up6up}
                                                offline={offline}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${baseClassName}__border-image-wrapper`}
                                >
                                    <img
                                        className={`${baseClassName}__border-image`}
                                        src={pageCountImg}
                                        alt=""
                                    />
                                </div>
                                <div className={`${baseClassName}__page-number`}>
                                    {pagesCount}
                                    <p>{t('Book.page')}</p>
                                </div>
                            </div>
                        )}


                    {this.state.isModalVisible.noSpaceForMoreOfflineBooks ? (
                        <NoSpaceForMoreOfflineBooksModal
                            viewport={viewport}
                            onClose={() =>
                                this.onCloseModal('noSpaceForMoreOfflineBooks')
                            }
                            maximum={MAX_OFFLINE_BOOKS_COUNT}
                        />
                    ) : null}
                    {!bookDetails ? (
                        <div style={{backgroundColor:`${storyLevelColour}`}} className={classNames(levelStyles)}>
                            <span className={`${baseClassName}__level-text`}>
                                {t(`PublicationForm.level${level}`)}
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

ManageBookCard.propTypes = {
    title: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    coverImage: PropTypes.object.isRequired,
    recommended: PropTypes.bool,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    fontSize: PropTypes.oneOf(['m', 'l']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    shouldDisplayMenu: PropTypes.bool,
    offline: PropTypes.bool,
    offlineBooksCount: PropTypes.number,
};

export default ManageBookCard;
