import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import { isRightToLeftDirection } from '../../../util/utils';
import LoaderBlock from '../../LoaderBlock';
import Pagination from '../../Pagination';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Grid from '../../Grid';
import BookCardSmall from '../../BookCardSmall';
import { fetchReadingListDetailsWorkflow } from '../../../redux/readingListsActions';
import './BooksSearchResultsContainer.scss';
import NoResults from './NoResults';

const ListSearchResultsContainer = ({
    t,
    fetchReadingListDetailsWorkflow,
    readingLists,
    isFetchingReadingList,
    isFetchingMoreReadingList,
    loadedPages,
    totalListsCount,
    hits,
    viewport,
    appliedFilters,
    searchQuery,
    slug,
}) => {
    const myLanguage = localStorage.getItem('locale');

    const onLoadMore = () => {
        fetchReadingListDetailsWorkflow(appliedFilters, slug, loadedPages + 1);
    };

    useEffect(() => {
        fetchReadingListDetailsWorkflow(appliedFilters);
    }, [fetchReadingListDetailsWorkflow, appliedFilters]);

    const listTitleEl = searchQuery ? (
        <h3>
            {myLanguage !== 'ne'
                ? `${hits} ${t(
                      'Search.results-for-count'
                  )} \u2018${searchQuery}\u2019`
                : `\u2018${searchQuery}\u2019 ${t(
                      'Search.results-for-count-1'
                  )} ${hits} ${t('Search.results-for-count-2')}`}
        </h3>
    ) : (
        <React.Fragment>
            <h3>{t('global.video', 2)}</h3>
            <h3
                className={
                    isRightToLeftDirection ? '__total-margin-left' : '__normal'
                }
            >
                ({hits})
            </h3>
        </React.Fragment>
    );

    const shouldShowLoadMore = loadedPages && loadedPages < totalListsCount;

    return (
        <Block background="transparent">
            <ContentStyler>
                <div className="__search-result-title-list">{listTitleEl}</div>
            </ContentStyler>
            <div className="book-section">
                {isFetchingReadingList || !readingLists ? (
                    <LoaderBlock />
                ) : (
                    <div>
                        <div className="__reading-book-item-container">
                            <Grid variant="4up">
                                {readingLists.map((readingListItem) => (
                                    <div key={readingListItem.id}>
                                        <BookCardSmall
                                            readingListItem={readingListItem}
                                            isFromReadingList
                                            readingListsImage="reading-lists-image"
                                            readingListsTitle="reading-lists-title"
                                            readingListsViewBooks="reading-lists-view-books"
                                        />
                                    </div>
                                ))}
                            </Grid>
                        </div>
                        {shouldShowLoadMore ? (
                            <Pagination
                                onClick={onLoadMore}
                                loading={isFetchingMoreReadingList}
                                dataGTM="thematic-reading-list-view-more"
                            />
                        ) : null}
                        {totalListsCount ? null : <NoResults />}
                    </div>
                )}
            </div>
        </Block>
    );
};

const mapDispatchToProps = {
    fetchReadingListDetailsWorkflow,
};

const mapStateToProps = ({
    readingLists,
    viewport,
    allFilters: { listsFilters },
}) => ({
    readingLists: readingLists.readingLists,
    isFetchingReadingList: readingLists.isFetchingReadingList,
    isFetchingMoreReadingList: readingLists.isFetchingMoreReadingList,
    loadedPages: readingLists.loadedPages,
    totalListsCount: readingLists.totalListsCount,
    hits: readingLists.hits,
    viewport,
    appliedFilters: listsFilters,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(ListSearchResultsContainer));
