import React from 'react';
import PropTypes from 'prop-types';
import '../Book.scss';
import './BookDetails.scss';
import ShareMenu from '../../ShareMenu';
import SvgIcon from '../../SvgIcon';
import { isRightToLeftDirection } from '../../../util/utils';
import useDeviceType from '../../../hooks/useDeviceType';

const LikeAndShareRow = ({
    t,
    favouriteStat,
    readsCount,
    title,
    description,
    coverImage,
    landingPageBook,
    favouriteStatMainBook,
}) => {
    const { isMobile } = useDeviceType();

    const baseClassName = 'pb-book';

    const reviewClickHandler = () => {
        const elmnt = document.getElementById('commentsSectionContainer');
        elmnt && elmnt.scrollIntoView();
    };

    return (
        <div className={`${baseClassName}__stats-share`}>
            {(favouriteStat || favouriteStatMainBook) && (
                <div className={`${baseClassName}__stats-share-item`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__stats ${baseClassName}__stats-arabic`
                                : ''
                        }
                    >
                        {landingPageBook && (
                            <div className={`${baseClassName}__favouriteCount`}>
                                {landingPageBook
                                    ? favouriteStatMainBook
                                    : favouriteStat}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {readsCount >= 0 && (
                <div className={`${baseClassName}__stats-share-item`}>
                    <SvgIcon name="Read" size="book-detail-read-count" />
                    <span>{readsCount}</span>
                </div>
            )}
            <div
                className={`${baseClassName}__stats-share-item`}
                style={{ cursor: 'pointer' }}
                onClick={reviewClickHandler}
            >
                <SvgIcon name="Comment" size="book-detail-comment" />
                <span>{t('Book.review')}</span>
            </div>
            <div className={`${baseClassName}__stats-share-item`}>
                <ShareMenu
                    title={title}
                    href={window.location.href}
                    desc={description}
                    image={coverImage}
                    landingPageBook={landingPageBook}
                    align={
                        isMobile
                            ? isRightToLeftDirection
                                ? 'left'
                                : 'right'
                            : null
                    }
                    isRightToLeftDirection={isRightToLeftDirection}
                />
            </div>
        </div>
    );
};

LikeAndShareRow.propTypes = {
    t: PropTypes.func.isRequired,
    favouriteStat: PropTypes.number,
    readsCount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    coverImage: PropTypes.string,
    landingPageBook: PropTypes.bool,
    favouriteStatMainBook: PropTypes.number,
};

LikeAndShareRow.defaultProps = {
    favouriteStat: null,
    coverImage: '',
    landingPageBook: false,
    favouriteStatMainBook: null,
};

export default LikeAndShareRow;
