import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import BooksSearchResultsContainer from './components/BooksSearchResultsContainer';
import Block from '../Block';
import './AllBooks.scss';
import Search from './components/Search';

const translateFiltersBarSortOptionsToFiltersPanelSortOptions = (
    filtersBarSortOptions
) =>
    filtersBarSortOptions.map((so) => ({
        label: so.name,
        value: so.queryValue,
    }));

const AllBooks = ({
    t,
    isSearchVariant,
    searchQuery,
    location,
    tags,
    headerTitle,
    applyFilter,
    removeFilter,
    onSortOptionChanged,
    searchPage,
    recommendedBooks,
    trendingBooks,
    appliedFilters,
    onTabChange,
    books,
}) => {
    const baseClassName = 'pb-all-books';
    const isTagSearch = tags && tags.length > 0 && !searchQuery;
    let title;

    if (isSearchVariant && searchQuery && (!tags || tags.length === 0)) {
        title = `"${searchQuery}"`;
    } else if (isSearchVariant && isTagSearch) {
        title = `${t('Item.tagged', { item: t('Stories') })} "${tags}"`;
    } else {
        title = headerTitle ? headerTitle : t('global.all-stories');
    }

    const classes = { [baseClassName]: true };

    return (
        <div className={classNames(classes)}>
            <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
            {isSearchVariant ? (
                <Search
                    onTabChange={onTabChange}
                    appliedFilters={appliedFilters}
                    searchQuery={searchQuery}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    onSortOptionChanged={onSortOptionChanged}
                    translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                        translateFiltersBarSortOptionsToFiltersPanelSortOptions
                    }
                    location={location}
                    searchPage
                    tags={tags}
                    recommendedBooks={recommendedBooks}
                    trendingBooks={trendingBooks}
                />
            ) : (
                <Block noMargin>
                    <BooksSearchResultsContainer
                        bookResults
                        location={location}
                        searchQuery={searchQuery}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                        onSortOptionChanged={onSortOptionChanged}
                        translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                            translateFiltersBarSortOptionsToFiltersPanelSortOptions
                        }
                        recommendedBooks={recommendedBooks}
                        trendingBooks={trendingBooks}
                    />
                </Block>
            )}
        </div>
    );
};

AllBooks.propTypes = {
    t: PropTypes.func.isRequired,
    isSearchVariant: PropTypes.bool,
    searchQuery: PropTypes.string,
    location: PropTypes.object,
    tags: PropTypes.arrayOf(PropTypes.string),
    headerTitle: PropTypes.string,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    onSortOptionChanged: PropTypes.func.isRequired,
    searchPage: PropTypes.bool,
    recommendedBooks: PropTypes.arrayOf(PropTypes.object),
    trendingBooks: PropTypes.arrayOf(PropTypes.object),
    appliedFilters: PropTypes.object,
    onTabChange: PropTypes.func.isRequired,
};

export default translate()(AllBooks);
