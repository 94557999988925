import React from 'react';
import PropTypes from 'prop-types';
import RTRBookFavourites from '../../../RTRBookFavourites';
import ShareMenu from '../../../ShareMenu';
import { isRightToLeftDirection } from '../../../../util/utils';

const RTRMobileClosingPage = ({
    t,
    bookData,
    totalPageCount,
    setBookReaderStatusWorkflow,
    book,
    readAgain,
    online,
    onFavoriteClicked,
    isLoggedIn,
    likesCount,
}) => {
    return (
        <div className="bookPage-Mobile" id="leftBookPage">
            <div className="bookCoverPageContainer">
                <h1 className="bookTitle">{bookData.title}</h1>
                <button
                    className="bookFinishReading"
                    onClick={() => {
                        let pageStatus;
                        if ((totalPageCount + 1) % 2 === 0) {
                            pageStatus = totalPageCount + 1;
                        } else {
                            pageStatus = totalPageCount;
                        }
                        setBookReaderStatusWorkflow(
                            book.id,
                            pageStatus,
                            'finish'
                        );
                        window.history.back();
                    }}
                >
                    <span>{t('Book.contentFinishReading')}</span>
                </button>
                <p className="bookReadAgain">
                    <button
                        onClick={() => {
                            setBookReaderStatusWorkflow(book.id, 0, 'reset');
                            readAgain();
                        }}
                    >
                        {t('Book.contentReadAgain')}
                    </button>
                </p>
                <div
                    className={`bookShareAndLikes ${
                        isRightToLeftDirection ? 'bookShareAndLikes-rtl' : ''
                    }`}
                >
                    <span
                        className={`bookLikes ${
                            isRightToLeftDirection ? 'bookLikes-rtl' : ''
                        }`}
                    >
                        {book && book.likesCount >= 0 && online && (
                            <div className=" RTRFavourites">
                                <RTRBookFavourites
                                    t={t}
                                    isFavourite={book.isFavourite}
                                    favouriteToggleHandler={() => {
                                        onFavoriteClicked(book.id);
                                    }}
                                    isFavouriting={book.isFavouriting}
                                    isLoggedIn={isLoggedIn}
                                    logInMsg={t('global.please-log-in', {
                                        action: t('global.like', 1),
                                        content_type: t('global.story', 1),
                                    })}
                                    likes={likesCount}
                                    bookSlug={book.slug}
                                    bookFavorite="book-favorite"
                                />
                            </div>
                        )}
                    </span>
                    <span className="RTRBookShare">
                        <ShareMenu
                            title={''}
                            href={window.location.href}
                            desc={''}
                            landingPageBook={''}
                            align={() => {
                                return true;
                            }}
                        />
                    </span>
                </div>
                <h2 className="bookThanksHeading">
                    {t('Book.contentThankYou')}
                </h2>
                <p className="bookDedicationText">
                    {bookData['dedication_language']}
                </p>
                {bookData.donor != null ? (
                    <div className="bookSponserImages">
                        <img
                            src={bookData.donor.logo_url}
                            alt="dedicatorImages"
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};


RTRMobileClosingPage.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        dedication_language: PropTypes.string,
        donor: PropTypes.shape({
            logo_url: PropTypes.string,
        }),
    }).isRequired,
    totalPageCount: PropTypes.number.isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isFavourite: PropTypes.bool,
        isFavouriting: PropTypes.bool,
        likesCount: PropTypes.number,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    readAgain: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
};


export default RTRMobileClosingPage;
