export const languages = {
    en: 'English',
    id: 'Bahasa Indonesia',
    my: 'Burmese',
    hi: 'Hindi',
    vi: 'Vietnamese',
    sw: 'KiSwahili',
    mr: 'Marathi',
    ne: 'Nepali',
    bn: 'Bengali',
    si: 'Sinhala',
    km: 'Khmer',
    lo: 'Lao',
    zh_TW: 'Chinese (Traditional)',
    ta: 'Tamil',
    fil: 'Filipino',
    zh_CN: 'Chinese (Simplified)',
    es: 'Spanish',
    ar: 'Arabic',
    lg: 'Luganda',
    ur: 'Urdu',
    te: 'Telugu',
    kn: 'Kannada',
    nyn: 'Runyankore-Rukiga',
    gu: 'Gujarati',
};
