import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import './DocumentHeader.scss';

const DocumentHeader = ({
    parentClassName,
    description,
    keywords,
    imageUrl,
    imgWidth,
    imgHeight,
    title,
}) => {
    const baseClassName = 'pb-document-header';

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: !!parentClassName,
    });

    const pageUrl = window.location.href;
    const prerenderHeader = `Location:${pageUrl}`;

    return (
        <div className={classes}>
            <Helmet key={pageUrl}>
                <meta name="prerender-header" content={prerenderHeader} />

                {/* Regular Meta Info */}
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <link rel="canonical" href={pageUrl} />

                {/* Facebook Meta Info */}
                <meta property="og:url" content={pageUrl} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={imageUrl} />
                <meta property="og:image:url" content={imageUrl} />
                <meta property="og:image:secure_url" content={imageUrl} />
                <meta property="og:image:width" content={imgWidth} />
                <meta property="og:image:height" content={imgHeight} />
                <meta property="og:description" content={description} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:alt" content={title} />
                <meta
                    property="fb:app_id"
                    content={process.env.REACT_APP_FACEBOOK_APP_ID}
                />

                {/* Google+ Meta Info */}
                <meta itemprop="name" content={title} />
                <meta itemprop="description" content={description} />
                <meta itemprop="image" content={imageUrl} />

                {/* Twitter Meta Info */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={imageUrl} />
            </Helmet>
        </div>
    );
};

DocumentHeader.propTypes = {
    parentClassName: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    imageUrl: PropTypes.string,
    imgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imgHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
};

export default DocumentHeader;
