import React, { useEffect, useState } from 'react';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import { fetchMeWorkflow } from '../../redux/userActions';
import {
    fetchVideosDetailWorkflow,
    videoReadCountWorkflow,
} from '../../redux/videoActions';
import withRouter from "../../util/withRouter";
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import VideoDetail from '../VideoDetail';

const mapDispatchToProps = {
    fetchVideosDetailWorkflow,
    fetchMeWorkflow
};

const mapStateToProps = ({ videos, viewport, network, user }) => ({
    ...videos,
    videoDetail: videos.videoDetail,
    videoID: videos.videoDetail.id,
    videoReadsCount: videos.videoDetail.readsCount,
    videoLikesCount: videos.videoDetail.likesCount,
    isFavourite: videos.videoDetail.is_favourite,
    videoUrl: videos.videoDetail.materialUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

const VideoDetailContainer = ({
    params,
    history,
    fetchVideosDetailWorkflow,
    fetchMeWorkflow,
    videoDetail,
    videoUrl,
    videoID,
    isFetchingVideoDetail,
    online,
    isLoggedIn,
    viewport,
    videoReadsCount,
    videoLikesCount,
    isFavourite,
    t,
}) => {
    const [videoSlug, setVideoSlug] = useState(params.slug);

    useEffect(() => {
        fetchVideosDetailWorkflow(videoSlug);
    }, [videoSlug, fetchVideosDetailWorkflow]);

    useEffect(() => {
        if (params.slug !== videoSlug) {
            setVideoSlug(params.slug);
        }
    }, [params.slug]);

    const myLanguage = localStorage.getItem('locale')

    if (isFetchingVideoDetail) {
        return <LoaderBlock />
    }

    if (!isFetchingVideoDetail && videoDetail.id === undefined) {
        return <NotFoundPage />
    }

    return (
        <div>
            <VideoDetail
                myLanguage={myLanguage}
                t={t}
                videoId={videoID}
                readsCount={videoReadsCount}
                likesCount={videoLikesCount}
                isFavourite={isFavourite}
                viewport={viewport}
                online={online}
                isLoggedIn={isLoggedIn}
                readCount={videoReadCountWorkflow}
                videoDetail={videoDetail}
                videoUrl={videoUrl}
                videosVideoCloseGTM="videos-video-close"
                videosVideoThumbnailGTM="videos-video-thumbnail"
                videosVideoWatchGTM="videos-video-watch"
                videosVideoDownloadGTM="videos-video-download"
                videosVideoFavoriteGTM="videos-video-favorite"
                videoDisqusGTM="video-disqus-comments"
            />
        </div>
    );
}

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(VideoDetailContainer)
    )
);
