import { useState, useEffect, useCallback } from 'react';

const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isMobileWidth, setIsMobileWidth] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isiPad, setIsIPad] = useState(false);
    const [isLandscapeMobile, setIsLandscapeMobile] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [isUserAgentMobile, setIsUserAgentMobile] = useState(false);

    const checkDevice = useCallback(() => {
        // User-agent-based mobile check
        const userAgentMobile =
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i) ||
            window.screen.width <= 768;

        const isMobileDevice =
            window.screen.width < 768 &&
            window.matchMedia('(orientation: portrait)').matches;
        const isiPadDevice =
            /iPad/.test(navigator.userAgent) ||
            (navigator.userAgent.includes('Macintosh') &&
                'ontouchend' in document);

        const isLandscapeMobileDevice =
            window.screen.width < 768 &&
            window.matchMedia('(orientation: landscape)').matches;

        const isMobileWidthDevice = window.screen.width <= 599;
        const isTabletDevice = window.screen.width <= 768;

        setIsMobile(isMobileDevice);
        setIsIPad(isiPadDevice);
        setIsMobileWidth(isMobileWidthDevice);
        setIsTablet(isTabletDevice);
        setIsLandscapeMobile(isLandscapeMobileDevice);
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        setIsUserAgentMobile(userAgentMobile);
    }, []);

    useEffect(() => {
        checkDevice();
        window.addEventListener('resize', checkDevice);

        return () => {
            window.removeEventListener('resize', checkDevice);
        };
    }, [checkDevice]);

    return {
        isMobile,
        isiPad,
        dimensions,
        isMobileWidth,
        isTablet,
        isLandscapeMobile,
        isUserAgentMobile,
    };
};

export default useDeviceType;
