import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isRightToLeftDirection } from '../../../util/utils';

const Input = ({
    type,
    inputEl,
    isPublication,
    baseClassName,
    id,
    autoFocus,
    inputProps,
    rows,
    placeholder,
    maxLength,
    name,
    btnName,
}) => {
    const inputClassNames = {
        [isPublication
            ? isRightToLeftDirection
                ? `${baseClassName}__input-publication-arabic`
                : `${baseClassName}__input-publication`
            : null]: true,
        [isRightToLeftDirection
            ? `${baseClassName}__input-arabic`
            : `${baseClassName}__input`]: true,
    };

    if (type === 'multiline') {
        return (
            <textarea
                ref={inputEl}
                className={classNames(inputClassNames)}
                id={id}
                autoFocus={autoFocus}
                {...inputProps}
                rows={rows}
                placeholder={placeholder || ''}
                maxLength={maxLength}
            />
        );
    } else if (type === 'file') {
        return (
            <div className={`${baseClassName}__upload_file`}>
                <input
                    placeholder={name || 'Upload only jpeg or png images'}
                    className={`${baseClassName}__file-input`}
                    disabled={true}
                    maxLength={maxLength}
                />
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__browse_file-arabic`
                            : `${baseClassName}__browse_file`
                    }
                >
                    <label
                        htmlFor={id}
                        className={`${baseClassName}__browse_file_label`}
                    >
                        {btnName}
                    </label>
                    <input
                        className={`${baseClassName}__browse`}
                        type={type}
                        id={id}
                        autoFocus={autoFocus}
                        ref={inputEl}
                        {...inputProps}
                    />
                </div>
            </div>
        );
    }
    return (
        <input
            className={classNames(inputClassNames)}
            type={type}
            id={id}
            maxLength={maxLength}
            ref={inputEl}
            autoFocus={autoFocus}
            placeholder={placeholder || ''}
            {...inputProps}
            autoComplete="off"
        />
    );
};

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'file', 'multiline', 'password', 'email']),
    inputEl: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    isPublication: PropTypes.bool,
    baseClassName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool,
    inputProps: PropTypes.object,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    btnName: PropTypes.string,
};

export default Input;
