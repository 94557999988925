import queryString from 'query-string';
import { links } from '../../lib/constants';
import { getAppliedFilters } from './utils';

// Fetch Resources and Update Navigation
export const handleFetchResources = (location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow) => {
    const { language } = queryString.parse(
        location.search
    );
    fetchAllReferencesFiltersWorkflow().then(response => {
        let filters = getAppliedFilters(location);
        filters.role = [];
        filters.role = filters.role.concat(response.data.current_role);
        filters.language = language || response.data.current_language;

        navigate({ search: `?${queryString.stringify(filters)}` }, { replace: true });

        fetchReferencesWorkflow(filters);
    })
}

// Fetch Me and Resources
export const handleFetchMeAndResources = async (fetchMeWorkflow, location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow, history) => {
    const response = await fetchMeWorkflow();
    if (response.data.isLoggedIn) {
        handleFetchResources(location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow);
    } else {
        history.push(links.fetchTabLogin('resources'));
    }
}

// Handle Search
export const onSearch = (query, setIsSearchModalOpen, location, navigate, fetchReferencesWorkflow) => {
    setIsSearchModalOpen(false);

    const filters = { query, sort: 'Relevance' };

    navigate({ search: `?${queryString.stringify(filters)}` }, { replace: true });
    fetchReferencesWorkflow(filters);
};