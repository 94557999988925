import React, { useState } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Block from '../../Block';
import Tabs from '../../Tabs';
import Tab from '../../Tab';
import ContentStyler from '../../ContentStyler';
import VideoSearchResultsContainer from './VideoSearchResultsContainer';
import ReadinglistsSearchResultsContainer from './ReadinglistsSearchResultsContainer';
import ResourceSearchResultsContainer from './ResourceSearchResultsContainer';
import BooksSearchResultsContainer from './BooksSearchResultsContainer';

// This must match the visual order of tabs on screen.
const tabIndexes = {
    books: 0,
    lists: 1,
    videos: 2,
    resources: 3,
};

const Search = ({
    t,
    onTabChange,
    appliedFilters,
    searchQuery,
    applyFilter,
    removeFilter,
    onSortOptionChanged,
    translateFiltersBarSortOptionsToFiltersPanelSortOptions,
    location,
    searchPage,
    recommendedBooks,
    trendingBooks,
    tags,
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(
        tabIndexes[appliedFilters.tab]
    );
    const handleTabChange = (index) => {
        setActiveTabIndex(index);
        onTabChange(Object.keys(tabIndexes)[index], appliedFilters);
    };
    return (
        <React.Fragment>
            <ContentStyler>
                <div className="__search-header">
                    <h3>{tags}</h3>
                </div>
            </ContentStyler>

            <Block noHorizontalPadding noVerticalPadding>
                <Tabs
                    onTabChange={handleTabChange}
                    activeTabIndex={activeTabIndex}
                >
                    <Tab
                        key="books"
                        title={t('global.book', 2)}
                        shouldUnloadContent={true}
                    >
                        <BooksSearchResultsContainer
                            location={location}
                            searchQuery={searchQuery}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            onSortOptionChanged={onSortOptionChanged}
                            translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                translateFiltersBarSortOptionsToFiltersPanelSortOptions
                            }
                            searchPage={searchPage}
                            recommendedBooks={recommendedBooks}
                            trendingBooks={trendingBooks}
                        />
                    </Tab>
                    <Tab
                        key="lists"
                        title={t('global.list', 2)}
                        shouldUnloadContent={true}
                    >
                        <ReadinglistsSearchResultsContainer
                            searchQuery={searchQuery}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            onSortOptionChanged={onSortOptionChanged}
                            translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                translateFiltersBarSortOptionsToFiltersPanelSortOptions
                            }
                        />
                    </Tab>
                    <Tab
                        key="videos"
                        title={t('global.video', 2)}
                        shouldUnloadContent={true}
                    >
                        <VideoSearchResultsContainer
                            searchQuery={searchQuery}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            onSortOptionChanged={onSortOptionChanged}
                            translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                translateFiltersBarSortOptionsToFiltersPanelSortOptions
                            }
                        />
                    </Tab>
                    <Tab
                        key="resources"
                        title={t('global.resources')}
                        shouldUnloadContent={true}
                    >
                        <ResourceSearchResultsContainer
                            searchQuery={searchQuery}
                            applyFilter={applyFilter}
                            removeFilter={removeFilter}
                            onSortOptionChanged={onSortOptionChanged}
                            translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                translateFiltersBarSortOptionsToFiltersPanelSortOptions
                            }
                        />
                    </Tab>
                </Tabs>
            </Block>
        </React.Fragment>
    );
};

Search.propTypes = {
    t: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    appliedFilters: PropTypes.shape({
        tab: PropTypes.oneOf(['books', 'lists', 'videos', 'resources'])
            .isRequired,
    }).isRequired,
    searchQuery: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    onSortOptionChanged: PropTypes.func.isRequired,
    translateFiltersBarSortOptionsToFiltersPanelSortOptions:
        PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    searchPage: PropTypes.string,
    recommendedBooks: PropTypes.arrayOf(PropTypes.object),
    trendingBooks: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.string,
};

export default translate()(Search);
