import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import './Tabs.scss';

const Tabs = ({
    children,
    align = 'center',
    fitHeight,
    activeTabIndex = 0,
    onTabChange,
}) => {
    // Find the first tab with content or default to activeTabIndex
    const firstNonEmptyTab = React.Children.toArray(children).findIndex(
        (child) => child.props.hasContent
    );
    const initialTab =
        firstNonEmptyTab !== -1 ? firstNonEmptyTab : activeTabIndex;

    const [currentTab, setCurrentTab] = useState(initialTab);

    useEffect(() => {
        setCurrentTab(initialTab);
    }, [initialTab]);

    const goToTab = (index) => {
        if (onTabChange) {
            onTabChange(index);
        }
        setCurrentTab(index);
    };

    const baseClassName = 'pb-tabs';

    const childrenArray = React.Children.toArray(children).map((child, index) =>
        index === currentTab
            ? React.cloneElement(child, { active: true })
            : child
    );

    const titles = childrenArray.map((child, index) => {
        const linkClassNames = [`${baseClassName}__tab-link`];
        if (index === currentTab) {
            linkClassNames.push(`${baseClassName}__tab-link--active`);
        }

        return (
            <Link
                parentClassName={linkClassNames.join(' ')}
                key={index}
                onClick={() => goToTab(index)}
            >
                {child.props.title}
            </Link>
        );
    });

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--align-${align}`]: align,
        [`${baseClassName}--fit-height`]: fitHeight,
    };

    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}__tabs`}>{titles}</div>
            {childrenArray}
        </div>
    );
};

Tabs.propTypes = {
    children: PropTypes.node.isRequired,
    align: PropTypes.oneOf(['left', 'center']),
    fitHeight: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    onTabChange: PropTypes.func,
};

export default Tabs;
