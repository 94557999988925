import React from 'react';
import PropTypes from 'prop-types';
import RTRBookFavourites from '../../../RTRBookFavourites';
import SvgIcon from '../../../SvgIcon';
import BookSummaryModal from '../BookSummaryModal';
import BioModal from '../BookSummaryModal';

const RTRMobileFirstPage = ({
    t,
    bookData,
    onOpenModal,
    isModalVisible,
    onOpenBioModal,
    isBioModalInfo,
    onCloseBioModal,
    book,
    online,
    onFavoriteClicked,
    isLoggedIn,
    likesCount,
}) => {
    return (
        <div className="bookPage-Mobile" id="leftBookPage">
            <div className="bookCoverPageContainer">
                <h1 className="bookTitle">{bookData.title}</h1>
                <button
                    className="bookReadingLevel"
                    style={{
                        background: bookData.story_level_colour,
                        pointerEvents: 'none',
                    }}
                >
                    <span>{bookData.reading_level}</span>
                </button>
                <p className="bookSummary">
                    {bookData.synopsis.length > 300
                        ? `${bookData.synopsis.slice(0, 300)}  `
                        : bookData.synopsis}
                    {bookData.synopsis.length > 300 ? (
                        <button
                            onClick={() => {
                                onOpenModal();
                            }}
                            className="bookReadMore"
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                        >
                            {t('Book.contentReadMore')}
                        </button>
                    ) : (
                        ''
                    )}
                    {isModalVisible.noSpaceForMoreOfflineBooks && (
                        <BookSummaryModal
                            onCloseModal={onOpenModal}
                            bookData={bookData}
                        />
                    )}
                </p>
                <div>
                    <p className="bookAuthors">
                        <span>{t('Book.contentAuthor')}</span>
                        &nbsp;
                        {bookData.authors.map((author, i) => {
                            return (
                                <span
                                    className="bookAuthorsLink"
                                    onClick={(e) => {
                                        onOpenBioModal(e, author);
                                    }}
                                >
                                    {`${author.first_name} ${author.last_name}`}
                                    <span>
                                        {i < bookData.authors.length - 1
                                            ? ', '
                                            : ''}
                                    </span>
                                </span>
                            );
                        })}
                    </p>
                    <p className="bookIllustrators">
                        <span>{t('Book.contentIllustrator')}</span>
                        &nbsp;
                        {bookData.illustrators.map((illustrator, i) => {
                            return (
                                <span
                                    className="bookIllustratorsLink"
                                    onClick={(e) => {
                                        onOpenBioModal(e, illustrator);
                                    }}
                                >
                                    {`${illustrator.first_name} ${illustrator.last_name}`}
                                    <span>
                                        {i < bookData.illustrators.length - 1
                                            ? ', '
                                            : ''}
                                    </span>
                                </span>
                            );
                        })}
                    </p>
                    {bookData.translators.length > 0 && (
                        <p className="bookTranslators">
                            <span>{t('Book.contentTranslator')}</span>
                            &nbsp;
                            {bookData.translators.map((translator, i) => {
                                return (
                                    <span
                                        className="bookTranslatorsLink"
                                        onClick={(e) => {
                                            onOpenBioModal(e, translator);
                                        }}
                                    >
                                        {`${translator.first_name} ${translator.last_name}`}
                                        <span>
                                            {i < bookData.translators.length - 1
                                                ? ', '
                                                : ''}
                                        </span>
                                    </span>
                                );
                            })}
                        </p>
                    )}
                    <p className="bookPublisherLogo">
                        <img
                            src={bookData.publishers.image}
                            alt={bookData.publishers.name}
                        />
                    </p>
                    {isBioModalInfo.isBioModalOpen && (
                        <BioModal
                            onCloseBioModal={onCloseBioModal}
                            isBioModalInfo={isBioModalInfo}
                        />
                    )}
                    <div className="RTRlikesContainer">
                        <span className="bookLikes">
                            {book && book.likesCount >= 0 && online && (
                                <div className=" RTRFavourites">
                                    <RTRBookFavourites
                                        t={t}
                                        isFavourite={book.isFavourite}
                                        favouriteToggleHandler={() => {
                                            onFavoriteClicked(book.id);
                                        }}
                                        isFavouriting={book.isFavouriting}
                                        isLoggedIn={isLoggedIn}
                                        logInMsg={t('global.please-log-in', {
                                            action: t('global.like', 1),
                                            content_type: t('global.story', 1),
                                        })}
                                        likes={likesCount}
                                        bookSlug={book.slug}
                                        bookFavorite="book-favorite"
                                    />
                                </div>
                            )}
                        </span>
                        <span className="bookReadCount">
                            <SvgIcon name={'Read'} size="custom-reader-view" />
                            <span>{bookData['read_count']}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

RTRMobileFirstPage.propTypes = {
    t: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        story_level_colour: PropTypes.string.isRequired,
        reading_level: PropTypes.string.isRequired,
        synopsis: PropTypes.string.isRequired,
        authors: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
            })
        ).isRequired,
        illustrators: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
            })
        ).isRequired,
        translators: PropTypes.arrayOf(
            PropTypes.shape({
                first_name: PropTypes.string.isRequired,
                last_name: PropTypes.string.isRequired,
            })
        ),
        publishers: PropTypes.shape({
            image: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        read_count: PropTypes.number.isRequired,
    }).isRequired,
    onOpenModal: PropTypes.func.isRequired,
    isModalVisible: PropTypes.shape({
        noSpaceForMoreOfflineBooks: PropTypes.bool,
    }).isRequired,
    onOpenBioModal: PropTypes.func.isRequired,
    isBioModalInfo: PropTypes.shape({
        isBioModalOpen: PropTypes.bool.isRequired,
    }).isRequired,
    onCloseBioModal: PropTypes.func.isRequired,
    book: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isFavourite: PropTypes.bool,
        isFavouriting: PropTypes.bool,
        likesCount: PropTypes.number,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    online: PropTypes.bool.isRequired,
    onFavoriteClicked: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likesCount: PropTypes.number.isRequired,
};

export default RTRMobileFirstPage;
