import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import LoaderBlock from '../LoaderBlock';
import './ThemedReadingListsDetailContainer.scss';
import NotFoundPage from '../NotFoundPage';
import { fetchReadingListBooksWorkflow } from '../../redux/readingListsActions';
import withRouter from '../../util/withRouter';
import ThemedReadingListBackLink from './components/ThemedReadingListBackLink';
import ThemedReadingListsHeader from './components/ThemedReadingListsHeader';
import ThemedReadingListsBody from './components/ThemedReadingListsBody';

const ThemedReadingListsDetailContainer = ({
    params,
    fetchReadingListBooksWorkflow,
    t,
    readingListsBookDetail,
    isReadingListBooks,
}) => {
    useEffect(() => {
        fetchReadingListBooksWorkflow(window.location.pathname.split('/')[2]);
    }, []);

    if (isReadingListBooks) {
        return <LoaderBlock />;
    }

    if (!isReadingListBooks && readingListsBookDetail.id === undefined) {
        return <NotFoundPage />;
    }
    return (
        <div className="__themedReadingLists-container">
            <DocumentTitle
                title={`${t('global.themed-reading-lists')} - ${t(
                    'global.site-title'
                )}`}
            />
            <ThemedReadingListBackLink t={t} />
            <div className="__themedReadingLists-section">
                <div className="__themedReadingListItem-container">
                    <div className="__themed-readinglist-section-header">
                        <ThemedReadingListsHeader
                            t={t}
                            readingListsBookDetail={readingListsBookDetail}
                        />
                        <ThemedReadingListsBody
                            t={t}
                            readingListsBookDetail={readingListsBookDetail}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ readingLists }) => {
    return {
        readingListsBookDetail: readingLists.readingListsBookDetail,
        isReadingListBooks: readingLists.isReadingListBooks,
        loadedPages: readingLists.loadedPages,
    };
};

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
};

ThemedReadingListsDetailContainer.propTypes = {
    params: PropTypes.object.isRequired,
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        count: PropTypes.number,
        description: PropTypes.string,
        organisation: PropTypes.shape({
            name: PropTypes.string,
        }),
        publisher: PropTypes.shape({
            name: PropTypes.string,
        }),
        books: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                slug: PropTypes.string,
                is_favourite: PropTypes.bool,
                isFavouriting: PropTypes.bool,
                likesCount: PropTypes.number,
            })
        ),
    }).isRequired,
    isReadingListBooks: PropTypes.bool.isRequired,
};

export default withRouter(
    translate()(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ThemedReadingListsDetailContainer)
    )
);
