import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    disableDocumentScroll,
    enableDocumentScroll,
} from '../../lib/documentScroll.js';
import './Overlay.scss';

const Overlay = ({ onClick, theme = 'dark' }) => {
    useEffect(() => {
        disableDocumentScroll();
        return () => {
            enableDocumentScroll();
        };
    }, []);

    const baseClassName = 'pb-overlay';
    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--${theme}`]: theme,
    };

    return <div className={classNames(classes)} onClick={onClick} />;
};

Overlay.propTypes = {
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['dark', 'light']),
};

export default Overlay;
