export const loggedInTabs = (t) => [
    {
        name: t('global.themed-reading-lists'),
        url: '/readinglists',
    },
    {
        name: t('global.my-reading-lists'),
        url: '/myreadinglists',
    },
];
