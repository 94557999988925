import { sortOptions } from '../../../lib/constants';

export const filtersBarSortOptions = (t) => [
    {
        name: t('sort.most-liked'),
        queryValue: sortOptions.mostLiked,
    },
    {
        name: t('sort.most-read'),
        queryValue: sortOptions.mostRead,
    },
];
