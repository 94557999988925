import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import {
    deleteReadingListWorkflow,
    fetchMyReadingListWorkflow,
    manageReadingListActionAsync,
} from '../../../../redux/readingListsActions';
import Grid from '../../../Grid';
import ReadingListCardContainer from '../../../ReadingListCardContainer';

const MyReadingList = ({
    deleteReadingListWorkflow,
    fetchMyReadingListWorkflow,
    manageReadingListActionAsync,
    parentClassName,
    myReadingLists,
    myLanguage,
}) => {
    const baseClassName = 'pb-my-reading-list';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const gridVariant = window.screen.width <= 767 ? '3up' : '4up';

    useEffect(() => {
        fetchMyReadingListWorkflow();
    }, []);

    return (
        <div className={classNames(classes)}>
            <div className="__reading-book-item-container">
                <Grid variant={gridVariant}>
                    {myReadingLists &&
                        myReadingLists.map((myReadingListsItem) => {
                            return (
                                <div key={myReadingListsItem.id}>
                                    <ReadingListCardContainer
                                        isMyReadingList
                                        myLanguage={myLanguage}
                                        key={myReadingListsItem.id}
                                        readingListItem={myReadingListsItem}
                                        readingListsTitle="my-reading-lists-title"
                                    />
                                </div>
                            );
                        })}
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = ({ viewport, readingLists }) => {
    return {
        viewport,
        myReadingLists: readingLists.myReadingLists.allReadingLists,
    };
};

const mapDispatchToProps = {
    deleteReadingListWorkflow,
    fetchMyReadingListWorkflow,
    manageReadingListActionAsync,
};

MyReadingList.propTypes = {
    deleteReadingListWorkflow: PropTypes.func.isRequired,
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
    manageReadingListActionAsync: PropTypes.func.isRequired,
    parentClassName: PropTypes.string,
    myReadingLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    myLanguage: PropTypes.string.isRequired,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(MyReadingList)
);
