import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import Loader from '../../Loader';
import Button from '../../Button';
import DummyUserImage from '../../../assets/user.svg';
import LikeIcon from '../../../assets/like.svg';
import ReportIcon from '../../../assets/flag.svg';
import { isRightToLeftDirection, getTimeAgo } from '../../../util/utils';

const ReplyComments = ({
    t,
    replyComments,
    data,
    baseClassName,
    showLikedUsers,
    likeMouseOut,
    isLoggedIn,
    likeMouseEnter,
    isLikeLoading,
    activeCommentId,
    postOrDeleteLike,
    setShowDeleteModal,
    setReportedCommentId,
    isUserAdmin,
    deleteComment,
}) => {
    return (
        <React.Fragment>
            {replyComments
                .filter((replies) => replies.parent_id === data.id)
                .map((reply, index) => (
                    <div>
                        <SvgIcon
                            replyArrow
                            name={
                                isRightToLeftDirection
                                    ? 'Reply-blue-rtl'
                                    : 'Reply-blue-ltr'
                            }
                            size="si"
                        />
                        <div
                            key={index}
                            className={`${baseClassName}__reply-container`}
                        >
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__repliedby-image-container-rtl`
                                        : `${baseClassName}__repliedby-image-container`
                                }
                            >
                                <SvgIcon
                                    name={
                                        reply.user_image
                                            ? reply.user_image
                                            : 'user-image'
                                    }
                                    size="user-image"
                                />
                            </div>
                            <div
                                className={`${baseClassName}__name-and-reply-section`}
                            >
                                <h2
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__username-reply-rtl`
                                            : `${baseClassName}__username-reply`
                                    }
                                >
                                    {reply.user_name}
                                </h2>
                                <p
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__reply-rtl`
                                            : `${baseClassName}__reply`
                                    }
                                >
                                    {reply.content}
                                </p>
                                <p
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__reply-time-rtl`
                                            : `${baseClassName}__reply-time`
                                    }
                                >
                                    {getTimeAgo(reply.created_at, t)}{' '}
                                    {t('comments.ago')}
                                </p>
                            </div>
                            {showLikedUsers === reply.id &&
                                reply.likes.length > 0 && (
                                    <div
                                        onMouseOut={() => likeMouseOut()}
                                        className={`${baseClassName}__likedUsersContainer`}
                                    >
                                        {reply.likes.map((likeedUsers) => (
                                            <div>
                                                <div
                                                    className={`${baseClassName}__likedUserImage-container`}
                                                >
                                                    <img
                                                        src={
                                                            likeedUsers.image
                                                                ? likeedUsers.image
                                                                : DummyUserImage
                                                        }
                                                        alt="user"
                                                        className={`${baseClassName}__likedUserImage`}
                                                    />
                                                </div>
                                                <span
                                                    className={`${baseClassName}__likedUserName`}
                                                >
                                                    {likeedUsers.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            {isLoggedIn ? (
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__repliedby-like-container-rtl`
                                            : `${baseClassName}__repliedby-like-container`
                                    }
                                >
                                    <div
                                        onMouseEnter={() =>
                                            likeMouseEnter(reply.id)
                                        }
                                        onMouseOut={() => likeMouseOut()}
                                        className={`${baseClassName}__likeReplyContainer`}
                                    >
                                        {isLikeLoading &&
                                        activeCommentId === reply.id ? (
                                            <Loader />
                                        ) : (
                                            <img
                                                src={LikeIcon}
                                                alt="like"
                                                className={`${baseClassName}_likeButton`}
                                                onMouseEnter={() =>
                                                    likeMouseEnter(reply.id)
                                                }
                                                onMouseOut={() =>
                                                    likeMouseOut()
                                                }
                                                onClick={() =>
                                                    postOrDeleteLike(
                                                        reply.id,
                                                        reply.likes,
                                                        reply.user_id,
                                                        'reply'
                                                    )
                                                }
                                            />
                                        )}
                                        <div
                                            onMouseEnter={() =>
                                                likeMouseEnter(reply.id)
                                            }
                                            onMouseOut={() => likeMouseOut()}
                                            onClick={() =>
                                                postOrDeleteLike(
                                                    reply.id,
                                                    reply.likes,
                                                    reply.user_id,
                                                    'reply'
                                                )
                                            }
                                        >
                                            {reply.likes.length}
                                        </div>
                                    </div>
                                    <div
                                        className={`${baseClassName}__comments-report-container`}
                                    >
                                        <img
                                            src={ReportIcon}
                                            alt="report"
                                            className={`${baseClassName}_likeButton`}
                                            onClick={() => {
                                                setShowDeleteModal(true);
                                                setReportedCommentId(reply.id);
                                            }}
                                        />
                                        <div>{t('comments.report')}</div>
                                    </div>
                                    {isUserAdmin() && reply.report_content && (
                                        <Button
                                            label={t('comments.delete')}
                                            variant="report"
                                            onClick={() =>
                                                deleteComment(reply.id)
                                            }
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ))}
        </React.Fragment>
    );
};

ReplyComments.propTypes = {
    t: PropTypes.func.isRequired,
    replyComments: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    baseClassName: PropTypes.string.isRequired,
    showLikedUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    likeMouseOut: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likeMouseEnter: PropTypes.func.isRequired,
    isLikeLoading: PropTypes.bool.isRequired,
    activeCommentId: PropTypes.string.isRequired,
    postOrDeleteLike: PropTypes.func.isRequired,
    setShowDeleteModal: PropTypes.func.isRequired,
    setReportedCommentId: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
};

export default ReplyComments;
