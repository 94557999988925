import React from 'react';
import PropTypes from 'prop-types';
import ContentStyler from '../../ContentStyler';
import TitleImage from '../../TitleImage';
import { API_URL } from '../../../api';
import { isRightToLeftDirection } from '../../../util/utils';

const AddBooksHeader = ({
    t,
    onOpenSearchModal,
    newList,
    baseClassName,
    disable,
    managingList,
    setDisable,
    nextPath,
    setShowModal,
    setErrorMessage,
}) => {
    const createReadingList = () => {
        const title = newList && newList.title;
        const description = newList && newList.description;
        const books = newList && newList.books;
        const booksSlugs = books.map((item) => {
            return item.slug;
        });
        const data = {
            title: title,
            description: description,
            books: booksSlugs,
        };
        fetch(`${API_URL}/lists`, {
            method: 'POST',
            body: JSON.stringify(data),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.meta.success) {
                    setTimeout(() => {
                        nextPath('/myreadinglists');
                    }, 3000);
                } else {
                    setErrorMessage(response.meta.errors.join(', '));
                    setShowModal(true);
                }
            })
            .catch(() => {
                console.error('Failed to add reading list');
            });
    };

    const manageReadingList = () => {
        let readingList = newList;
        let data = {
            title: readingList && readingList.title,
            description: readingList && readingList.description,
            books: readingList && readingList.books.map((item) => item.slug),
        };
        fetch(`${API_URL}/lists/${readingList.slug}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.meta.success) {
                    setTimeout(() => {
                        nextPath('/myreadinglists');
                    }, 3000);
                } else {
                    setErrorMessage(response.meta.errors.join(', '));
                    setShowModal(true);
                }
            });
    };
    return (
        <ContentStyler>
            <div className="__myReadingList-header">
                <h3>{t('Readinglist.add-books')}</h3>
                <div
                    className={`book-section--menu-search`}
                    onClick={onOpenSearchModal}
                >
                    <TitleImage
                        className={
                            isRightToLeftDirection
                                ? '__profile-images-arabic'
                                : '__profile-images'
                        }
                        svgName={require('../../../assets/search_tl.svg')}
                        title={t('books.search')}
                        contentSpecificSearch
                    />
                </div>
                {newList && newList.books.length <= 0 ? (
                    <div className={`${baseClassName}__disabled-button`}>
                        <span className="__done-button-disabled">
                            {t('ReadingList.done')}
                        </span>
                    </div>
                ) : (
                    <div className="__done-button-section">
                        {disable ? (
                            <span className="__done-button-disabled">
                                <img
                                    src="/public/loader.svg"
                                    alt="loader"
                                    className="svgLoaderRL"
                                    style={{ margin: '0px 5px' }}
                                />
                                {t('ReadingList.done')}
                            </span>
                        ) : (
                            <span
                                className="__done-button"
                                onClick={() => {
                                    if (managingList) {
                                        manageReadingList();
                                        setDisable(true);
                                    } else {
                                        createReadingList();
                                        setDisable(true);
                                    }
                                }}
                            >
                                {t('ReadingList.done')}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </ContentStyler>
    );
};

AddBooksHeader.propTypes = {
    t: PropTypes.func.isRequired,
    onOpenSearchModal: PropTypes.func.isRequired,
    newList: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        books: PropTypes.arrayOf(
            PropTypes.shape({
                slug: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    baseClassName: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    managingList: PropTypes.bool.isRequired,
    setDisable: PropTypes.func.isRequired,
    nextPath: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
};

export default AddBooksHeader;
