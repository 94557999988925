import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import { fetchMyReadingListWorkflow } from '../../../../redux/readingListsActions';
import MyReadingList from '../MyReadingList';
import Pagination from '../../../Pagination';

const MyReadingListContainer = ({
    fetchMyReadingListWorkflow,
    parentClassName,
    myReadingLists,
    isMyReadingList,
    loadedPages,
    totalMyReadingListsCount,
    isFetchingMoreMyReadingList,
    myLanguage,
    appliedFilters,
    slug,
}) => {
    const baseClassName = 'pb-my-reading-list-container';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const shouldShowLoadMore =
        loadedPages && loadedPages < totalMyReadingListsCount;

    const onLoadMore = () =>
        fetchMyReadingListWorkflow(appliedFilters, slug, loadedPages + 1);
    return (
        <div className={classNames(classes)}>
            <MyReadingList
                myLanguage={myLanguage}
                myReadingLists={myReadingLists}
                isMyReadingList={isMyReadingList}
            />
            {shouldShowLoadMore && (
                <Pagination
                    onClick={onLoadMore}
                    loading={isFetchingMoreMyReadingList}
                    dataGTM="thematic-reading-list-view-more"
                />
            )}
        </div>
    );
};

const mapStateToProps = () => {};

const mapDispatchToProps = {
    fetchMyReadingListWorkflow,
};

MyReadingListContainer.propTypes = {
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
    parentClassName: PropTypes.string,
    myReadingLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMyReadingList: PropTypes.bool,
    loadedPages: PropTypes.number,
    totalMyReadingListsCount: PropTypes.number,
    isFetchingMoreMyReadingList: PropTypes.bool,
    myLanguage: PropTypes.string.isRequired,
    appliedFilters: PropTypes.object,
    slug: PropTypes.string,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(MyReadingListContainer)
);
