import { useState, useEffect } from 'react';

const useFullscreen = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenChange = () => {
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            setIsFullscreen(true);
        } else {
            setIsFullscreen(false);
        }
    };

    useEffect(() => {
        // Add event listeners to track fullscreen state
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener(
            'webkitfullscreenchange',
            handleFullscreenChange
        );
        document.addEventListener(
            'mozfullscreenchange',
            handleFullscreenChange
        );
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Cleanup on unmount
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange
            );
            document.removeEventListener(
                'webkitfullscreenchange',
                handleFullscreenChange
            );
            document.removeEventListener(
                'mozfullscreenchange',
                handleFullscreenChange
            );
            document.removeEventListener(
                'MSFullscreenChange',
                handleFullscreenChange
            );
        };
    }, []);

    return isFullscreen;
};

export default useFullscreen;
