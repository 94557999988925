import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Level.scss';

const Level = ({
    parentClassName,
    level,
    title,
    storyLevelColour,
    isLandingBookPage,
}) => {
    const baseClassName = 'pb-level';

    const classes = classNames({
        [baseClassName]: true,
        [`${baseClassName}--book-page`]: isLandingBookPage,
        [`${baseClassName}--level-${level}`]: level,
        [parentClassName]: parentClassName,
    });

    return (
        <p style={{ backgroundColor: storyLevelColour }} className={classes}>
            <span className={`${baseClassName}__level`}>{title}</span>
        </p>
    );
};

Level.propTypes = {
    parentClassName: PropTypes.string,
    level: PropTypes.number,
    title: PropTypes.string,
    storyLevelColour: PropTypes.string,
    isLandingBookPage: PropTypes.bool,
};

export default Level;
