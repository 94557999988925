import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import BookCard from '../BookCard';
import CardShelf from '../CardShelf';
import './BookShelf.scss';

const renderBookEls = (
    t,
    books,
    sectionClicked,
    offline,
    myLanguage,
    lastPage
) => {
    return books.map((book) => (
        <BookCard
            myLanguage={myLanguage}
            key={book.slug}
            id={book.id}
            book={book}
            isSimilarBook
            sectionClicked={sectionClicked}
            offline={offline}
            isFavourite={book.is_favourite}
            isFavouriting={book.isFavouriting}
            bookLikes={book.likesCount}
            hideMenu
            bookImageGTM="related-book-image"
            bookTitleGTM="related-book-title"
            bookAuthorGTM="related-book-author"
            bookIllustratorsGTM="related-book-illustrators"
            bookLevelGTM="related-book-level"
            isRelatedBook
            lastPage={lastPage}
            padding130={true}
        />
    ));
};

const BookShelf = ({
    books,
    viewport,
    t,
    sectionClicked,
    offline,
    myLanguage,
    isBookReaderPage = false,
    lastPage,
}) => {
    const baseClassName = 'pb-book-shelf';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            <CardShelf
                cellWidth="book-card"
                viewport={viewport}
                isBookReaderPage={isBookReaderPage}
            >
                {renderBookEls(
                    t,
                    books,
                    sectionClicked,
                    offline,
                    myLanguage,
                    lastPage
                )}
            </CardShelf>
        </div>
    );
};

BookShelf.defaultProps = {
    isBookReaderPage: false,
};
BookShelf.propTypes = {
    books: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)).isRequired,
    viewport: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    sectionClicked: PropTypes.func.isRequired,
    offline: PropTypes.bool.isRequired,
    myLanguage: PropTypes.string.isRequired,
    isBookReaderPage: PropTypes.bool,
    lastPage: PropTypes.string.isRequired,
};

export default translate()(BookShelf);
