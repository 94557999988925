import React from 'react';
import { sortOptions } from '../../lib/constants';
import FiltersBar from '../FiltersBar';

const FiltersBarComponent = ({
    filters,
    applyFilter,
    removeFilter,
    appliedFilters,
    onSortOptionChanged,
    isResourceMobile,
    filtersSearchText,
    updateFiltersSearchText,
    totalReferencesCount,
    t,
}) => {
    const filtersBarSortOptions = [
        {
            name: t('sort.relevance'),
            queryValue: sortOptions.relevance,
        },
        {
            name: t('sort.new-arrivals-images'),
            queryValue: sortOptions.newArrivals,
        },
        {
            name: t('sort.most-viewed'),
            queryValue: sortOptions.mostViewed,
        },
        {
            name: t('sort.most-liked'),
            queryValue: sortOptions.mostLiked,
        },
    ];
    return (
        <div className="__video-filter-component">
            <FiltersBar
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                styleSearchValue={filtersSearchText.style}
                updateStyleSearchValue={(e) =>
                    updateFiltersSearchText('style', e.target.value)
                }
                categorySearchValue={filtersSearchText.category}
                updateCategorySearchValue={(e) =>
                    updateFiltersSearchText('category', e.target.value)
                }
                publisherSearchValue={filtersSearchText.publisher}
                updatePublisherSearchValue={(e) =>
                    updateFiltersSearchText('publisher', e.target.value)
                }
                resultsCount={totalReferencesCount}
                sortOptions={filtersBarSortOptions}
                applySortOption={onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isResources
                isResourceMobile={isResourceMobile}
                categoryFilterGTM="resource-category-filter"
                roleFilterGTM="resource-role-filter"
                languageFilterGTM="resource-language-filter"
                favoriteSortGTM="resource-favorite-sort"
                viewSortGTM="resource-view-sort"
                alphabetSortGTM="resource-alphabetic-sort"
                hideLevelSelector
            />
        </div>
    );
};

export default FiltersBarComponent;
