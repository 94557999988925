import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';
import { links } from '../../../lib/constants';

const Thumbnail = ({
    isLoggedIn,
    videoThumbnailGTM,
    isSimilarVideo,
    isSimilarReadAloud,
    similarVideoPath,
    videoPath,
    videoThumbnail,
    videoPlaceholder,
    videoTime,
}) => {
    const thumbnailEl = () => (
        <div className="__video-image" data-gtm={videoThumbnailGTM}>
            <img
                src={
                    videoThumbnail === 'original/missing_video.png'
                        ? videoPlaceholder
                        : videoThumbnail
                }
                alt="Video Thumbnail Img"
                data-gtm={videoThumbnailGTM}
            />
        </div>
    );
    return (
        <React.Fragment>
            <Link
                isInternal={isLoggedIn}
                dataGTM={videoThumbnailGTM}
                href={
                    (isSimilarVideo && isLoggedIn) || isSimilarReadAloud
                        ? similarVideoPath
                        : isLoggedIn
                        ? `/${videoPath}`
                        : links.fetchTabLogin('videos')
                }
            >
                <div data-gtm={videoThumbnailGTM}>
                    <div className="__video-play" data-gtm={videoThumbnailGTM}>
                        <SvgIcon
                            name="play"
                            size="l"
                            data-gtm={videoThumbnailGTM}
                        />
                    </div>
                    <div data-gtm={videoThumbnailGTM}>{thumbnailEl()}</div>
                </div>
            </Link>
            <div className="__video-timer">{videoTime}</div>
        </React.Fragment>
    );
};

Thumbnail.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    videoThumbnailGTM: PropTypes.string,
    isSimilarVideo: PropTypes.bool,
    isSimilarReadAloud: PropTypes.bool,
    similarVideoPath: PropTypes.string,
    videoPath: PropTypes.string.isRequired,
    videoThumbnail: PropTypes.string.isRequired,
    videoPlaceholder: PropTypes.string.isRequired,
    videoTime: PropTypes.string,
};

export default Thumbnail;
