import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import LoaderBlock from '../../LoaderBlock';
import Pagination from '../../Pagination';
import Grid from '../../Grid';
import {
    fetchVideosWorkflow,
    videoReadCountWorkflow,
} from '../../../redux/videoActions';
import { fetchMeWorkflow } from '../../../redux/userActions';
import { recordGaEvents } from '../../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../../redux/slimNotificationActions';
import ContentStyler from '../../ContentStyler';
import Block from '../../Block';
import VideoCard from '../../VideoCard';
import NoResults from './NoResults';
import './BooksSearchResultsContainer.scss';

const VideoGridEl = ({ videos, t, isLoggedIn }) => {
    const videoEls = videos.map((videoItem) => (
        <div key={videoItem.id}>
            <VideoCard
                videoID={videoItem.id}
                videoSlug={videoItem.slug}
                videoTitle={videoItem.name}
                videoThumbnail={videoItem.thumbUrl}
                videoUrl={videoItem.materialUrl}
                videoDownloadUrl={videoItem.downloadUrl}
                videoLikes={videoItem.likesCount}
                videoReads={videoItem.readsCount}
                videoTime={videoItem.duration}
                isFavourite={videoItem.is_favourite}
                readCount={videoReadCountWorkflow}
                videoTitleGTM="search-video-title"
                videoThumbnailGTM="search-video-thumbnail"
                isLoggedIn={videoItem.is_read_aloud ? true : isLoggedIn}
                youtubeIdentifier={videoItem.youtube_video_identifier}
            />
        </div>
    ));
    return <Grid variant="4up">{videoEls}</Grid>;
};

const BooksSearchResultsContainer = ({
    t,
    videos,
    isFetchingVideos,
    isFetchingMoreVideos,
    loadedPages,
    totalVideosCount,
    videoHits,
    viewport,
    appliedFilters,
    isLoggedIn,
    searchQuery,
    fetchVideosWorkflow,
}) => {
    useEffect(() => {
        fetchVideosWorkflow(appliedFilters);
    }, [appliedFilters, fetchVideosWorkflow]);

    const onLoadMore = () => {
        fetchVideosWorkflow(appliedFilters, loadedPages + 1);
    };

    const myLanguage = localStorage.getItem('locale');
    const shouldShowLoadMore = loadedPages && loadedPages < totalVideosCount;

    const resourceTitleEl = searchQuery ? (
        <h3>
            {myLanguage !== 'ne'
                ? `${videoHits} ${t(
                      'Search.results-for-count'
                  )} \u2018${searchQuery}\u2019`
                : `\u2018${searchQuery}\u2019 ${t(
                      'Search.results-for-count-1'
                  )} ${videoHits} ${t('Search.results-for-count-2')}`}
        </h3>
    ) : (
        <React.Fragment>
            <h3>{t('global.video', 2)}</h3>
            <h3 className="__normal">({videoHits})</h3>
        </React.Fragment>
    );

    return (
        <Block background="transparent">
            <ContentStyler>
                <div className="__search-result-title">{resourceTitleEl}</div>
            </ContentStyler>
            <div className="book-section">
                {isFetchingVideos || !videos ? (
                    <LoaderBlock />
                ) : (
                    <div>
                        {videos.length > 0 ? (
                            <div>
                                <VideoGridEl
                                    videos={videos}
                                    t={t}
                                    isLoggedIn={isLoggedIn}
                                />
                                {shouldShowLoadMore && (
                                    <Pagination
                                        onClick={onLoadMore}
                                        loading={isFetchingMoreVideos}
                                        dataGTM="videos-view-more"
                                    />
                                )}
                            </div>
                        ) : null}
                        {!totalVideosCount && <NoResults />}
                    </div>
                )}
            </div>
        </Block>
    );
};

const mapStateToProps = ({
    videos,
    viewport,
    allFilters: { readFilters },
    user,
}) => ({
    videos: videos.videos,
    isFetchingVideos: videos.isFetchingVideos,
    isFetchingMoreVideos: videos.isFetchingMoreVideos,
    loadedPages: videos.loadedPages,
    totalVideosCount: videos.totalVideosCount,
    videoHits: videos.hits,
    viewport,
    appliedFilters: readFilters,
    isLoggedIn: user.isLoggedIn,
});

const mapDispatchToProps = {
    fetchVideosWorkflow,
    recordGaEvents,
    addSlimNotification,
    fetchMeWorkflow,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(BooksSearchResultsContainer));
