export const helpGuideSteps = (t, isLoggedIn) => ({
    steps: [
        {
            target: isLoggedIn ? '.subMenuTabs' : '.Tabs',
            content: t('ReadingList.subMenuHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.__bookFilter',
            content: t('ReadingList.filterHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-filters-bar__actions--secondary',
            content: t('ReadingList.sortHelpGuide'),
            placementBeacon: 'left',
        },
    ],
    mobileSteps: [
        {
            target: isLoggedIn ? '.subMenuTabs' : '.Tabs',
            content: t('ReadingList.subMenuHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-filters-bar__filter-option',
            content: t('ReadingList.filterHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-filters-bar__sort-option',
            content: t('ReadingList.sortHelpGuide'),
            placementBeacon: 'left',
        },
    ],
});
