import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Sizer from '../Sizer';

import './BookDetailSummary.scss';

const BookDetailSummary = ({
    parentClassName,
    title,
    description,
    isTabletMode,
    hideSummary,
    bookDetailPage
}) => {
    const baseClassName = 'pb-book-detail-summary';

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    });

    const locale = localStorage.getItem('locale');
    const isArabic = locale === 'ar' || locale === 'ur';
    const titleClass = isArabic
        ? `${baseClassName}__title-arabic`
        : `${baseClassName}__title`;

    const maxHeight =
        (window.screen.width >= 800 && window.screen.width <= 1047) ||
        (window.innerWidth >= 1024 && window.innerWidth <= 1199)
            ? 'xl'
            : 'lm';

    return (
        <div className={classes}>
            {!(isTabletMode || hideSummary || bookDetailPage) && (
                <p className={titleClass}>{title}</p>
            )}
            <Sizer maxHeight={maxHeight} scrollY>
                {description}
            </Sizer>
        </div>
    );
};

BookDetailSummary.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    isTabletMode: PropTypes.bool,
    hideSummary: PropTypes.bool,
};

BookDetailSummary.defaultProps = {
    parentClassName: '',
    isTabletMode: false,
    hideSummary: false,
};

export default BookDetailSummary;
