export const helpGuideSteps = (t, myLanguage) => ({
    steps: [
        {
            target: '.__bookFilter',
            content: t('Book.filterHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-filters-bar__actions--secondary',
            content: t('Book.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Book.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
    ],
    mobileSteps: [
        myLanguage === 'ar' || myLanguage === 'ur'
            ? {
                  target: '.pb-filters-bar__filter-option-rtl',
                  content: t('Video.filterHelpGuide'),
                  placementBeacon: 'left',
                  disableBeacon: true,
              }
            : {
                  target: '.pb-filters-bar__filter-option',
                  content: t('Video.filterHelpGuide'),
                  placementBeacon: 'left',
                  disableBeacon: true,
              },
        {
            target: '.pb-filters-bar__sort-option',
            content: t('Book.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Book.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
    ],
});
