import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import SvgIcon from '../../SvgIcon';
import Button from '../../Button';
import PostComment from './PostComment';
import Pagination from '../../Pagination';
import DummyUserImage from '../../../assets/user.svg';
import LikeIcon from '../../../assets/like.svg';
import CommentIcon from '../../../assets/comment.svg';
import ReportIcon from '../../../assets/flag.svg';
import { getTimeAgo, isRightToLeftDirection } from '../../../util/utils';
import ReplyComments from './ReplyComments';

const MainComments = ({
    t,
    isMainCommentLoading,
    mainComments,
    baseClassName,
    showLikedUsers,
    likeMouseOut,
    isLoggedIn,
    likeMouseEnter,
    isLikeLoading,
    activeCommentId,
    postOrDeleteLike,
    isReplyCommentLoading,
    getReplyCommentsData,
    replyComments,
    isReportLoading,
    setShowDeleteModal,
    setReportedCommentId,
    setActiveCommentId,
    isUserAdmin,
    deleteComment,
    showReply,
    profileImage,
    postReply,
    isMoreReplyButtonActive,
    getMoreReplyComments,
}) => {
    return (
        <React.Fragment>
            {isMainCommentLoading ? (
                <Loader />
            ) : (
                mainComments.map((data, index) => (
                    <div
                        key={index}
                        className={`${baseClassName}__individual-comments-section`}
                    >
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__commentedby-image-container-rtl`
                                    : `${baseClassName}__commentedby-image-container`
                            }
                        >
                            <SvgIcon
                                name={
                                    data.user_image
                                        ? data.user_image
                                        : 'user-image'
                                }
                                size="user-image"
                            />
                        </div>
                        <div
                            className={`${baseClassName}__name-and-comment-section`}
                        >
                            <h2
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__username-rtl`
                                        : `${baseClassName}__username`
                                }
                            >
                                {data.user_name}
                            </h2>
                            <p
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__comment-rtl`
                                        : `${baseClassName}__comment`
                                }
                            >
                                {data.content}
                            </p>
                            <p
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__comment-time-rtl`
                                        : `${baseClassName}__comment-time`
                                }
                            >
                                {getTimeAgo(data.created_at, t)}{' '}
                                {t('comments.ago')}
                            </p>
                        </div>
                        {showLikedUsers === data.id &&
                            data.likes.length > 0 && (
                                <div
                                    onMouseOut={() => likeMouseOut()}
                                    className={`${baseClassName}__likedUsersContainer`}
                                >
                                    {data.likes.map((users) => (
                                        <div>
                                            <div
                                                className={`${baseClassName}__likedUserImage-container`}
                                            >
                                                <img
                                                    src={
                                                        users.image
                                                            ? users.image
                                                            : DummyUserImage
                                                    }
                                                    alt=""
                                                    className={`${baseClassName}__likedUserImage`}
                                                />
                                            </div>
                                            <span
                                                className={`${baseClassName}__likedUserName`}
                                            >
                                                {users.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__likeAndReplyBar-rtl`
                                    : `${baseClassName}__likeAndReplyBar`
                            }
                        >
                            {isLoggedIn ? (
                                <div
                                    onMouseEnter={() => likeMouseEnter(data.id)}
                                    onMouseOut={() => likeMouseOut()}
                                    className={`${baseClassName}__likeReplyContainer`}
                                >
                                    {isLikeLoading &&
                                    activeCommentId === data.id ? (
                                        <Loader />
                                    ) : (
                                        <img
                                            src={LikeIcon}
                                            alt="like"
                                            className={`${baseClassName}_likeButton`}
                                            onMouseEnter={() =>
                                                likeMouseEnter(data.id)
                                            }
                                            onMouseOut={() => likeMouseOut()}
                                            onClick={() =>
                                                postOrDeleteLike(
                                                    data.id,
                                                    data.likes,
                                                    data.user_id,
                                                    'mainComment'
                                                )
                                            }
                                        />
                                    )}
                                    <div
                                        onMouseEnter={() =>
                                            likeMouseEnter(data.id)
                                        }
                                        onMouseOut={() => likeMouseOut()}
                                        onClick={() =>
                                            postOrDeleteLike(
                                                data.id,
                                                data.likes,
                                                data.user_id,
                                                'mainComment'
                                            )
                                        }
                                    >
                                        {data.likes.length}
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className={`${baseClassName}__comments-reply-container`}
                            >
                                {isReplyCommentLoading &&
                                activeCommentId === data.id ? (
                                    <Loader />
                                ) : (
                                    <img
                                        src={CommentIcon}
                                        alt="comment"
                                        className={`${baseClassName}_likeButton`}
                                        onClick={() =>
                                            getReplyCommentsData(data.id)
                                        }
                                    />
                                )}
                                <div>
                                    {t('comments.reply')} (
                                    {replyComments.length > 0 &&
                                    replyComments[0].parent_id === data.id
                                        ? replyComments.length
                                        : data.reply_commments}
                                    )
                                </div>
                            </div>
                            {isReportLoading && activeCommentId === data.id ? (
                                <Loader />
                            ) : isLoggedIn ? (
                                <div
                                    className={`${baseClassName}__comments-report-container`}
                                >
                                    <img
                                        src={ReportIcon}
                                        alt="report"
                                        className={`${baseClassName}_likeButton`}
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                            setReportedCommentId(data.id);
                                            setActiveCommentId(data.id);
                                        }}
                                    />
                                    <div>{t('comments.report')}</div>
                                </div>
                            ) : null}
                            {isUserAdmin() && data.report_content && (
                                <Button
                                    label={t('comments.delete')}
                                    variant="report"
                                    onClick={() => deleteComment(data.id)}
                                />
                            )}
                        </div>
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__individual-reply-section-rtl`
                                    : `${baseClassName}__individual-reply-section`
                            }
                        >
                            {showReply === data.id &&
                                (isLoggedIn ? (
                                    <PostComment
                                        id={data.id}
                                        url={profileImage}
                                        postReply={postReply}
                                        t={t}
                                        isLoggedIn={isLoggedIn}
                                    />
                                ) : null)}
                            {isReplyCommentLoading ? (
                                <Loader />
                            ) : (
                                showReply === data.id && (
                                    <ReplyComments
                                        t={t}
                                        replyComments={replyComments}
                                        data={data}
                                        baseClassName={baseClassName}
                                        showLikedUsers={showLikedUsers}
                                        likeMouseOut={likeMouseOut}
                                        isLoggedIn={isLoggedIn}
                                        likeMouseEnter={likeMouseEnter}
                                        isLikeLoading={isLikeLoading}
                                        activeCommentId={activeCommentId}
                                        postOrDeleteLike={postOrDeleteLike}
                                        setShowDeleteModal={setShowDeleteModal}
                                        setReportedCommentId={
                                            setReportedCommentId
                                        }
                                        isUserAdmin={isUserAdmin}
                                        deleteComment={deleteComment}
                                    />
                                )
                            )}
                        </div>
                        {isMoreReplyButtonActive &&
                            replyComments.filter(
                                (replies) => replies.parent_id === data.id
                            ).length > 0 && (
                                <div>
                                    <Pagination
                                        label="Show More Replies"
                                        onClick={() =>
                                            getMoreReplyComments(data.id)
                                        }
                                    />
                                </div>
                            )}
                    </div>
                ))
            )}
        </React.Fragment>
    );
};

MainComments.propTypes = {
    t: PropTypes.func.isRequired,
    isMainCommentLoading: PropTypes.bool.isRequired,
    mainComments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            user_name: PropTypes.string.isRequired,
            user_image: PropTypes.string,
            content: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            likes: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    image: PropTypes.string,
                })
            ).isRequired,
            reply_commments: PropTypes.number.isRequired,
            report_content: PropTypes.bool,
            user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    baseClassName: PropTypes.string.isRequired,
    showLikedUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    likeMouseOut: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    likeMouseEnter: PropTypes.func.isRequired,
    isLikeLoading: PropTypes.bool.isRequired,
    activeCommentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    postOrDeleteLike: PropTypes.func.isRequired,
    isReplyCommentLoading: PropTypes.bool.isRequired,
    getReplyCommentsData: PropTypes.func.isRequired,
    replyComments: PropTypes.arrayOf(
        PropTypes.shape({
            parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        })
    ).isRequired,
    isReportLoading: PropTypes.bool.isRequired,
    setShowDeleteModal: PropTypes.func.isRequired,
    setReportedCommentId: PropTypes.func.isRequired,
    setActiveCommentId: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    showReply: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profileImage: PropTypes.string,
    postReply: PropTypes.func.isRequired,
    isMoreReplyButtonActive: PropTypes.bool.isRequired,
    getMoreReplyComments: PropTypes.func.isRequired,
};

export default MainComments;
