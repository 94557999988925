import React from 'react';
import PropTypes from 'prop-types';
import ListBox from '../../ListBox';

const InputListBox = ({ baseClassName, options, selectedIndex, onSelect }) => {
    return (
        <div className={`${baseClassName}__options`}>
            <ListBox
                options={options}
                selectedIndex={selectedIndex}
                onSelect={onSelect}
            />
        </div>
    );
};

InputListBox.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        })
    ).isRequired,
    selectedIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default InputListBox;
