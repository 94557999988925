import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './TitleImage.scss';
import { isRightToLeftDirection } from '../../util/utils';

const TitleImage = ({
    parentClassName,
    title,
    svgName,
    reverse = false,
    mobileMenu = false,
    avatar = null,
    readingList,
    dataGTM,
    className,
    mobileMenuSearch,
    contentSpecificSearch,
}) => {
    const baseClassName = 'pb-title-image';

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: !!parentClassName,
        [`${baseClassName}--reading-list`]: readingList,
        [isRightToLeftDirection
            ? `${baseClassName}--container-rtl`
            : `${baseClassName}--container`]: !reverse,
        [`${baseClassName}--reverse`]: reverse,
        [`${baseClassName}--mobile-menu`]: mobileMenu,
        [`${baseClassName}--bold`]: reverse && !avatar,
        [`${baseClassName}--mobile-menu-search`]: mobileMenuSearch,
        [isRightToLeftDirection
            ? `${baseClassName}--content-specific-search-rtl`
            : `${baseClassName}--content-specific-search`]:
            contentSpecificSearch,
    });

    let imgElement = avatar ? (
        avatar
    ) : (
        <img className={className} src={svgName} alt="" data-gtm={dataGTM} />
    );

    if (!avatar && mobileMenu) {
        imgElement = null;
    }

    return (
        <div className={classes} data-gtm={dataGTM}>
            {title}
            {imgElement}
        </div>
    );
};

TitleImage.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string,
    svgName: PropTypes.string,
    reverse: PropTypes.bool,
    mobileMenu: PropTypes.bool,
    avatar: PropTypes.node,
    readingList: PropTypes.bool,
    dataGTM: PropTypes.string,
    className: PropTypes.string,
    mobileMenuSearch: PropTypes.bool,
    contentSpecificSearch: PropTypes.bool,
};

export default TitleImage;
