import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flatMap, pick } from 'lodash';
import { connect } from 'react-redux';
import Inliner from '../Inliner';
import Picklist from '../Picklist';
import Dropdown from '../Dropdown';
import Link from '../Link';
import List from '../List';
import SortOption from '../SortOption';
import SvgIcon from '../SvgIcon';
import Modal from '../Modal';
import './FiltersBar.scss';
import close from '../../assets/close.png';
import Caret from '../Caret';

const mapStateToProps = ({ viewport }) => ({
    viewport,
});
const mapDispatchToProps = {};
@translate()
@connect(mapStateToProps, mapDispatchToProps)
class FiltersBar extends Component {
    static defaultProps = {
        resultsCount: 0,
    };
    constructor(props) {
        super(props);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleSecondOpen = this.toggleSecondOpen.bind(this);
        this.toggleThirdOpen = this.toggleThirdOpen.bind(this);
        this.state = {
            open: false,
            secondOpen: false,
            thirdOpen: false,
            openModalFilter: false,
            openModalSort: false,
            categoryTheme: null,
            categoryCategories: null,
        };
    }
    toggleOpen() {
        this.setState({
            open: !this.state.open,
            secondOpen: false,
            thirdOpen: false,
        });
    }
    toggleSecondOpen() {
        this.setState({
            secondOpen: !this.state.secondOpen,
            open: false,
            thirdOpen: false,
        });
    }

    toggleThirdOpen() {
        this.setState({
            thirdOpen: !this.state.thirdOpen,
            open: false,
            secondOpen: false,
        });
    }
    handlePicklistChange = (type, value, checked) => {
        const { applyFilter, removeFilter } = this.props;
        if (checked) {
            applyFilter(type, value);
        } else {
            removeFilter(type, value);
        }
    };
    openModalFilter = () => {
        this.setState({
            openModalFilter: true,
        });
    };
    modalFilterClose = () => {
        this.setState({
            openModalFilter: false,
        });
    };
    openModalSort = () => {
        this.setState({
            openModalSort: true,
        });
    };
    modalSortClose = () => {
        this.setState({
            openModalSort: false,
        });
    };
    filtersBarFilters = () => {
        let filtersBarFilters = 'category';
        return filtersBarFilters;
    };
    isCategory = () => {
        const { category } = this.props.filters;
        let categoryOptions = [];
        categoryOptions = category.queryValues;
        categoryOptions.map((categoryOption) => {
            this.setState({
                categoryTheme: categoryOption.theme,
                categoryCategories: categoryOption.categories,
            });
            return (
                <div>
                    <span>{this.state.categoryTheme}</span>
                    <Picklist
                        id="filers-bar-categories"
                        searchValue={this.props.categorySearchValue}
                        onSearchChange={this.props.updateCategorySearchValue}
                        options={this.state.categoryCategories}
                        onChange={this.handlePicklistChange.bind(
                            this,
                            'category'
                        )}
                        checkedValues={this.filtersBarFilters}
                    />
                </div>
            );
        });
    };
    render() {
        const baseClassName = 'pb-filters-bar';
        const {
            t,
            noTopBorder,
            pullUp,
            filters,
            levelSearchValue,
            categorySearchValue,
            updateLevelSearchValue,
            updateCategorySearchValue,
            appliedFilters,
            sortOptions,
            applySortOption,
            appliedSortOption,
            viewport,
            isIllustration,
            isIllustrationMobile,
            isResources,
            isBookSection,
            isVideos,
            isReadingList,
            isReadingListMobile,
            isBookSectionMobile,
            isResourceMobile,
            isTranslate,
            isTranslateMobile,
            isVideoMobile,
            isAddBook,
            isAddBookMobile,
            categoryFilterGTM,
            roleFilterGTM,
            levelFilterGTM,
            languageFilterGTM,
            favoriteSortGTM,
            viewSortGTM,
            alphabetSortGTM,
            readSortGTM,
            readAloud,
            hideLevelSelector,
            hideLanguageSelector,
            displayTypeSelector,
        } = this.props;

        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';

        const { category, role, current_role, language } = filters;

        const levelData = {
            queryValues: [
                {
                    description: t('PublicationForm.level1Text'),
                    name: t('PublicationForm.level1'),
                    queryValue: '1',
                },
                {
                    description: t('PublicationForm.level2Text'),
                    name: t('PublicationForm.level2'),
                    queryValue: '2',
                },
                {
                    description: t('PublicationForm.level3Text'),
                    name: t('PublicationForm.level3'),
                    queryValue: '3',
                },
                {
                    description: t('PublicationForm.level4Text'),
                    name: t('PublicationForm.level4'),
                    queryValue: '4',
                },
                {
                    description: t('PublicationForm.level5Text'),
                    name: t('PublicationForm.level5'),
                    queryValue: '5',
                },
                {
                    description: t('PublicationForm.level6Text'),
                    name: t('PublicationForm.level6'),
                    queryValue: '6',
                },
            ],
        };

        const typeData = {
            queryValues: [
                {
                    name: t('global.typeOption1'),
                    queryValue: '1',
                },
            ],
        };

        const filtersBarFilters = pick(appliedFilters, [
            // 'publisher',
            'category',
            'role',
            'language',
            'style',
            'level',
            'read_alouds',
        ]);

        //Level Selector
        let levelOptions = [];
        levelOptions = levelData.queryValues;

        levelOptions = levelOptions.map((levelOption) => {
            return {
                name: levelOption.name,
                legend: levelOption.description,
                queryValue: levelOption.queryValue,
            };
        });
        //Type Selector Options
        let typeOptions = [];
        typeOptions = typeData.queryValues;
        typeOptions = typeOptions.map((typeOption) => {
            return {
                name: typeOption.name,
                queryValue: typeOption.queryValue,
            };
        });
        const shouldDisplayCategorySelector =
            typeof filtersBarFilters.category !== 'undefined' &&
            typeof filters.category !== 'undefined';
        let categoryOptions = [];
        if (category) {
            categoryOptions = category.queryValues;
        }
        // const shouldDisplayRoleSelector = false;
        const shouldDisplayRoleSelector =
            typeof filtersBarFilters.role !== 'undefined' &&
            typeof filters.role !== 'undefined';
        let roleOptions = [];
        if (role) {
            roleOptions = role.queryValues;
        }

        const shouldDisplayLanguageSelector =
            typeof filtersBarFilters.language !== 'undefined' &&
            typeof filters.language !== 'undefined';
        let languageOptions = [];
        if (language) {
            languageOptions = language.queryValues;
        }
        const hasFilters = flatMap(
            filtersBarFilters,
            (filters) => filters
        ).length;
        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--no-top-border`]: noTopBorder,
            [`${baseClassName}--pull-up`]: pullUp,
            [`${baseClassName}--has-filter`]: hasFilters,
        };
        let filtersBar = null;
        if (viewport.medium) {
            filtersBar = (
                <div className={`${baseClassName}__action-wrapper`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__actions ${baseClassName}__actions--primary-arabic __bookFilter`
                                : `${baseClassName}__actions ${baseClassName}__actions--primary __bookFilter`
                        }
                    >
                        <span className={`${baseClassName}__section-label`}>
                            {t('global.filter-by')}
                        </span>

                        <List inline pushRight>
                            {!readAloud ? (
                                shouldDisplayCategorySelector ? (
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlack
                                                onClick={this.toggleOpen}
                                                dataGTM={categoryFilterGTM}
                                            >
                                                {t('global.category', 1)}{' '}
                                                <Caret
                                                    variant="twilight-lavender"
                                                    direction="down"
                                                />
                                            </Link>
                                        }
                                    >
                                        <div>
                                            <Picklist
                                                id="filers-bar-categories"
                                                searchValue={
                                                    categorySearchValue
                                                }
                                                onSearchChange={
                                                    updateCategorySearchValue
                                                }
                                                options={categoryOptions}
                                                onChange={this.handlePicklistChange.bind(
                                                    this,
                                                    'category'
                                                )}
                                                checkedValues={
                                                    filtersBarFilters.category
                                                }
                                            />
                                        </div>
                                    </Dropdown>
                                ) : null
                            ) : null}

                            {hideLevelSelector ? null : (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            onClick={this.toggleSecondOpen}
                                            dataGTM={levelFilterGTM}
                                        >
                                            {t('global.level', 1)}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        id="filers-bar-levels"
                                        searchValue={levelSearchValue}
                                        onSearchChange={updateLevelSearchValue}
                                        options={levelOptions}
                                        type="level"
                                        onChange={this.handlePicklistChange.bind(
                                            this,
                                            'level'
                                        )}
                                        checkedValues={filtersBarFilters.level}
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            )}

                            {shouldDisplayRoleSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            onClick={this.toggleSecondOpen}
                                            dataGTM={roleFilterGTM}
                                        >
                                            {t('global.role')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        options={roleOptions}
                                        onChange={this.handlePicklistChange.bind(
                                            this,
                                            'role'
                                        )}
                                        checkedValues={filtersBarFilters.role}
                                        currentRole={current_role}
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                            {shouldDisplayLanguageSelector &&
                            !hideLanguageSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            onClick={this.toggleThirdOpen}
                                            dataGTM={languageFilterGTM}
                                        >
                                            {t('Book.read-other-language')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        options={languageOptions}
                                        onChange={this.handlePicklistChange.bind(
                                            this,
                                            'language'
                                        )}
                                        checkedValues={
                                            filtersBarFilters.language
                                        }
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                            {displayTypeSelector ? (
                                <Dropdown
                                    align="left"
                                    toggleEl={
                                        <Link
                                            lightBlack
                                            onClick={this.toggleSecondOpen}
                                            dataGTM={levelFilterGTM}
                                        >
                                            {t('global.type')}{' '}
                                            <Caret
                                                variant="twilight-lavender"
                                                direction="down"
                                            />
                                        </Link>
                                    }
                                >
                                    <Picklist
                                        id="filers-bar-levels"
                                        searchValue={levelSearchValue}
                                        onSearchChange={updateLevelSearchValue}
                                        options={typeOptions}
                                        type="type"
                                        onChange={this.handlePicklistChange.bind(
                                            this,
                                            'read_alouds'
                                        )}
                                        checkedValues={
                                            filtersBarFilters.read_alouds
                                        }
                                        hideSearchBar
                                        fontWeight="bold"
                                    />
                                </Dropdown>
                            ) : null}
                        </List>
                    </div>
                    {sortOptions ? (
                        <div
                            className={`${baseClassName}__actions ${baseClassName}__actions--secondary`}
                        >
                            <span className={`${baseClassName}__section-label`}>
                                {t('global.sort-by')}
                            </span>
                            <Inliner auto>
                                <SortOption
                                    value={appliedSortOption}
                                    id="filters-bar-sort-by"
                                    name="filters-bar-sort-by"
                                    onChange={applySortOption}
                                    options={sortOptions}
                                    isIllustration={isIllustration}
                                    isResources={isResources}
                                    isAddBook={isAddBook}
                                    isBookSection={isBookSection}
                                    isVideos={isVideos}
                                    isTranslate={isTranslate}
                                    isReadingList={isReadingList}
                                    favoriteSortGTM={favoriteSortGTM}
                                    viewSortGTM={viewSortGTM}
                                    alphabetSortGTM={alphabetSortGTM}
                                    readSortGTM={readSortGTM}
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            </Inliner>
                        </div>
                    ) : null}
                </div>
            );
        } else {
            filtersBar = (
                <div className={`${baseClassName}__filter-responsive`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__filter-option-rtl`
                                : `${baseClassName}__filter-option`
                        }
                    >
                        <Link filterMobile onClick={this.openModalFilter}>
                            <SvgIcon
                                name="filter-option"
                                size="m"
                                svgIconBooks
                            />
                            {t('global.filter', 1)}
                        </Link>
                    </div>
                    {!isTranslateMobile ? (
                        <div className={`${baseClassName}__sort-option`}>
                            <Link filterMobile onClick={this.openModalSort}>
                                <SvgIcon name="sort" size="m" svgIconBooks />
                                {t('global.sort')}
                            </Link>
                        </div>
                    ) : null}
                </div>
            );
        }
        if (this.state.openModalFilter) {
            return (
                <div className={classNames(classes)}>
                    {filtersBar}
                    <Modal>
                        <div className={`${baseClassName}__close-modal`}>
                            <Link onClick={() => this.modalFilterClose()}>
                                <img
                                    className="__close-img"
                                    src={close}
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div
                            className={`${baseClassName}__actions ${baseClassName}__actions--primary`}
                        >
                            <div
                                className={`${baseClassName}__section-label-filter`}
                            >
                                {t('global.filter-by')}
                            </div>
                            <List pushRight>
                                {!readAloud ? (
                                    shouldDisplayCategorySelector ? (
                                        <Dropdown
                                            bookFilter
                                            align="left"
                                            toggleEl={
                                                <Link
                                                    lightBlackResponsive
                                                    onClick={this.toggleOpen}
                                                    dataGTM={categoryFilterGTM}
                                                >
                                                    <div
                                                        className="__option-values-media"
                                                        data-gtm={
                                                            categoryFilterGTM
                                                        }
                                                    >
                                                        {t(
                                                            'global.category',
                                                            1
                                                        )}{' '}
                                                        <Caret
                                                            variant="twilight-lavender"
                                                            direction="down"
                                                        />
                                                    </div>
                                                </Link>
                                            }
                                        >
                                            <div>
                                                <Picklist
                                                    id="filers-bar-categories"
                                                    searchValue={
                                                        categorySearchValue
                                                    }
                                                    onSearchChange={
                                                        updateCategorySearchValue
                                                    }
                                                    options={categoryOptions}
                                                    onChange={this.handlePicklistChange.bind(
                                                        this,
                                                        'category'
                                                    )}
                                                    checkedValues={
                                                        filtersBarFilters.category
                                                    }
                                                />
                                            </div>
                                            {/* </Sizer> */}
                                        </Dropdown>
                                    ) : null
                                ) : null}

                                {hideLevelSelector ? null : (
                                    <Dropdown
                                        bookFilter
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlackResponsive
                                                onClick={this.toggleSecondOpen}
                                            >
                                                <div className="__option-values-media">
                                                    {t('global.level', 1)}{' '}
                                                    <Caret
                                                        variant="twilight-lavender"
                                                        direction="down"
                                                    />
                                                </div>
                                            </Link>
                                        }
                                    >
                                        <Picklist
                                            id="filers-bar-levels"
                                            searchValue={levelSearchValue}
                                            onSearchChange={
                                                updateLevelSearchValue
                                            }
                                            options={levelOptions}
                                            onChange={this.handlePicklistChange.bind(
                                                this,
                                                'level'
                                            )}
                                            checkedValues={
                                                filtersBarFilters.level
                                            }
                                            hideSearchBar
                                            fontWeight="bold"
                                        />
                                    </Dropdown>
                                )}

                                {shouldDisplayRoleSelector ? (
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlackResponsive
                                                onClick={this.toggleSecondOpen}
                                                dataGTM={roleFilterGTM}
                                            >
                                                <div
                                                    className="__option-values-media"
                                                    data-gtm={roleFilterGTM}
                                                >
                                                    {t('global.role')}{' '}
                                                    <Caret
                                                        variant="twilight-lavender"
                                                        direction="down"
                                                    />
                                                </div>
                                            </Link>
                                        }
                                    >
                                        <Picklist
                                            options={roleOptions}
                                            onChange={this.handlePicklistChange.bind(
                                                this,
                                                'role'
                                            )}
                                            checkedValues={
                                                filtersBarFilters.role
                                            }
                                            hideSearchBar
                                            fontWeight="bold"
                                        />
                                    </Dropdown>
                                ) : null}
                                {shouldDisplayLanguageSelector &&
                                !hideLanguageSelector ? (
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlackResponsive
                                                onClick={this.toggleThirdOpen}
                                                dataGTM={languageFilterGTM}
                                            >
                                                <div
                                                    className="__option-values-media"
                                                    data-gtm={languageFilterGTM}
                                                >
                                                    {t(
                                                        'Book.read-other-language'
                                                    )}{' '}
                                                    <Caret
                                                        variant="twilight-lavender"
                                                        direction="down"
                                                    />
                                                </div>
                                            </Link>
                                        }
                                    >
                                        <Picklist
                                            options={languageOptions}
                                            onChange={this.handlePicklistChange.bind(
                                                this,
                                                'language'
                                            )}
                                            checkedValues={
                                                filtersBarFilters.language
                                            }
                                            hideSearchBar
                                            fontWeight="bold"
                                        />
                                    </Dropdown>
                                ) : null}
                                {displayTypeSelector ? (
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link
                                                lightBlackResponsive
                                                onClick={this.toggleSecondOpen}
                                            >
                                                <div className="__option-values-media">
                                                    {t('global.type')}{' '}
                                                    <Caret
                                                        variant="twilight-lavender"
                                                        direction="down"
                                                    />
                                                </div>
                                            </Link>
                                        }
                                    >
                                        <Picklist
                                            options={typeOptions}
                                            onChange={this.handlePicklistChange.bind(
                                                this,
                                                'read_alouds'
                                            )}
                                            checkedValues={
                                                filtersBarFilters.read_alouds
                                            }
                                            hideSearchBar
                                            fontWeight="bold"
                                        />
                                    </Dropdown>
                                ) : null}
                            </List>
                        </div>
                    </Modal>
                </div>
            );
        }
        if (this.state.openModalSort) {
            return (
                <div className={classNames(classes)}>
                    {filtersBar}
                    <Modal>
                        <div className={`${baseClassName}__close-modal`}>
                            <Link onClick={() => this.modalSortClose()}>
                                <img
                                    className="__close-img"
                                    src={close}
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div>
                            {sortOptions ? (
                                <div
                                    className={`${baseClassName}__actions ${baseClassName}__actions--secondary`}
                                >
                                    <div
                                        className={`${baseClassName}__section-label-filter`}
                                    >
                                        {t('global.sort-by')}
                                    </div>
                                    <SortOption
                                        value={appliedSortOption}
                                        id="filters-bar-sort-by"
                                        name={t('global.apply')}
                                        onChange={applySortOption}
                                        options={sortOptions}
                                        sortPopup={() => this.modalSortClose()}
                                        isBookSectionMobile={
                                            isBookSectionMobile
                                        }
                                        isVideoMobile={isVideoMobile}
                                        isResourceMobile={isResourceMobile}
                                        isReadingListMobile={
                                            isReadingListMobile
                                        }
                                        isAddBookMobile={isAddBookMobile}
                                        isIllustrationMobile={
                                            isIllustrationMobile
                                        }
                                        isTranslateMobile={isTranslateMobile}
                                        isRightToLeftDirection={
                                            isRightToLeftDirection
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </Modal>
                </div>
            );
        }
        return <div className={classNames(classes)}>{filtersBar}</div>;
    }
}
FiltersBar.propTypes = {
    noTopBorder: PropTypes.bool,
    pullUp: PropTypes.bool,
};
export default FiltersBar;
