import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './BookRating.scss';
import close from '../../assets/cancel.svg';
import TextField from '../TextField';
import { PopUpRating } from '../../api';
import withRouter from '../../util/withRouter';
import HappyEmoji from './components/HappyEmoji';
import NeutralEmoji from './components/NeutralEmoji';
import SadEmoji from './components/SadEmoji';

const BookRating = ({ t, bookId, bookSlug, handleClose, navigate }) => {
    const [reviewDesc, setReviewDesc] = useState('');
    const [happyEmojiClicked, setHappyEmojiClicked] = useState(true);
    const [neutralEmojiClicked, setNeutralEmojiClicked] = useState(true);
    const [sadEmojiClicked, setSadEmojiClicked] = useState(true);
    const [dataId, setDataId] = useState('');
    const [ratingId, setRatingId] = useState('');

    useEffect(() => {
        PopUpRating.fetchAllRatings()
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                const data = response.data;

                setDataId(data);
            });
    }, []);

    const onDescriptionChange = (e) => setReviewDesc(e.target.value);

    const onSubmit = () => {
        const params = {
            entity_ratable_id: bookId,
            entity_ratable_type: 'Story',
            rating_id: ratingId,
            comment: reviewDesc,
        };
        PopUpRating.postRating(params).then((response) => {
            if (response.status === 200) {
                navigate(`/stories/${bookSlug}`);
            }
        });
    };

    return (
        <div className="modal_wrapper">
            <div onClick={handleClose}>
                <img className="close-img" src={close} alt="" />
            </div>
            <div className="main_body">
                <div className="title_question"> {t('Rating.bookTitle')}</div>
                <div style={{ display: 'flex' }} className="emoji_style">
                    <HappyEmoji
                        setHappyEmojiClicked={setHappyEmojiClicked}
                        setNeutralEmojiClicked={setNeutralEmojiClicked}
                        setSadEmojiClicked={setSadEmojiClicked}
                        setRatingId={setRatingId}
                        happyEmojiClicked={happyEmojiClicked}
                        dataId={dataId}
                    />
                    <NeutralEmoji
                        setHappyEmojiClicked={setHappyEmojiClicked}
                        setNeutralEmojiClicked={setNeutralEmojiClicked}
                        setSadEmojiClicked={setSadEmojiClicked}
                        setRatingId={setRatingId}
                        neutralEmojiClicked={neutralEmojiClicked}
                        dataId={dataId}
                    />
                    <SadEmoji
                        setHappyEmojiClicked={setHappyEmojiClicked}
                        setNeutralEmojiClicked={setNeutralEmojiClicked}
                        setSadEmojiClicked={setSadEmojiClicked}
                        setRatingId={setRatingId}
                        sadEmojiClicked={sadEmojiClicked}
                        dataId={dataId}
                    />
                </div>
                <div className="title_message"> {t('Rating.feedback')}</div>
                <div className="create_message_box">
                    <TextField
                        id="search-modal-input"
                        value={reviewDesc}
                        size="m"
                        theme="dark"
                        onChange={onDescriptionChange}
                        bordered={true}
                        type="multiline"
                        rows="1"
                        maxLength="100"
                    />
                    <span className="description_length">
                        {reviewDesc.length}/100
                    </span>
                </div>
                <div>
                    {
                        <button
                            className={
                                ratingId === ''
                                    ? 'disableSubmitReview'
                                    : 'submitReview'
                            }
                            onClick={onSubmit}
                        >
                            {t('book.submit')}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

BookRating.propTypes = {
    t: PropTypes.func.isRequired,
    bookId: PropTypes.string.isRequired,
    bookSlug: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};
export default withRouter(BookRating);
