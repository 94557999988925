import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    fetchBookWorkflow,
    favouriteBookWorkflow,
    getBookDataWorkflow,
    getBookReaderStatusWorkflow,
    setBookReaderStatusWorkflow,
} from '../../redux/bookActions';
import {
    recordLocalization,
    recordBookReadCompleted,
} from '../../redux/googleAnalyticsActions';
import { translate } from 'react-polyglot';
import './RTRBookReaderContainer.scss';
import RTRBookReaderPageHeader from './components/RTRBookReaderPageHeader/RTRBookReaderPageHeader';
import RTRBookReaderPageFooter from './components/RTRBookReaderPageFooter/RTRBookReaderPageFooter';
import RTRBookReaderContent from './components/RTRBookReaderContent/index';
import { videoReadCountWorkflow } from '../../redux/readAloudsActions';
import DataLoader from '../DataLoader/DataLoader';
import withRouter from '../../util/withRouter';
import { macOSPlatforms } from './constants/macOSPlatforms';
import useFullscreen from '../../hooks/useFullScreen';

const RTRBookReaderContainer = ({
    t,
    setBookReaderStatusWorkflow,
    location,
    favouriteBookWorkflow,
    book,
    fetchBookWorkflow,
    getBookReaderStatusWorkflow,
    getBookDataWorkflow,
    params,
    viewport,
    chromeless,
    isLoggedIn,
    online,
    navigate,
}) => {
    const baseClassName = 'pb-app';

    const [pageNo, setPageNo] = useState(window.innerWidth > 1030 ? 1 : 0);
    const [addAnimation, setAddAnimation] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [xDown, setXDown] = useState(null);
    const [yDown, setYDown] = useState(null);
    const [bookData, setBookData] = useState({});
    const [likesCount, setLikesCount] = useState(0);
    const [loader, setLoader] = useState(true);

    const isFullscreen = useFullscreen();

    useEffect(() => {
        if (window.innerWidth < 1030) {
            const platform = window.navigator.platform;

            var myImg = document.getElementById('root');
            if (macOSPlatforms.indexOf(platform) !== -1) {
                console.log('Apple device');
            } else {
                myImg.requestFullscreen();
            }
        }
    }, []);

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        const bookId = path.split('-')[0];

        fetchBookWorkflow(path);
        var bookDataStatus = getBookReaderStatusWorkflow(bookId);

        bookDataStatus.then((response) => {
            if (
                response &&
                response.read_story_detail &&
                response.read_story_detail.current_page
            ) {
                setPageNo(
                    response.read_story_detail.current_page === 0
                        ? 1
                        : response.read_story_detail.current_page
                );
            }
        });

        const bookData = getBookDataWorkflow(bookId);
        bookData.then((resp) => {
            const data = resp.data;
            data.pages = data.pages.filter((e) => e.display);
            setBookData(data);
            setTotalPageCount(data.pages.length);
            setLikesCount(data.like_count);
            setLoader(false);
        });
    }, [location]);

    useEffect(() => {
        fetchBookWorkflow(params.slug);
    }, [params.slug]);

    if (loader) {
        return <DataLoader />;
    }

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--fill-height`]: !chromeless,
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        setXDown(firstTouch.clientX);
        setYDown(firstTouch.clientY);
    };

    const getTouches = (evt) => {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                if (pageNo < totalPageCount + 2) {
                    if (
                        (pageNo !== 0 && pageNo % 2 !== 0) ||
                        pageNo === totalPageCount + 1
                    ) {
                        setBookReaderStatusWorkflow(
                            location.pathname.split('/')[2].split('-')[0],
                            (pageNo + 1) / 2,
                            pageNo === totalPageCount + 1 ? 'reset' : ''
                        );
                    }

                    setPageNo((prev) => prev + 1);
                }
            } else {
                /* left swipe */
                if (pageNo > 0) {
                    if (pageNo !== 0 && pageNo % 2 === 0) {
                        setBookReaderStatusWorkflow(
                            location.pathname.split('/')[2].split('-')[0],
                            (pageNo - 1) / 2,
                            ''
                        );
                    }
                    setPageNo((prev) => prev - 1);
                }
            }
        }
        setXDown(null);
        setYDown(null);
    };

    const prevPage = (pageNumber) => {
        if (pageNumber > 0) {
            setBookReaderStatusWorkflow(
                location.pathname.split('/')[2].split('-')[0],
                pageNumber,
                '',
                bookData.request_id
            );

            setPageNo(pageNumber);
            setAddAnimation(true);
        }
    };

    const nextPage = (pageNumber) => {
        if (pageNumber < totalPageCount) {
            setBookReaderStatusWorkflow(
                location.pathname.split('/')[2].split('-')[0],
                pageNumber,
                pageNumber * 2 === totalPageCount + 3 ||
                    pageNumber * 2 === totalPageCount + 4
                    ? 'reset'
                    : '',
                bookData.request_id
            );
            setPageNo(pageNumber);
            setAddAnimation(true);
        }
    };

    const onFavoriteClicked = (bookID) => {
        favouriteBookWorkflow(bookID);
        if (book.isFavourite) {
            setLikesCount((prev) => prev - 1);
        } else {
            setLikesCount((prev) => likesCount + 1);
        }
    };

    const readAgain = () => {
        setPageNo(window.innerWidth > 1030 ? 1 : 0);
    };

    const hideAnimation = () => {
        setTimeout(() => {
            setAddAnimation(false);
        }, 1000);
    };

    const appendedBookData =
        window.innerWidth < 1030
            ? bookData
            : {
                  ...bookData,
                  pages: [
                      { dynamicPage: 1 },
                      { dynamicPage: 2 },
                      ...(bookData.pages
                          ? JSON.parse(JSON.stringify(bookData.pages))
                          : []),
                      { dynamicPage: 3 },
                      { dynamicPage: 4 },
                  ],
              };
    return (
        Object.keys(bookData).length > 0 && (
            <div className={classNames(classes)}>
                {!isFullscreen && !chromeless && window.innerWidth > 1030 && (
                    <RTRBookReaderPageHeader bookData={bookData} t={t} />
                )}
                <div className={`${baseClassName}__content`}>
                    {book && (
                        <RTRBookReaderContent
                            handleTouchStart={handleTouchStart}
                            handleTouchMove={handleTouchMove}
                            prevPage={prevPage}
                            online={online}
                            nextPage={nextPage}
                            onFavoriteClicked={onFavoriteClicked}
                            isLoggedIn={isLoggedIn}
                            bookData={appendedBookData} //Added this condition here because, we need this before component renders
                            book={book}
                            viewport={viewport}
                            t={t}
                            setBookReaderStatusWorkflow={
                                setBookReaderStatusWorkflow
                            }
                            readAgain={readAgain}
                            addAnimation={addAnimation}
                            hideAnimation={hideAnimation}
                            likesCount={likesCount}
                            history={navigate}
                            totalPageCount={totalPageCount}
                            pageNo={pageNo}
                        />
                    )}
                </div>
                {!isFullscreen &&
                    !chromeless &&
                    window.innerWidth > 1030 &&
                    book && (
                        <RTRBookReaderPageFooter
                            book={book}
                            readToMeAction={videoReadCountWorkflow}
                            t={t}
                            pageNo={pageNo}
                            totalPageCount={totalPageCount}
                        />
                    )}
            </div>
        )
    );
};

const mapStateToProps = (state) => {
    return {
        viewport: state.viewport,
        profile: state.user.profile,
        isLoggedIn: state.user.isLoggedIn,
        online: state.network.online,
        bookAssets: state.book.assets,
        book: state.book.book,
    };
};

const mapDispatchToProps = {
    recordLocalization,
    recordBookReadCompleted,
    fetchBookWorkflow,
    favouriteBookWorkflow,
    getBookDataWorkflow,
    getBookReaderStatusWorkflow,
    setBookReaderStatusWorkflow,
};

RTRBookReaderContainer.propTypes = {
    t: PropTypes.func.isRequired,
    setBookReaderStatusWorkflow: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    favouriteBookWorkflow: PropTypes.func.isRequired,
    book: PropTypes.object.isRequired,
    fetchBookWorkflow: PropTypes.func.isRequired,
    getBookReaderStatusWorkflow: PropTypes.func.isRequired,
    getBookDataWorkflow: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    viewport: PropTypes.object.isRequired,
    chromeless: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    online: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(RTRBookReaderContainer)
    )
);
