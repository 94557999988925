import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import { links } from '../../lib/constants';
import Dropdown from '../Dropdown';
import './ReadAloudsDetail.scss';
import SvgIcon from '../SvgIcon';
import Link from '../Link';
import Button from '../Button';
import Modal from '../Modal';
import ShareMenu from '../ShareMenu';
import DownloadRange from '../DownloadRange';
import BookDetailSummary from '../BookDetailSummary';
import Comments from '../Comments';
import closeIcon from '../../assets/closeIcon.png';
import VideoPlayer from '../VideoPlayer';
import SimilarVideos from './SimilarVideos';
import { favouriteReadAloudsWorkflow } from '../../redux/readAloudsActions';
import DocumentHeader from '../DocumentHeader';
import heartFilled from '../../assets/heart-filled.svg';
import heartUnFilled from '../../assets/heart-unfilled.svg';
import IframeContainer from '../IframeContainer/IframeContainer';



const mapDispatchToProps = {
    favouriteReadAloudsWorkflow,
};

@translate()
class ReadAloudsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDisqus: false,
            isModalVisible: false,
            isVideoPlay: false,
            downlaodModal: false,
            readsCount: this.props.readsCount,
            likesCount: this.props.likesCount,
            isFavourite: this.props.isFavourite,
        };
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.state.readsCount !== nextProps.readsCount) {
            this.setState({
                readsCount: nextProps.readsCount,
                likesCount: nextProps.likesCount,
            });
        }
    };

    handleToggleClick() {
        this.setState({
            showDisqus: true,
        });
    }

    videoModalOpen = () => {
        this.setState({
            isModalVisible: true,
            isVideoPlay: true,
            readsCount: this.state.readsCount + 1,
        });
        this.props.readCount(this.props.videoId);
    };

    videoModalClose = () => {
        this.setState({
            isModalVisible: false,
            isVideoPlay: false,
        });
    };

    onFavoriteClicked = (videoId) => {
        const { favouriteReadAloudsWorkflow } = this.props;
        favouriteReadAloudsWorkflow(videoId);
        if (this.state.isFavourite) {
            this.setState({
                likesCount: this.state.likesCount - 1,
                isFavourite: false,
            });
        } else {
            this.setState({
                likesCount: this.state.likesCount + 1,
                isFavourite: true,
            });
        }
    };

    videoPlayerModal = () => {
        return (
            <Modal>
                <div className="__close">
                    <Link
                        onClick={() => this.videoModalClose()}
                        dataGTM={this.props.videosVideoCloseGTM}
                    >
                        <img
                            className="__profile-images"
                            src={closeIcon}
                            alt=""
                            data-gtm={this.props.videosVideoCloseGTM}
                        />
                    </Link>
                </div>
                <div>
                    <VideoPlayer
                        name={this.props.videoDetail.name}
                        videoSrc={this.props.videoUrl}
                        videoPlay={this.state.isVideoPlay}
                    />
                </div>
            </Modal>
        );
    };

    downloadModal = () => {
        this.setState({
            downlaodModal: true,
        });
    };

    render() {
        const baseClassName = 'pb-readAloud-detail';
        const {
            t,
            parentClassName,
            online,
            isLoggedIn,
            logInMsg,
            viewport,
            videoDetail,
            videosVideoThumbnailGTM,
            videosVideoDownloadGTM,
            videosVideoFavoriteGTM,
            myLanguage,
            roles,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        const {
            id,
            slug,
            thumbUrl,
            name,
            summary,
            likesCount,
            duration,
            similar_videos,
            dpi_url,
            isDownloadable,
            youtube_video_identifier
        } = videoDetail;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        let heartEl = this.state.isFavourite ? (
            <img
                src={heartFilled}
                alt="heart-filled"
                className="__heart-unfilled"
            />
        ) : (
            <img
                src={heartUnFilled}
                alt="heart-unfilled"
                className="__heart-unfilled"
            />
        );

        let favouriteStat = null;
        if (likesCount >= 0 && online) {
            favouriteStat = (
                <div className={parentClassName}>
                    <Link
                        normal
                        favouriteShare
                        onClick={
                            isLoggedIn ? () => this.onFavoriteClicked(id) : null
                        }
                        dataGTM={videosVideoFavoriteGTM}
                    >
                        {heartEl}
                        {this.state.isFavourite}
                        <span style={{ marginLeft: '3px', fontSize:'1rem'}}>
                            {this.state.likesCount}
                        </span>
                    </Link>
                </div>
            );
        }

        let favouriteHandler = (
            <Dropdown readAloudShare toggleEl={favouriteStat}>
                <Link
                    favouriteShare
                    parentClassName={parentClassName}
                    fullWidth
                    legend={logInMsg}
                    href={links.redirectReadAloudSignIn(slug)}
                    dataGTM={videosVideoFavoriteGTM}
                >
                    <SvgIcon name="user" /> {t('global.log-in')}
                </Link>
            </Dropdown>
        );

        let steps = [
            {
                target: '.pb-link--fav-share',
                content: t('Video.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.__video-watch-btn',
                content: t('Video.watchHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__video-download-btn',
                content: t('Video.saveOfflineHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__comments',
                content: t('Video.disqusHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className={classNames(classes)}>
                <DocumentHeader
                    title={name}
                    description={summary}
                    keywords={slug}
                    imageUrl={thumbUrl}
                    imgAlt={name}
                />
                <Joyride
                    enabled={true}
                    steps={steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <DocumentTitle title={`${name} - ${t('global.site-title')}`} />
                <div className="__video-detail-section">
                    { youtube_video_identifier ? null :
                    <div className="__video-detail-left">
                        <Link
                            onClick={() => this.videoModalOpen()}
                            dataGTM={videosVideoThumbnailGTM}
                        >
                            <div
                                className="__video-detail-left-content"
                                data-gtm={videosVideoThumbnailGTM}
                            >
                                <div
                                    className="__video-play"
                                    data-gtm={videosVideoThumbnailGTM}
                                >
                                    <SvgIcon
                                        name="play"
                                        size="l"
                                        dataGTM={videosVideoThumbnailGTM}
                                    />
                                </div>
                                <div
                                    className="__thumbnailImg"
                                    data-gtm={videosVideoThumbnailGTM}
                                >
                                    <img
                                        src={thumbUrl}
                                        alt=""
                                        data-gtm={videosVideoThumbnailGTM}
                                    />
                                </div>
                                <div
                                    className="__video-duration"
                                    data-gtm={videosVideoThumbnailGTM}
                                >
                                    {duration}
                                </div>
                            </div>
                        </Link>
                    </div>
                    }

                    <div className={youtube_video_identifier ? "__video-detail" : "__video-detail-right"}>
                        <div className="__video-detail-right-content">
                            <div
                                className={
                                    myLanguage === 'lo'
                                        ? '__video-detail-lo-title'
                                        : isRightToLeftDirection
                                        ? '__video-detail-title __text-align-right'
                                        : '__video-detail-title'
                                }
                            >
                                {name}
                            </div>

                            {/* Youtube Embedded Player*/}
                            {
                                youtube_video_identifier ? 
                                    <IframeContainer 
                                        title={name}
                                        videoId={youtube_video_identifier}
                                    /> 
                                : null
                            }     

                            <div className="__status-share">
                                <div className={
                                        isRightToLeftDirection 
                                            ? "__video-like-count-rtl"
                                            : "__video-like-count"
                                    }
                                >
                                    <div
                                        className={
                                            isRightToLeftDirection 
                                            ? `${baseClassName}__like-count-rtl`
                                            : `${baseClassName}__like-count`
                                        }
                                    >
                                        {isLoggedIn
                                            ? favouriteStat
                                            : favouriteHandler}
                                    </div>
                                    <div>
                                        <span>
                                            <SvgIcon
                                                name="playIcon"
                                                size="sm"
                                                pushRight
                                                svgIcon
                                                playButton
                                            />
                                        </span>
                                        <span>{this.state.readsCount}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="__video-detail-desc">
                                <BookDetailSummary
                                    title={t('Book.summary')}
                                    description={summary}
                                    hideSummary
                                />
                            </div>

                            {youtube_video_identifier ? null :
                                <div className="__video-btn-section">
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? '__video-watch-btn-arabic'
                                                : '__video-watch-btn'
                                        }
                                    >
                                        <Button
                                            iconLeft="video"
                                            buttonWatchBlue
                                            fullWidth
                                            label={t(
                                                'Video.watch'
                                            )}
                                            variant="primary"
                                            onClick={() => this.videoModalOpen()}
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                        />
                                    </div>

                                    {isDownloadable ? (
                                        <div className="__video-download-btn">
                                            <DownloadRange
                                                dataGTM={videosVideoDownloadGTM}
                                                dpi_url={dpi_url}
                                            />
                                        </div>
                                    ) : (
                                        <div className="__video-download-disable-btn">
                                            <DownloadRange
                                                dataGTM={videosVideoDownloadGTM}
                                                dpi_url={dpi_url}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(
                    <div className={`${baseClassName}__disqus-section`}>
                        <div className="__disqussion">
                            <Comments
                                {...this.props}
                                source="readAloud"
                                id={id}
                                roles={roles}
                            />
                        </div>
                    </div>
                )}
                <div>
                    <SimilarVideos
                        myLanguage={myLanguage}
                        offline={!online}
                        similarVideos={similar_videos}
                        t={t}
                        viewport={viewport}
                    />
                </div>
                {this.state.isModalVisible && this.videoPlayerModal()}
            </div>
        );
    }
}

ReadAloudsDetail.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        helpStatus: state.help.helpStatus,
        roles: state.user.profile.roles,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadAloudsDetail);
