import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DetectIE from '../DetectIE';
import { connect } from 'react-redux';
import { initViewport } from '../../redux/viewportActions';
import { initNetwork } from '../../redux/networkActions';
import { helpStatusActionAsync } from '../../redux/helpActions';
import {
    fetchMeWorkflow,
    logoutWorkflow,
    getMenuList,
} from '../../redux/userActions';
import { changeLanguage, browserLanguage } from '../../redux/userActions';
import { addSlimNotification } from '../../redux/slimNotificationActions';
import {
    recordLocalization,
    recordBookReadCompleted,
} from '../../redux/googleAnalyticsActions';
import { translate } from 'react-polyglot';
import './AppContainer.scss';
import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import SlimNotificationContainer from '../SlimNotificationContainer';
import { getNavLinks } from '../../helpers/MenuItems';
import { useLocation } from 'react-router-dom';
// import withRouter from "../../util/withRouter"

const mapStateToProps = (state) => {
    return {
        viewport: state.viewport,
        isLoggedIn: state.user.isLoggedIn,
        profile: state.user.profile,
        menuList: state.user.menuList,
        isFetchingMe: state.user.isFetchingMe,
        online: state.network.online,
        book: state.book.book,
        helpStatus: state.help ? state.help.helpStatus : undefined,
    };
};

const mapDispatchToProps = {
    initViewport,
    initNetwork,
    fetchMeWorkflow,
    logoutWorkflow,
    changeLanguage,
    browserLanguage,
    addSlimNotification,
    recordLocalization,
    recordBookReadCompleted,
    helpStatusActionAsync,
    getMenuList,
};

const AppContainer = (props) => {
    let {
        chromeless = false,
        viewport,
        isLoggedIn,
        profile,
        menuList,
        logoutWorkflow,
        recordLocalization,
        recordBookReadCompleted,
        helpStatusActionAsync,
        changeLanguage,
        initNetwork,
        isFetchingMe,
        online,
        addSlimNotification,
        fetchMeWorkflow,
        getMenuList,
        initViewport,
        browserLanguage,
        children,
        helpStatus,
        t,
    } = props;
    const [language, setLanguage] = useState('');

    const location = useLocation();

    const handleFlashMessages = (response) => {
        const flashMessages = response.flashMessages || {};
        if (localStorage.getItem('publishMessage') !== null) {
            addSlimNotification({
                type: 'success',
                content: localStorage.getItem('publishMessage'),
            });
            localStorage.removeItem('publishMessage');
        } else if (flashMessages['success']) {
            addSlimNotification({
                type: 'success',
                content: flashMessages['success'],
            });
        } else if (flashMessages['error']) {
            addSlimNotification({
                type: 'danger',
                content: flashMessages['error'],
            });
        } else if (flashMessages['alert']) {
            addSlimNotification({
                type: 'danger',
                content: flashMessages['alert'],
            });
        } else {
            addSlimNotification({ content: flashMessages['notice'] });
        }
    };

    const fetchBrowserLanguage = async () => {
        if (localStorage && localStorage.getItem('locale') != null) {
            await getMenuList(localStorage.getItem('locale'));
        }

        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur'
            ? (document.getElementById('root').style.direction = 'rtl')
            : (document.getElementById('root').style.direction = 'ltr');

        fetchMeWorkflow().then((response) => {
            console.log('res123', response);
            //store isLoggedIn flag to localStorage to avoid repeated me api calls
            if (response.data) {
                localStorage.setItem('status', response.data.isLoggedIn);

                //below condition true for if new user first time opening the LC platform
                if (!localStorage.getItem('locale')) {
                    var language = [
                        'en',
                        'id',
                        'my',
                        'hi',
                        'vi',
                        'sw',
                        'mr',
                        'ne',
                        'bn',
                        'si',
                        'km',
                        'lo',
                        'zh-TW',
                        'ta',
                        'fil',
                        'zh-CN',
                        'es',
                        'ar',
                        'lg',
                        'ur',
                        'te',
                        'kn',
                        'nyn',
                        'gu',
                    ];
                    var sortedLanguage = language.sort();

                    var blang = language.includes(getFirstBrowserLanguage())
                        ? getFirstBrowserLanguage()
                        : 'en';
                    blang =
                        blang === 'zh-TW'
                            ? 'zh_TW'
                            : blang === 'zh-CN'
                            ? 'zh_CN'
                            : blang;
                    localStorage.setItem('locale', blang);
                    localStorage.setItem(
                        'index',
                        sortedLanguage.indexOf(blang)
                    );
                    getMenuList(localStorage.getItem('locale'));

                    //if user has navigator.language already set , response.data.locale will be in en ,by default it will be picked from navigator
                    if (response.data.locale !== blang) {
                        return browserLanguage(blang).then((response) => {
                            window.location.reload();
                        });
                    }
                }
                //not for frst time user but if user has already changes the LC language
                else {
                    if (response.data.locale !== localStorage.getItem('locale'))
                        browserLanguage(localStorage.getItem('locale')).then(
                            () => {
                                // window.location.reload()
                                console.log('entered');
                            }
                        );
                }
                if (localStorage.getItem('locale') === 'lo') {
                    document.body.classList.add('lao-language');
                }
            }
        });

        function getFirstBrowserLanguage() {
            var nav = window.navigator,
                browserLanguagePropertyKeys = [
                    'language',
                    'browserLanguage',
                    'systemLanguage',
                    'userLanguage',
                ],
                i,
                language,
                len,
                shortLanguage = null;

            // support for other well known properties in browsers
            for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
                language = nav[browserLanguagePropertyKeys[i]];
                //skip this loop iteration if property is null/undefined.  IE11 fix.
                if (language == null) {
                    continue;
                }
                len = language.length;
                if (!shortLanguage && len) {
                    shortLanguage = language;
                }
                if (language && len > 2) {
                    return language;
                }
            }

            if (shortLanguage) {
                return shortLanguage;
            }

            // support for HTML 5.1 "navigator.languages"
            if (Array.isArray(nav.languages)) {
                for (i = 0; i < nav.languages.length; i++) {
                    language = nav.languages[i];
                    len = language.length;
                    if (!shortLanguage && len) {
                        shortLanguage = language;
                    }
                    if (language && len > 2) {
                        return language;
                    }
                }
            }
        }
    };

    const onChangeLanguage = (newLanguage) => {
        // const { profile, changeLanguage, recordLocalization } = props;

        changeLanguage(newLanguage).then(() =>
            recordLocalization({
                userEmail: profile.email,
                newLanguage: newLanguage,
            })
        );
        localStorage.setItem('locale', newLanguage);

        setLanguage(localStorage.getItem('locale'));

        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur'
            ? (document.getElementById('root').style.direction = 'rtl')
            : (document.getElementById('root').style.direction = 'ltr');
    };

    //Function to Sniff IE
    const isIE = () => {
        let ua = navigator.userAgent;
        /* MSIE used to detect old browsers and Trident used to newer ones*/
        var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

        return is_ie;
        // let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // return isChrome
    };

    const baseClassName = 'pb-app';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--fill-height`]: !chromeless,
    };

    useEffect(() => {
        const initializeApp = async () => {
            isIE();
            fetchBrowserLanguage();
            initViewport();
            initNetwork();
            fetchMeWorkflow().then((response) => {
                if (
                    response.flashMessages ||
                    localStorage.getItem('publishMessage') !== null
                ) {
                    const flashMessages = response.flashMessages;
                    if (localStorage.getItem('publishMessage') !== null) {
                        addSlimNotification({
                            type: 'success',
                            content: localStorage.getItem('publishMessage'),
                        });
                        localStorage.removeItem('publishMessage');
                    } else if (flashMessages['success']) {
                        addSlimNotification({
                            type: 'success',
                            content: flashMessages['success'],
                        });
                    } else if (flashMessages['error']) {
                        addSlimNotification({
                            type: 'danger',
                            content: flashMessages['error'],
                        });
                    } else if (flashMessages['alert']) {
                        //TODO: This needs to be changed to warning state after warning state is added to SlimNotification
                        addSlimNotification({
                            type: 'danger',
                            content: flashMessages['alert'],
                        });
                    } else {
                        addSlimNotification({
                            //'Info' is the default state for any other type of flash messages ex. notice, etc
                            content: flashMessages['notice'],
                        });
                    }
                }
            });
        };
        initializeApp();
    }, []);

    const paths = location.pathname.split('/');
    const isReadStoryPage = paths[paths.length - 1] === 'readStoryPage';

    return isIE() ? (
        <DetectIE />
    ) : isReadStoryPage ? (
        <div className={`${baseClassName}__content`}>{children}</div>
    ) : (
        <div className={classNames(classes)}>
            {chromeless ? null : (
                <SiteHeader
                    viewport={viewport}
                    isLoggedIn={isLoggedIn}
                    user={profile}
                    logout={logoutWorkflow}
                    isFetchingMe={isFetchingMe}
                    offline={!online}
                    addSlimNotification={addSlimNotification}
                    changeLanguage={onChangeLanguage}
                />
            )}
            {chromeless ? null : <SlimNotificationContainer />}
            <div className={`${baseClassName}__content`}>{children}</div>
            {chromeless ? null : (
                <SiteFooter
                    isLoggedIn={isLoggedIn}
                    viewport={viewport}
                    shouldShowLanguageSelector={true}
                    offline={!online}
                />
            )}
        </div>
    );
};

AppContainer.propTypes = {
    chromeless: PropTypes.bool,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
