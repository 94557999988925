import React, { useState } from 'react';
import { links } from '../lib/constants';
import SiteNavLink from '../components/SiteNavLink';
import SvgIcon from '../components/SvgIcon';

export const getNavLinks = ({ t, menuList, viewport, user, isLoggedIn, onOpenHelpGuide, isHeader }) => {
    try {
        const filterMenuItems = (menuList, heading) => {
            return menuList ? menuList.filter(list => list.menu_heading === heading) : [];
        };
    
        const filterBookMenu = filterMenuItems(menuList, 'Books');
        const filterReadingListMenu = filterMenuItems(menuList, 'Reading Lists');
        const filterVideoMenu = filterMenuItems(menuList, 'Videos');
        const filterReadAloudMenu = filterMenuItems(menuList, 'Read Alouds');
        const filterResourceMenu = filterMenuItems(menuList, 'Resources');
    
        const PrivacyPolicy = window.location.origin + '/v0/privacy_policy';
        const locale = localStorage.getItem('locale');
    
        const footerLinks = [
            <SiteNavLink
                bold
                key="terms-conditions"
                termsCondTab
                variant="bordered"
                respondWidth
                align={viewport.large ? null : 'left'}
                dataGTM="terms-condition"
                href={window.location.origin + '/terms-and-conditions'}
                mobileFooter={viewport.large ? false : true}
            >
                {t('global.terms-and-conditions')}
            </SiteNavLink>,
            <SiteNavLink
                isExternal={locale === 'en' ? true : false}
                bold
                key="privacy-policy"
                privacyPolicyTab
                variant="bordered"
                respondWidth
                align={viewport.large ? null : 'left'}
                dataGTM="privacy-policy"
                href={
                    locale === 'en'
                        ? links.englishPrivacyPolicy()
                        : PrivacyPolicy
                }
                mobileFooter={viewport.large ? false : true}
            >
                {t('global.privacy-policy')}
            </SiteNavLink>,
            <SiteNavLink
                isExternal
                bold
                key="about-us"
                variant="bordered"
                respondWidth
                align={viewport.large ? null : 'left'}
                dataGTM="about-us"
                href={links.aboutUs()}
                mobileFooter={viewport.large ? false : true}
            >
                {t('global.about-us')}
            </SiteNavLink>,
        ]
        const headerLinks = [
            <SiteNavLink
                key="help guide"
                variant="bordered"
                onClick={(e) => onOpenHelpGuide(e)}
                helpguide
                dataGTM="nav-help-guide"
                light={!viewport.large}
                bold={!viewport.large}
            >
                {viewport.large ? (
                    <SvgIcon name="Help_dc" size="m" />
                ) : (
                    t('global.helpGuide')
                )}
            </SiteNavLink>
        ]
        let siteNavLinks = [
            filterBookMenu.length > 0 && filterBookMenu[0].flag && (
                <SiteNavLink
                    key="books"
                    booksTab
                    isInternal
                    variant="bordered"
                    respondWidth
                    shouldHide
                    dataGTM="nav-books"
                    href={links.allBooks(
                        void 0,
                        user.languagePreferences,
                        user.readingLevels
                    )}
                    active={
                        window.location.pathname.includes(
                            links
                                .allBooks(
                                    void 0,
                                    user.languagePreferences,
                                    user.readingLevels
                                )
                                .split('?')[0]
                        ) && viewport.large && isHeader
                    }
                    light={isHeader ? !viewport.large : false}
                    bold={!viewport.large}
                    mobileFooter={viewport.large ? false : true}
                >
                    {t('global.book', 2)}
                </SiteNavLink>
            ),
            filterReadingListMenu.length > 0 && filterReadingListMenu[0].flag && (
                <SiteNavLink
                    key="reading-list"
                    readingListTab
                    isInternal
                    variant="bordered"
                    respondWidth
                    shouldHide
                    dataGTM="nav-reading-list"
                    href="/readinglists"
                    active={
                        window.location.pathname.includes('readinglists') &&
                        viewport.large && isHeader
                    }
                    light={isHeader ? !viewport.large : false}
                    bold={!viewport.large}
                    mobileFooter={viewport.large ? false : true}
                >
                    {t('global.reading-list', 2)}
                </SiteNavLink>
            ),
            filterVideoMenu.length > 0 && filterVideoMenu[0].flag && (
                <SiteNavLink
                    key="videos"
                    videosTab
                    isInternal={isLoggedIn}
                    variant="bordered"
                    respondWidth
                    shouldHide
                    dataGTM="nav-videos"
                    href={
                        isLoggedIn ? '/videos' : links.fetchTabLogin('videos')
                    }
                    active={
                        window.location.pathname === '/videos' &&
                        viewport.large && isHeader
                    }
                    light={isHeader ? !viewport.large : false}
                    bold={!viewport.large}
                    mobileFooter={viewport.large ? false : true}
                >
                    {t('global.video', 2)}
                </SiteNavLink>
            ),
            locale === 'id' &&
            filterReadAloudMenu.length > 0 && filterReadAloudMenu[0].flag && (
                <SiteNavLink
                    key="readAloud"
                    videosTab
                    isInternal
                    variant="bordered"
                    respondWidth
                    shouldHide
                    dataGTM="nav-videos"
                    href={links.allReadAlouds(
                        void 0,
                        user.languagePreferences,
                        user.role
                    )}
                    active={
                        window.location.pathname.includes(
                            links
                                .allReadAlouds(
                                    void 0,
                                    user.languagePreferences,
                                    user.role
                                )
                                .split('?')[0]
                        ) && viewport.large && isHeader
                    }
                    light={isHeader ? !viewport.large : false}
                    bold={!viewport.large}
                >
                    {t('global.readAlouds')}
                </SiteNavLink>
            ),
            filterResourceMenu.length > 0 && filterResourceMenu[0].flag && (
                <SiteNavLink
                    key="resources"
                    resourcesTab
                    isInternal={isLoggedIn}
                    variant="bordered"
                    respondWidth
                    shouldHide
                    dataGTM="nav-resources"
                    href={
                        isLoggedIn
                            ? '/resources'
                            : links.fetchTabLogin('resources')
                    }
                    active={
                        window.location.pathname === '/resources' &&
                        viewport.large && isHeader
                    }
                    light={isHeader ? !viewport.large : false}
                    bold={!viewport.large}
                    mobileFooter={viewport.large ? false : true}
                >
                    {t('global.resource', 2)}
                </SiteNavLink>
            ),
        ];
        let NavLinks = isHeader ? [...siteNavLinks, ...headerLinks] : [...siteNavLinks, ...footerLinks];
        NavLinks = NavLinks.filter(link => link !== false);
    
        return NavLinks;
    } catch (error) {
        return [];
    }
}