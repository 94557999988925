import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReadingListCardContainer from '../../../ReadingListCardContainer';
import Grid from '../../../Grid';
import './ThemedReadingList.scss';

const ThemedReadingList = ({
    parentClassName,
    themedReadingLists,
    myLanguage,
}) => {
    const baseClassName = 'pb-themed-reading-list';
    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const gridVariant = window.screen.width <= 767 ? '3up' : '4up';
    return (
        <div className={classNames(classes)}>
            <div className="__reading-book-item-container">
                <Grid variant={gridVariant}>
                    {themedReadingLists.map((readingListItem) => {
                        return (
                            <div key={readingListItem.id}>
                                <ReadingListCardContainer
                                    myLanguage={myLanguage}
                                    key={readingListItem.id}
                                    readingListItem={readingListItem}
                                    readingListsTitle="themed-reading-lists-title"
                                />
                            </div>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = ({ viewport }) => {
    return {
        viewport,
    };
};

const mapDispatchToProps = {};

ThemedReadingList.propTypes = {
    parentClassName: PropTypes.string,
    themedReadingLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    myLanguage: PropTypes.string.isRequired,
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(ThemedReadingList)
);
