import React from 'react';
import { translate } from 'react-polyglot';
import { isRightToLeftDirection } from '../../../util/utils';

const NoResults = ({ t }) => (
    <span
        style={
            isRightToLeftDirection
                ? {
                      textAlign: 'start',
                  }
                : undefined
        }
    >
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul
            style={
                isRightToLeftDirection
                    ? {
                          paddingLeft: '0',
                          paddingRight: '0',
                      }
                    : undefined
            }
        >
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
);

export default translate()(NoResults);
