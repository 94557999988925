import React from 'react';
import PropTypes from 'prop-types';
import List from '../../List';
import Pill from '../../Pill';
import { links } from '../../../lib/constants';

const Tags = ({ tags, baseClassName, bookTagsGTM, type }) => {
    return (
        <List type={type}>
            {tags.map((t, i) => {
                return (
                    <Pill
                        bookDetails={true}
                        key={`tag.${i}`}
                        label={t.name}
                        href={links.searchByTag(t.query)}
                        isInternal
                        bookTagsGTM={bookTagsGTM}
                    />
                );
            })}
        </List>
    );
};

Tags.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            query: PropTypes.string.isRequired,
        })
    ).isRequired,
    baseClassName: PropTypes.string,
    bookTagsGTM: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default Tags;
