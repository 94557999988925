import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './MyReadingListDetail.scss';
import LoaderBlock from '../../../LoaderBlock';
import {
    deleteReadingListWorkflow,
    fetchReadingListBooksWorkflow,
    manageReadingListActionAsync,
    updateReadingListDetailsWorkflow,
} from '../../../../redux/readingListsActions';
import NotFoundPage from '../../../NotFoundPage';
import withRouter from '../../../../util/withRouter';
import DeleteReadingListModal from '../DeleteReadingListModal';
import EditReadingListModal from '../EditReadingListModal';
import MyReadingListDetailsHeader from '../MyReadingListDetailsHeader';
import MyReadingListDetailOptions from '../MyReadingListDetailOptions';
import MyReadingListDetailsInfo from '../MyReadingListDetailsInfo';
import MyReadinglistDetailBooks from '../MyReadinglistDetailBooks';

const MyReadingListDetail = ({
    fetchReadingListBooksWorkflow,
    navigate,
    manageReadingListActionAsync,
    readingListsBookDetail,
    updateReadingListDetailsWorkflow,
    t,
    isReadingListBooks,
}) => {
    const [isDeleteList, setIsDeleteList] = useState(false);
    const [isEditList, setIsEditList] = useState(false);

    const bookSlug = window.location.href.split('/').slice(4);

    useEffect(() => {
        fetchReadingListBooksWorkflow(bookSlug);
    }, []);

    const manageReadingList = (list) => {
        manageReadingListActionAsync(list);
    };

    const openDeleteModal = () => {
        setIsDeleteList(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteList(false);
    };

    const openEditModal = () => {
        setIsEditList(true);
    };

    const closeEditModal = () => {
        setIsEditList(false);
    };

    if (isReadingListBooks) {
        return <LoaderBlock />;
    }

    if (!isReadingListBooks && readingListsBookDetail.id === undefined) {
        return <NotFoundPage />;
    }
    return (
        <div className="__themedReadingLists-container">
            <DocumentTitle
                title={`${t('global.my-reading-lists')} - ${t(
                    'global.site-title'
                )}`}
            />
            <MyReadingListDetailsHeader t={t} />
            <div className="__themedReadingLists-section">
                <div className="__themedReadingListItem-container">
                    <div className="__themed-readinglist-section-header">
                        {!readingListsBookDetail.isShared && (
                            <MyReadingListDetailOptions
                                t={t}
                                manageReadingList={manageReadingList}
                                readingListsBookDetail={readingListsBookDetail}
                                openDeleteModal={openDeleteModal}
                                openEditModal={openEditModal}
                            />
                        )}
                        <MyReadingListDetailsInfo
                            readingListsBookDetail={readingListsBookDetail}
                        />
                        <MyReadinglistDetailBooks
                            readingListsBookDetail={readingListsBookDetail}
                        />
                    </div>
                </div>
            </div>
            {isDeleteList && (
                <DeleteReadingListModal
                    t={t}
                    readingListsBookDetail={readingListsBookDetail}
                    closeDeleteModal={closeDeleteModal}
                    navigate={navigate}
                />
            )}
            {isEditList && (
                <EditReadingListModal
                    t={t}
                    closeEditModal={closeEditModal}
                    readingListsBookDetail={readingListsBookDetail}
                    updateReadingListDetailsWorkflow={
                        updateReadingListDetailsWorkflow
                    }
                    navigate={navigate}
                    fetchReadingListBooksWorkflow={
                        fetchReadingListBooksWorkflow
                    }
                />
            )}
        </div>
    );
};
const mapStateToProps = ({ readingLists }) => {
    return {
        readingListsBookDetail: readingLists.readingListsBookDetail,
        isReadingListBooks: readingLists.isReadingListBooks,
        loadedPages: readingLists.loadedPages,
    };
};

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
    deleteReadingListWorkflow,
    manageReadingListActionAsync,
    updateReadingListDetailsWorkflow,
};

MyReadingListDetail.propTypes = {
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    manageReadingListActionAsync: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isShared: PropTypes.bool,
    }).isRequired,
    updateReadingListDetailsWorkflow: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isReadingListBooks: PropTypes.bool.isRequired,
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(MyReadingListDetail)
    )
);
