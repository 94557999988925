import React from 'react';
import PropTypes from 'prop-types';
import '../Book.scss';
import './BookDetails.scss';
import Credits from './Credits';
import Level from '../../Level';
import BookDetailSummary from '../../BookDetailSummary';
import GovtApproved from './GovtApproved';
import { isRightToLeftDirection } from '../../../util/utils';
import LanguageIndicator from './LanguageIndicator';

const BookDetails = ({
    level,
    isTranslation,
    isRelevelled,
    authors,
    publisher,
    illustrators,
    photographers,
    originalTitle,
    originalSlug,
    originalAuthors,
    online,
    t,
    onProfileLinkClicked,
    landingPageBook,
    title_logos,
    storyLevelColour,
    translator,
    type,
    isOriginalStoryPresent,
    baseClassName,
    description,
    isMobileWidth,
    language,
}) => {
    return (
        <div className="book-details">
            {!isMobileWidth && (
                <div className="level">
                    <div
                    // className={
                    //     title_logos.length > 0
                    //         ? 'level-reduced-width'
                    //         : 'level-custom-width'
                    // }
                    >
                        <Level
                            title={t(`PublicationForm.level${level}`)}
                            storyLevelColour={storyLevelColour}
                            isLandingBookPage
                        />
                    </div>
                    {title_logos.length > 0 && (
                        <GovtApproved
                            t={t}
                            isRightToLeftDirection={isRightToLeftDirection}
                            title_logos={title_logos}
                        />
                    )}
                    {language && (
                        <LanguageIndicator
                            language={language}
                            baseClassName={baseClassName}
                        />
                    )}
                </div>
            )}

            <div className={`${baseClassName}__summary-collection`}>
                <BookDetailSummary description={description} bookDetailPage />
            </div>

            {/* Credits */}
            <Credits
                isMobileWidth={isMobileWidth}
                isTranslation={isTranslation}
                isRelevelled={isRelevelled}
                authors={authors}
                publisher={publisher}
                illustrators={illustrators}
                photographers={photographers}
                originalTitle={originalTitle}
                originalSlug={originalSlug}
                originalAuthors={originalAuthors}
                offline={!online}
                t={t}
                onProfileLinkClicked={onProfileLinkClicked}
                landingPageBook={landingPageBook}
                translator={translator}
                type={type}
                isOriginalStoryPresent={isOriginalStoryPresent}
            />
        </div>
    );
};

BookDetails.propTypes = {
    level: PropTypes.number.isRequired,
    isTranslation: PropTypes.bool.isRequired,
    isRelevelled: PropTypes.bool.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    publisher: PropTypes.string.isRequired,
    illustrators: PropTypes.arrayOf(PropTypes.string).isRequired,
    photographers: PropTypes.arrayOf(PropTypes.string).isRequired,
    originalTitle: PropTypes.string.isRequired,
    originalSlug: PropTypes.string.isRequired,
    originalAuthors: PropTypes.arrayOf(PropTypes.string).isRequired,
    online: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onProfileLinkClicked: PropTypes.func.isRequired,
    landingPageBook: PropTypes.bool.isRequired,
    title_logos: PropTypes.arrayOf(PropTypes.string).isRequired,
    storyLevelColour: PropTypes.string.isRequired,
    translator: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOriginalStoryPresent: PropTypes.bool.isRequired,
};

export default BookDetails;
