import { cloneDeep } from 'lodash';
import queryString from 'query-string';
import { sortOptions } from '../../lib/constants';

// Tabs list in search page
const defaultFilters = {
    category: [],
    role: [],
    language: [],
    sort: sortOptions.position,
};

// Handle Mobile View
export const handleMobileView = (setIsMobile) => {
    if (window.innerWidth <= 768) setIsMobile(true);
    else setIsMobile(false);
};

// Get Applied Filters
export const getAppliedFilters = (location) => {
    const parsedFilters = queryString.parse(location.search);
    Object.entries(parsedFilters).forEach(([key, value]) => {
        if (Array.isArray(defaultFilters[key]) && !Array.isArray(value)) {
            parsedFilters[key] = [value];
        }
    });

    //WARN: Need to look into a side effect bug here
    //that is suspiciously changes the defaultFilters.role array even though
    //there is not direct mutation of this object. So each time, we ensure
    //role of defaultFilters is reset to empty array.
    defaultFilters.role = [];
    return {
        ...defaultFilters,
        ...parsedFilters,
    };
};

export const handleApplyFilter = (type, value, location, navigate, fetchReferencesWorkflow) => {
    const appliedFilters = cloneDeep(getAppliedFilters(location));

    if (Array.isArray(appliedFilters[type])) {
        appliedFilters[type].push(value);
    } else {
        appliedFilters[type] = value;
    }

    const searchParams = queryString.stringify(appliedFilters);
    navigate({ search: `?${searchParams}` }, { replace: true });

    fetchReferencesWorkflow(appliedFilters);
};

export const handleRemoveFilter = (type, value, location, navigate, fetchReferencesWorkflow) => {
    const appliedFilters = cloneDeep(getAppliedFilters(location));

    if (!appliedFilters[type]) {
        return;
    }

    if (Array.isArray(appliedFilters[type])) {
        const index = appliedFilters[type].indexOf(value);
        if (index !== -1) {
            appliedFilters[type].splice(index, 1);
        }
    } else {
        delete appliedFilters[type];
    }

    navigate({ search: `?${queryString.stringify(appliedFilters)}` }, { replace: true });

    fetchReferencesWorkflow(appliedFilters);
};