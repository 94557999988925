import React from 'react';
import PropTypes from 'prop-types';
import './Tab.scss';

const Tab = ({
    title,
    noPadding = false,
    active = false,
    shouldUnloadContent = false,
    children,
}) => {
    const baseClassName = 'pb-tab';
    const classNames = [baseClassName];

    if (noPadding) {
        classNames.push(`${baseClassName}--no-padding`);
    }

    if (active) {
        classNames.push(`${baseClassName}--active`);
    }

    return (
        <div className={classNames.join(' ')}>
            {!active && shouldUnloadContent ? null : children}
        </div>
    );
};

Tab.propTypes = {
    title: PropTypes.string.isRequired,
    noPadding: PropTypes.bool,
    active: PropTypes.bool,
    shouldUnloadContent: PropTypes.bool,
    children: PropTypes.node,
};

export default Tab;
