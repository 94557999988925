import React, { useState, useEffect } from 'react';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PostComment from './components/PostComment';
import Pagination from '../Pagination';
import DeleteCommentModal from './components/DeleteCommentModal';
import {
    getMainComments,
    postMainComments,
    removeComments,
    postReplyComments,
    getReplyComments,
    postLikeCall,
    deleteLikeCall,
    deleteReplyComments,
    reportComment,
    deleteReportedComment,
} from '../../redux/bookCommentAction';
import { isRightToLeftDirection } from '../../util/utils';
import './Comments.scss';
import MainComments from './components/MainComments';

const baseClassName = 'pb-comments';

const Comments = ({
    t,
    id,
    mainComments,
    source: commentSource = '',
    removeComments,
    getMainComments,
    isLoggedIn,
    profileImage,
    postMainComments,
    isMainCommentLoading,
    isLikeLoading,
    postLikeCall,
    deleteLikeCall,
    isReplyCommentLoading,
    getReplyComments,
    replyComments,
    isReportLoading,
    roles,
    deleteReportedComment,
    postReplyComments,
    isMoreReplyButtonActive,
    deleteReplyComments,
    isMoreCommentButtonActive,
    reportComment,
    viewport,
    isDeleteReportedCommentloading,
}) => {
    const [pageNo, setPageNo] = useState(1);
    const [showLikedUsers, setShowLikedUsers] = useState('');
    const [activeCommentId, setActiveCommentId] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reportedCommentId, setReportedCommentId] = useState('');
    const [showReply, setShowReply] = useState('');
    const [replyPageNo, setReplyPageNo] = useState(1);

    useEffect(() => {
        emptyComments();
    }, []);

    const emptyComments = async () => {
        await removeComments(id, commentSource);
        fetchMainComments();
    };

    const fetchMainComments = () => {
        getMainComments(id, pageNo, commentSource);
        setPageNo((prev) => prev + 1);
    };

    const postComment = (newComment) => {
        postMainComments(id, newComment, commentSource);
    };

    const postReply = (commentid, newReply) => {
        postReplyComments(commentid, newReply);
    };

    const likeMouseOut = () => {
        setShowLikedUsers(null);
    };

    const likeMouseEnter = async (commentId) => {
        await setShowLikedUsers(commentId);

        const likedUsersContainer = document.querySelector(
            `.${baseClassName}__likedUsersContainer`
        );

        if (likedUsersContainer) {
            likedUsersContainer.style.marginTop = `-${likedUsersContainer.scrollHeight}px`;
        }
    };

    const postOrDeleteLike = (commentId, likedUsersList, userId, source) => {
        const isLikedAlready = likedUsersList.filter(
            (list) => list.user_id === userId
        );

        setActiveCommentId(commentId);

        isLikedAlready.length === 0
            ? postLikeCall(commentId, source)
            : deleteLikeCall(commentId, source);
    };

    const deleteComment = (commentId) => {
        deleteReportedComment(commentId);
    };

    const getMoreReplyComments = (commentId) => {
        setActiveCommentId(commentId);
        getReplyComments(commentId, replyPageNo);
        setReplyPageNo((prev) => prev + 1);
        setShowReply(commentId);
    };

    const getReplyCommentsData = (commentId) => {
        if (showReply) {
            deleteReplyComments(commentId);
            setShowReply('');
            setActiveCommentId(commentId);
        } else {
            getReplyComments(commentId, 1);
            setShowReply(commentId);
            setReplyPageNo(2);
            setActiveCommentId(commentId);
        }
    };

    const submitReportRequest = (reason) => {
        reportComment(reportedCommentId, reason);
        setReportedCommentId('');
    };

    const isUserAdmin = () => {
        let getResult =
            roles.includes('content_manager') ||
            roles.includes('sub_admin') ||
            roles.includes('admin');

        return getResult;
    };

    return (
        <div>
            <div
                className={
                    isRightToLeftDirection ? '__comments-rtl' : '__comments'
                }
                data-gtm="book-disqus-comments"
            >
                {t('Book.comments') + ' (' + mainComments.length + ')'}
            </div>
            {isLoggedIn ? (
                <PostComment
                    id={id}
                    url={profileImage}
                    postComment={postComment}
                    t={t}
                    isLoggedIn={isLoggedIn}
                />
            ) : (
                <p
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__login-message-rtl`
                            : `${baseClassName}__login-message`
                    }
                >
                    {t('comments.loginNotification')}
                </p>
            )}
            <MainComments
                t={t}
                isMainCommentLoading={isMainCommentLoading}
                mainComments={mainComments}
                baseClassName={baseClassName}
                showLikedUsers={showLikedUsers}
                likeMouseOut={likeMouseOut}
                isLoggedIn={isLoggedIn}
                likeMouseEnter={likeMouseEnter}
                isLikeLoading={isLikeLoading}
                activeCommentId={activeCommentId}
                postOrDeleteLike={postOrDeleteLike}
                isReplyCommentLoading={isReplyCommentLoading}
                getReplyCommentsData={getReplyCommentsData}
                replyComments={replyComments}
                isReportLoading={isReportLoading}
                setShowDeleteModal={setShowDeleteModal}
                setReportedCommentId={setReportedCommentId}
                setActiveCommentId={setActiveCommentId}
                isUserAdmin={isUserAdmin}
                deleteComment={deleteComment}
                showReply={showReply}
                profileImage={profileImage}
                postReply={postReply}
                isMoreReplyButtonActive={isMoreReplyButtonActive}
                getMoreReplyComments={getMoreReplyComments}
            />

            {isMoreCommentButtonActive && (
                <div>
                    <Pagination
                        label="Show More Comments"
                        onClick={() => fetchMainComments()}
                    />
                </div>
            )}
            {showDeleteModal ? (
                <DeleteCommentModal
                    onConfirm={(reason) => submitReportRequest(reason)}
                    onClose={() => setShowDeleteModal(false)}
                    viewport={viewport}
                    isDeleteReportedCommentloading={
                        isDeleteReportedCommentloading
                    }
                />
            ) : null}
        </div>
    );
};

const mapDispatchToProps = {
    getMainComments,
    postMainComments,
    postReplyComments,
    getReplyComments,
    removeComments,
    postLikeCall,
    deleteLikeCall,
    deleteReplyComments,
    reportComment,
    deleteReportedComment,
};

const mapStateToProps = (state) => {
    return {
        mainComments: state.bookComments.mainComments,
        replyComments: state.bookComments.replyComments,
        isMoreCommentButtonActive: state.bookComments.isMoreCommentButtonActive,
        isMoreReplyButtonActive: state.bookComments.isMoreReplyButtonActive,
        profileImage: state.user.profile.profileImage,
        isMainCommentLoading: state.bookComments.isMainCommentLoading,
        isReplyCommentLoading: state.bookComments.isReplyCommentLoading,
        totalComments: state.bookComments.totalComments,
        isLikeLoading: state.bookComments.isLikeLoading,
        isReportLoading: state.bookComments.isReportLoading,
        isDeleteReportedCommentloading:
            state.bookComments.isDeleteReportedCommentloading,
    };
};

Comments.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    mainComments: PropTypes.arrayOf(PropTypes.object).isRequired,
    source: PropTypes.string,
    removeComments: PropTypes.func.isRequired,
    getMainComments: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    profileImage: PropTypes.string,
    postMainComments: PropTypes.func.isRequired,
    isMainCommentLoading: PropTypes.bool.isRequired,
    isLikeLoading: PropTypes.bool.isRequired,
    postLikeCall: PropTypes.func.isRequired,
    deleteLikeCall: PropTypes.func.isRequired,
    isReplyCommentLoading: PropTypes.bool.isRequired,
    getReplyComments: PropTypes.func.isRequired,
    replyComments: PropTypes.arrayOf(PropTypes.object).isRequired,
    isReportLoading: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    deleteReportedComment: PropTypes.func.isRequired,
    postReplyComments: PropTypes.func.isRequired,
    isMoreReplyButtonActive: PropTypes.bool.isRequired,
    deleteReplyComments: PropTypes.func.isRequired,
    isMoreCommentButtonActive: PropTypes.bool.isRequired,
    reportComment: PropTypes.func.isRequired,
    viewport: PropTypes.string,
    isDeleteReportedCommentloading: PropTypes.bool.isRequired,
};

Comments.defaultProps = {
    source: '',
    profileImage: '',
    viewport: '',
};

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(Comments)
);
