import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import FiltersBar from '../../../FiltersBar';
import LoaderBlock from '../../../LoaderBlock';
import NoResults from '../NoResults/NoResults';
import ThemedReadingList from '../ThemedReadingList';
import Pagination from '../../../Pagination';
import {
    fetchReadingListDetailsWorkflow,
    fetchReadingListsFiltersWorkflow,
} from '../../../../redux/readingListsActions';
import { sortOptions } from '../../../../lib/constants';
import { applyFilter, resetFilters } from '../../../../redux/allFiltersActions';
import withRouter from '../../../../util/withRouter';
import { filtersBarSortOptions } from '../../constants/filtersBarSortOptions';

const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.position,
    tags: [],
    query: '',
};

const ThemedReadingListContainer = ({
    t,
    fetchReadingListDetailsWorkflow,
    fetchReadingListsFiltersWorkflow,
    navigate,
    location,
    parentClassName,
    themedReadingLists,
    filters,
    isFetchingReadingList,
    totalListsCount,
    myLanguage,
    loadedPages,
    slug,
    isFetchingMoreReadingList,
}) => {
    const [filtersSearchText, setFiltersSearchText] = useState({
        category: '',
        publisher: '',
        style: '',
        language: '',
    });

    useEffect(() => {
        fetchReadingListsFiltersWorkflow().then((response) => {
            let filters = getAppliedFilters();
            filters.language = response.data.current_language;
            const searchParams = queryString.stringify(filters);
            navigate({ search: `?${searchParams}` }, { replace: true });
            fetchReadingListDetailsWorkflow(filters);
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (location.search === '') {
                await fetchReadingListsFiltersWorkflow().then((response) => {
                    let filters = getAppliedFilters();
                    filters.role = [];
                    filters.role = filters.role.concat(
                        response.data.current_role
                    );
                    filters.language = response.data.current_language;
                    const searchParams = queryString.stringify(filters);
                    navigate({ search: `?${searchParams}` }, { replace: true });
                    fetchReadingListDetailsWorkflow(filters);
                });
            }
        };

        fetchData();
    }, [location.search]);

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    const applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchReadingListDetailsWorkflow(appliedFilters);
    };

    const removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchReadingListDetailsWorkflow(appliedFilters);
    };

    const baseClassName = 'pb-themed-reading-list-container';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const appliedFilters = getAppliedFilters();

    const handleSearchTextChange = (key) => (e) => {
        setFiltersSearchText((prevState) => ({
            ...prevState,
            [key]: e.target.value,
        }));
    };

    const onSortOptionChanged = (option) => applyFilter('sort', option);

    const shouldShowLoadMore = loadedPages && loadedPages < totalListsCount;

    const onLoadMore = () =>
        fetchReadingListDetailsWorkflow(
            getAppliedFilters(),
            slug,
            loadedPages + 1
        );

    if (!filters || isFetchingReadingList) {
        return <LoaderBlock />;
    }

    return (
        <div className={classNames(classes)}>
            {themedReadingLists && themedReadingLists.length >= 0 && (
                <div className="__video-filter-component">
                    <FiltersBar
                        noTopBorder
                        pullUp
                        filters={filters}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                        appliedFilters={appliedFilters}
                        styleSearchValue={filtersSearchText.style}
                        updateStyleSearchValue={() =>
                            handleSearchTextChange('style')
                        }
                        categorySearchValue={filtersSearchText.category}
                        updateCategorySearchValue={() =>
                            handleSearchTextChange('category')
                        }
                        publisherSearchValue={filtersSearchText.publisher}
                        updatePublisherSearchValue={() =>
                            handleSearchTextChange('publisher')
                        }
                        resultsCount={totalListsCount}
                        sortOptions={filtersBarSortOptions(t, sortOptions)}
                        applySortOption={onSortOptionChanged}
                        appliedSortOption={appliedFilters.sort}
                        isReadingList
                        isReadingListMobile
                        languageFilterGTM="themed-reading-list-language-filter"
                        alphabetSortGTM="themed-reading-list-alphabetic-sort"
                    />
                </div>
            )}
            <div>
                {themedReadingLists.length <= 0 && (
                    <div className="no-results">
                        <NoResults t={t} />
                    </div>
                )}
            </div>
            <ThemedReadingList
                myLanguage={myLanguage}
                themedReadingLists={themedReadingLists}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                onSortOptionChanged={onSortOptionChanged}
                tags={appliedFilters.tags}
                appliedFilters={{ ...appliedFilters }}
            />
            {shouldShowLoadMore ? (
                <Pagination
                    onClick={onLoadMore}
                    loading={isFetchingMoreReadingList}
                    dataGTM="thematic-reading-list-view-more"
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    fetchReadingListsFiltersWorkflow,
    fetchReadingListDetailsWorkflow,
    applyFilter,
    resetFilters,
};

ThemedReadingListContainer.propTypes = {
    t: PropTypes.func.isRequired,
    fetchReadingListDetailsWorkflow: PropTypes.func.isRequired,
    fetchReadingListsFiltersWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    parentClassName: PropTypes.string,
    themedReadingLists: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    isFetchingReadingList: PropTypes.bool.isRequired,
    totalListsCount: PropTypes.number.isRequired,
    myLanguage: PropTypes.string.isRequired,
    loadedPages: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    isFetchingMoreReadingList: PropTypes.bool.isRequired,
};

ThemedReadingListContainer.defaultProps = {
    parentClassName: '',
};

export default withRouter(
    translate()(
        connect(mapStateToProps, mapDispatchToProps)(ThemedReadingListContainer)
    )
);
