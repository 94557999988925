import { useState, useEffect, useRef } from 'react';
import { debounce } from '../util/utils';

const useMeasureHeight = (debounceDelay = 300) => {
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    const debouncedUpdateHeight = useRef(
        debounce(() => {
            if (elementRef.current) {
                setHeight(elementRef.current.offsetHeight);
            }
        }, debounceDelay)
    ).current;

    useEffect(() => {
        debouncedUpdateHeight();

        window.addEventListener('resize', debouncedUpdateHeight);
        return () =>
            window.removeEventListener('resize', debouncedUpdateHeight);
    }, [debouncedUpdateHeight]);

    return [elementRef, height];
};

export default useMeasureHeight;