import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import ShareMenu from '../../ShareMenu';
import { isRightToLeftDirection } from '../../../util/utils';

const ThemedReadingListsHeader = ({ t, readingListsBookDetail }) => {
    return (
        <Block background="transparent">
            <ContentStyler>
                <div className="__header-section">
                    <div className="__title">
                        <h3>{readingListsBookDetail.title}</h3>
                        <h3 className="__readinglist-count">
                            {readingListsBookDetail.count}
                        </h3>
                    </div>

                    <div className="__right-side-header-section">
                        {/* Socail Sharing for Reading List */}
                        <div className="__reading-list-share-container">
                            <ShareMenu
                                readingList
                                align="left"
                                title={readingListsBookDetail.title}
                                desc={readingListsBookDetail.description}
                                href={window.location.href}
                            />
                        </div>

                        <div className="__readinglist-creater">
                            <span className="__created-by-text">
                                {t('Illustration.created-by')}
                            </span>
                            {readingListsBookDetail.organisation ? (
                                <span className="__created-by-name">
                                    {readingListsBookDetail.organisation.name}
                                </span>
                            ) : (
                                <span className="__created-by-name">
                                    {readingListsBookDetail &&
                                        readingListsBookDetail.publisher &&
                                        readingListsBookDetail.publisher.name}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </ContentStyler>
            <div
                className={
                    isRightToLeftDirection
                        ? '__readinglist-header-text-arabic'
                        : '__readinglist-header-text'
                }
            >
                {readingListsBookDetail.description}
            </div>
        </Block>
    );
};

ThemedReadingListsHeader.propTypes = {
    t: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        title: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        description: PropTypes.string,
        organisation: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        publisher: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default ThemedReadingListsHeader;
