import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Button';

const DisabledReadingListButton = ({
    baseClassName,
    BtnDisabled,
    buttonText,
    isEdit,
}) => {
    return (
        <div className={`${baseClassName}__disabled-button`}>
            <Button
                disabled={BtnDisabled}
                btnDisabled
                fullWidth
                label={buttonText}
                dataGTM={isEdit ? 'edit-books' : 'add-books'}
                variant="primary"
            />
        </div>
    );
};

DisabledReadingListButton.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    BtnDisabled: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
};

export default DisabledReadingListButton;
