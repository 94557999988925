import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CardsCarousel from '../CardsCarousel';
import HorizontalGrid from '../HorizontalGrid';
import './CardShelf.scss';

const CardShelf = ({ children, cellWidth, viewport, isBookReaderPage = false }) => {
    const baseClassName = 'pb-book-shelf';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            {viewport.medium ? (
                <CardsCarousel
                    viewport={viewport}
                    popoutControls={viewport.xxlarge}
                    isBookReaderPage={isBookReaderPage}
                >
                    {children}
                </CardsCarousel>
            ) : (
                <HorizontalGrid cellWidth={cellWidth}>
                    {children}
                </HorizontalGrid>
            )}
        </div>
    );
}

CardShelf.propTypes = {
    children: PropTypes.node,
    viewport: PropTypes.shape({
        medium: PropTypes.bool,
        xxlarge: PropTypes.bool,
    }).isRequired,
    cellWidth: PropTypes.string,
    isBookReaderPage: PropTypes.bool,
};

export default CardShelf;
