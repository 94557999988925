import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import BookCard from '../../BookCard';

const MyReadinglistDetailBooks = ({ readingListsBookDetail }) => {
    return (
        <div>
            <Grid variant="4up">
                {readingListsBookDetail.books &&
                    readingListsBookDetail.books.map((bookItem) => {
                        return (
                            <div className="__themedBookItem-section">
                                <BookCard
                                    key={bookItem.slug}
                                    id={bookItem.id}
                                    book={bookItem}
                                    isFavourite={bookItem.is_favourite}
                                    bookLikes={bookItem.likesCount}
                                    isThemedList
                                    isFavouriting={bookItem.isFavouriting}
                                    bookImageGTM="manage-book-image"
                                    bookTitleGTM="manage-book-title"
                                    bookAuthorGTM="manage-book-author"
                                    bookIllustratorsGTM="manage-book-illustrators"
                                    bookLevelGTM="manage-book-level"
                                />
                            </div>
                        );
                    })}
            </Grid>
        </div>
    );
};

MyReadinglistDetailBooks.propTypes = {
    readingListsBookDetail: PropTypes.shape({
        books: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                    .isRequired,
                slug: PropTypes.string.isRequired,
                is_favourite: PropTypes.bool,
                likesCount: PropTypes.number,
                isFavouriting: PropTypes.bool,
            })
        ),
    }).isRequired,
};

export default MyReadinglistDetailBooks;
