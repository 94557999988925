import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Columnizer.scss';

const Columnizer = ({ children, columns, parentClassName, noGutter }) => {
    const baseClassName = 'pb-columnizer';

    const columnEls = [];

    if (children && children.length && typeof children !== 'string') {
        React.Children.forEach(children, (c, i) => {
            const styles = {
                width: '100%',
            };

            if (Array.isArray(columns)) {
                styles.width = columns[i % columns.length];
                if (i % columns.length === 0) {
                    styles.clear = 'left';
                }
            } else if (typeof columns === 'number') {
                styles.width = `${100 / columns}%`;
            } else {
                styles.width = `${100 / children.length}%`;
            }

            columnEls.push(
                <div key={i} className="pb-columnizer__column" style={styles}>
                    {c}
                </div>
            );
        });
    } else {
        const styles = {
            width: '100%',
        };

        columnEls.push(
            <div key="1" className="pb-columnizer__column" style={styles}>
                {children}
            </div>
        );
    }

    const classes = classNames(baseClassName, {
        [parentClassName]: parentClassName,
        [`${baseClassName}--no-gutter`]: noGutter,
    });

    return <div className={classes}>{columnEls}</div>;
};

Columnizer.propTypes = {
    children: PropTypes.node.isRequired,
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    parentClassName: PropTypes.string,
    noGutter: PropTypes.bool,
};

export default Columnizer;
