import React from 'react';
import PropTypes from 'prop-types';
import { isRightToLeftDirection } from '../../../util/utils';

const NoResults = ({ t }) => {
    return (
        <span>
            <p>{t('Books.no-result-warning-1')}</p>
            <p>{t('Books.no-result-warning-2')}</p>
            <ul
                style={
                    isRightToLeftDirection
                        ? { paddingLeft: '0', paddingRight: '0' }
                        : null
                }
            >
                <li>{t('Books.no-result-warning-3')}</li>
                <li>{t('Books.no-result-warning-4')}</li>
                <li>{t('Books.no-result-warning-5')}</li>
                <li>{t('Books.no-result-warning-6')}</li>
            </ul>
        </span>
    );
};

NoResults.propTypes = {
    t: PropTypes.func.isRequired,
};
export default NoResults;
