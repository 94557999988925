import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import { arrayToI18nList } from '../../../lib/textUtils.js';
import { isMobile, isTablet } from '../../../util';
import { links, linkType } from '../../../lib/constants';
import './Credits.scss';

const baseClassName = 'pb-credits';

const Credits = ({
    isTranslation,
    isRelevelled,
    authors,
    publisher,
    illustrators,
    photographers,
    originalTitle,
    originalSlug,
    originalAuthors,
    t,
    offline,
    onProfileLinkClicked,
    isRightToLeftDirection,
    landingPageBook,
    translator,
    type,
    isOriginalStoryPresent,
    isMobileWidth,
}) => {
    const makeProfileLinks = (collection, sectionClicked) =>
        collection.map((item, i) => (
            <Link
                normal
                storyCredit={true}
                isInternal={true}
                key={item.slug}
                disabled={offline}
                href={links.userProfile(item.slug)}
                dataGTM="book-author"
            >
                {item.name}
            </Link>
        ));

    const authorLinks = makeProfileLinks(
        authors,
        linkType.writtenBy
        // isTranslation ? linkType.translatedBy : linkType.writtenBy
    );

    let mainAuthorsEl;
    if (type === 'adaptation' && isOriginalStoryPresent) {
        mainAuthorsEl = '';
    } else if (isRelevelled) {
        mainAuthorsEl = (
            <p
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__arabic`
                        : `${baseClassName}`
                }
            >
                {t('Book.relevelled-by')}{' '}
                {arrayToI18nList(authorLinks, t('book.and'))}
            </p>
        );
    } else {
        mainAuthorsEl = (
            <p
                className={
                    localStorage.getItem('locale') === 'ar' ||
                    localStorage.getItem('locale') === 'ur'
                        ? `${baseClassName}__arabic`
                        : `${baseClassName}`
                }
            >
                {t('global.author')}{' '}
                {arrayToI18nList(authorLinks, t('book.and'))}
            </p>
        );
    }

    const illustratorLinks = makeProfileLinks(
        illustrators,
        linkType.illustratedBy
    );

    const illustratorEl =
        illustratorLinks.length > 0 ? (
            <p
                className={
                    localStorage.getItem('locale') === 'ar' ||
                    localStorage.getItem('locale') === 'ur'
                        ? `${baseClassName}__arabic`
                        : `${baseClassName}`
                }
            >
                {t('global.illustrator', 2)}{' '}
                {arrayToI18nList(illustratorLinks, t('book.and'))}
            </p>
        ) : null;

    const publisherEl = (
        <div className="__publisherSection">
            <p className="__publisherName">{t('global.publisher', 1)}&nbsp;</p>
            <p>
                <Link
                    normal
                    storyCredit={true}
                    isInternal={true}
                    disabled={offline}
                    href={`/publishers/${publisher.slug}`}
                    dataGTM="book-publisher"
                >
                    {publisher.name}
                </Link>{' '}
            </p>
        </div>
    );

    let translatorEl;
    if (translator) {
        const translatorLinks = makeProfileLinks(
            translator,
            linkType.writtenBy
        );

        translatorEl =
            translatorLinks.length > 0 ? (
                <p
                    className={
                        localStorage.getItem('locale') === 'ar' ||
                        localStorage.getItem('locale') === 'ur'
                            ? `${baseClassName}__arabic`
                            : `${baseClassName}`
                    }
                >
                    {t('global.translator')}{' '}
                    {arrayToI18nList(translatorLinks, t('book.and'))}
                </p>
            ) : null;
    }

    const photographerLinks = makeProfileLinks(
        photographers,
        linkType.photographedBy
    );
    const photographerEl =
        photographerLinks.length > 0 ? (
            <p
                className={
                    localStorage.getItem('locale') === 'ar' ||
                    localStorage.getItem('locale') === 'ur'
                        ? `${baseClassName}__arabic`
                        : `${baseClassName}`
                }
            >
                {t('Book.photograph-by')}{' '}
                {arrayToI18nList(photographerLinks, t('book.and'))}
            </p>
        ) : null;

    let originalAuthorsEl = null;
    if (type === 'adaptation' && isOriginalStoryPresent) {
        const originalAuthorsLinks = makeProfileLinks(
            originalAuthors,
            linkType.originalStory
        );
        originalAuthorsEl = (
            <p
                className={
                    localStorage.getItem('locale') === 'ar' ||
                    localStorage.getItem('locale') === 'ur'
                        ? `${baseClassName}__arabic`
                        : `${baseClassName}`
                }
            >
                {t('Book.original-story')}{' '}
                <Link
                    normal
                    storyCredit={true}
                    isInternal={true}
                    disabled={offline}
                    href={links.bookDetails(originalSlug)}
                    dataGTM="book-author"
                >
                    {originalTitle}
                </Link>{' '}
                {t('global.by')}{' '}
                {arrayToI18nList(originalAuthorsLinks, t('book.and'))}
            </p>
        );
    }

    return (
        <div
            className={
                localStorage.getItem('locale') === 'ar' ||
                localStorage.getItem('locale') === 'ur'
                    ? `${baseClassName}-credits-container-arabic`
                    : `${baseClassName}-credits-container`
            }
        >
            {landingPageBook && !isMobileWidth
                ? mainAuthorsEl
                : !landingPageBook
                ? mainAuthorsEl
                : null}
            {landingPageBook && isTablet()
                ? originalAuthorsEl
                : landingPageBook
                ? originalAuthorsEl
                : null}
            {landingPageBook && !isMobileWidth
                ? illustratorEl
                : !landingPageBook
                ? illustratorEl
                : null}
            {photographerEl}
            {type === 'adaptation' ? translatorEl : ''}
            {publisherEl}
        </div>
    );
};

Credits.propTypes = {
    isTranslation: PropTypes.bool,
    isRelevelled: PropTypes.bool,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    publisher: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }),
    illustrators: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    photographers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    originalTitle: PropTypes.string,
    originalSlug: PropTypes.string,
    originalAuthors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    t: PropTypes.func.isRequired,
    offline: PropTypes.bool,
    onProfileLinkClicked: PropTypes.func,
    isRightToLeftDirection: PropTypes.bool,
    landingPageBook: PropTypes.bool,
    translator: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    type: PropTypes.string.isRequired,
    isOriginalStoryPresent: PropTypes.bool,
};

export default Credits;
