import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Joyride from 'react-joyride';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import u from 'updeep';
import withRouter from "../../util/withRouter";
import Block from '../Block';
import LoaderBlock from '../LoaderBlock';
import Pagination from '../Pagination';
import ResourceCardContainer from '../ResourceCardContainer';
import SearchModal from '../SearchModal';
import TitleImage from '../TitleImage';
import { handleFetchMeAndResources, handleFetchResources, onSearch } from './actions';
import BannerComponent from './BannerComponent';
import FiltersBarComponent from './FiltersBarComponent';
import { mapDispatchToProps, mapStateToProps } from './reduxBindings';
import './ResourceContainer.scss';
import { getAppliedFilters, handleApplyFilter, handleMobileView, handleRemoveFilter } from './utils';

const ResourceContainer = ({
    t,
    references,
    viewport,
    resourceHits,
    filters,
    totalReferencesCount,
    isFetchingMoreReferences,
    fetchAllReferencesFiltersWorkflow,
    fetchReferencesWorkflow,
    fetchMeWorkflow,
    isLoggedIn,
    helpStatus,
    location,
    navigate,
    loadedPages,
    history,
}) => {
    const [filtersSearchText, setFiltersSearchText] = useState({
        category: '',
        role: '',
    });
    const [isResourceMobile, setIsResourceMobile] = useState(false);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    useEffect(() => {
        handleMobileView(setIsResourceMobile);
        // window.addEventListener('resize', handleMobileView);

        if (isLoggedIn) {
            handleFetchResources(location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow);
        } else {
            fetchMeAndReferences();
        }
    }, [isLoggedIn]);

    const routerLocation = useLocation();

    useEffect(() => {
        if (routerLocation.search === '') {
            handleMobileView(setIsResourceMobile);
            handleFetchResources(location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow);
        }
    }, [location.search]);

    const updateFiltersSearchText = (key, text) =>
        setFiltersSearchText((prev) => u({ [key]: text }, prev));

    const fetchMeAndReferences = async () => await handleFetchMeAndResources(fetchMeWorkflow, location, navigate, fetchReferencesWorkflow, fetchAllReferencesFiltersWorkflow, history)
    const applyFilter = (type, value) => handleApplyFilter(type, value, location, navigate, fetchReferencesWorkflow);
    const removeFilter = (type, value) => handleRemoveFilter(type, value, location, navigate, fetchReferencesWorkflow);
    const onSortOptionChanged = option => handleApplyFilter('sort', option, location, navigate, fetchReferencesWorkflow)
    const onLoadMore = () =>
        fetchReferencesWorkflow(
            getAppliedFilters(location),
            loadedPages + 1
        );

    //Content-Specific Search Function for Vidoes
    const onResourceSearch = (event) => onSearch(event, setIsSearchModalOpen, location, navigate, fetchReferencesWorkflow);

    const onOpenSearchModal = () => setIsSearchModalOpen(true)
    const onCloseSearchModal = () => setIsSearchModalOpen(false)

    const myLanguage = localStorage.getItem('locale');

    const shouldShowLoadMore =
        loadedPages &&
        loadedPages < totalReferencesCount;

    let appliedFilters = getAppliedFilters(location);

    const isRightToLeftDirection = myLanguage === 'ar' || myLanguage === 'ur';

    if (!filters) {
        return <LoaderBlock />;
    }

    let steps = [
        {
            target: '.__bookFilter',
            content: t('Resources.filterHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-filters-bar__actions--secondary',
            content: t('Resources.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Resources.favoriteHelpGuide'),
            placementBeacon: 'left',
        },
    ];

    let mobileSteps = [
        myLanguage === "ar" || myLanguage === "ur" ?
            {
                target: '.pb-filters-bar__filter-option-rtl',
                content: t('Resources.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
            :
            {
                target: '.pb-filters-bar__filter-option',
                content: t('Resources.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
        {
            target: '.pb-filters-bar__sort-option',
            content: t('Resources.sortHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-link--normal',
            content: t('Resources.favoriteHelpGuide'),
            placementBeacon: 'left',
        },
    ];

    return (
        <div className="__resource-container">
            <Joyride
                enabled={true}
                steps={isResourceMobile ? mobileSteps : steps}
                continuous
                showProgress
                run={helpStatus}
                scrollToFirstStep
                scrollOffset={250}
                disableScrolling={true}
                locale={{
                    next: t('global.Next'),
                    back: t('global.Back'),
                    last: t('global.Last'),
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: '#e3ffeb',
                        textColor: '#004a14',
                        overlayColor: 'rgba(0, 0, 0, 0.8)',
                        primaryColor: '#007AA4',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                        zIndex: 1000,
                    },
                }}
            />
            <BannerComponent />
            <DocumentTitle
                title={`${t('global.resources')} - ${t(
                    'global.site-title'
                )}`}
            />
            <Block verticalMargin={true} background="transparent">
                <div className="resources-header">
                    <div className="__resources-title">
                        <h3>{t('global.resources')}</h3>
                        <h3
                            className={
                                isRightToLeftDirection
                                    ? '__total-margin-left'
                                    : '__normal'
                            }
                        >
                            ({resourceHits})
                        </h3>
                    </div>
                    <div
                        className={'__resources-search'}
                        onClick={onOpenSearchModal}
                    >
                        <TitleImage
                            className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                            svgName={require('../../assets/search_tl.svg')}
                            title={t('resources.search')}
                            contentSpecificSearch
                        />
                    </div>
                </div>
            </Block>
            {references && references.length >= 0 ? (
                <FiltersBarComponent
                    filters={filters}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    appliedFilters={getAppliedFilters(location)}
                    onSortOptionChanged={onSortOptionChanged}
                    isResourceMobile={isResourceMobile}
                    filtersSearchText={filtersSearchText}
                    updateFiltersSearchText={updateFiltersSearchText}
                    totalReferencesCount={totalReferencesCount}
                    t={t}
                />
            ) : null}
            <div className="__resource-card-section">
                <ResourceCardContainer
                    isLoggedIn={isLoggedIn}
                    myLanguage={myLanguage}
                    References={references}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    onSortOptionChanged={onSortOptionChanged}
                    tags={appliedFilters.tags}
                    appliedFilters={{ ...appliedFilters }}
                />
                {shouldShowLoadMore ? (
                    <Pagination
                        onClick={onLoadMore}
                        loading={isFetchingMoreReferences}
                        dataGTM="references-view-more"
                    />
                ) : null}
            </div>
            {isSearchModalOpen ? (
                <SearchModal
                    viewport={viewport}
                    onClose={onCloseSearchModal}
                    onSubmit={onResourceSearch}
                />
            ) : null}
        </div>
    );
}

ResourceContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate()(ResourceContainer)));