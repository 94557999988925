import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '../SvgIcon';
import './Loader.scss';

const Loader = ({ size = 'm', parentClassName }) => {

    const baseClassName = 'pb-loader';
    const classNames = [`${baseClassName}`, `${baseClassName}--${size}`];

    if (parentClassName) {
        classNames.push(parentClassName);
    }

    return (
        <div className={classNames.join(' ')}>
            <SvgIcon
                parentClassName={`${baseClassName}__icon`}
                size={size}
                name="loader"
            />
        </div>
    );
}

Loader.propTypes = {
    parentClassName: PropTypes.string,
    /* Default size is m */
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
};

export default Loader;
