import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import './AddBooksContainer.scss';
import Block from '../Block';
import Pagination from '../Pagination';
import AddBooks from '../AddBooks';
import { fetchAllBooksWorkflow } from '../../redux/allBooksActions';
import {
    createMyReadingListWorkflow,
    fetchMyReadingListWorkflow,
    fetchManageReadingListWorkflow,
} from '../../redux/readingListsActions';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import SearchModal from '../SearchModal';
import withRouter from '../../util/withRouter';
import {
    defaultFilterValues,
    languages,
    tabFilters,
} from './constants/constants';
import ErrorModal from './components/ErrorModal';
import AddBooksHeader from './components/AddBooksHeader';

const AddBooksContainer = ({
    fetchAllBooksWorkflow,
    location,
    allFilters,
    applyFilter,
    filterType,
    appliedFilters,
    navigate,
    resetFilters,
    loadedPages,
    newList,
    parentClassName,
    Bookslist,
    t,
    totalBooksPage,
    managingList,
    isFetchingMoreBooks,
    viewport,
}) => {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchAllBooksWorkflow();
    }, []);

    useEffect(() => {
        const { tab, item, ...queryAppliedFilters } = queryString.parse(
            location.search
        );

        const filtersType = tabFilters[tab] || tabFilters[item] || filterType;
        const appliedFilters = allFilters[filtersType];
        const updatedFilters = { ...appliedFilters, ...queryAppliedFilters };

        Object.entries(updatedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilterValues[k]) && !Array.isArray(v)) {
                updatedFilters[k] = [v];
            }
        });
        applyFilter({ filterType: filtersType, filter: updatedFilters });
    }, [location]);

    useEffect(() => {
        fetchAllBooksWorkflow(appliedFilters);
    }, [appliedFilters]);

    const { query, tags } = appliedFilters;
    const { tab } = queryString.parse(location.search);

    const onOpenSearchModal = () => setIsSearchModalOpen(true);
    const onCloseSearchModal = () => setIsSearchModalOpen(false);

    const nextPath = (path) => {
        navigate(path);
    };

    const applyFilters = (type, value) => {
        const filterIsArray = Array.isArray(appliedFilters[type]);
        if (filterIsArray) {
            value = appliedFilters[type].concat(value);
        }
        let updatedField = { [type]: value };
        const newFilters = queryString.stringify({
            ...appliedFilters,
            ...updatedField,
            tab: getTab(),
        });

        navigate({ search: `?${newFilters}` }, { replace: true });
        applyFilter({ filter: updatedField, filterType: filterType });
    };

    const removeFilter = (type, value) => {
        if (!appliedFilters[type]) {
            return;
        }
        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            value = [...appliedFilters[type]];
            if (index !== -1) {
                value.splice(index, 1);
            }
        } else {
            value = '';
        }
        let updatedField = { [type]: value };

        const searchParams = queryString.stringify({
            ...appliedFilters,
            ...updatedField,
            tab: getTab(),
        });
        navigate({ search: `?${searchParams}` }, { replace: true });
        applyFilter({ filter: updatedField, filterType: filterType });
    };

    const baseClassName = 'pb-add-books-container';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const getTab = () => {
        return queryString.parse(location.search)['tab'];
    };

    const onSortOptionChanged = (option) => applyFilters('sort', option);

    const onTabChange = (newTab) => {
        const filterType = tabFilters[newTab];
        const filters = { query: query, tags: tags };
        applyFilter({ filterType: filterType, filter: filters });

        const searchParams = queryString.stringify({
            ...allFilters[filterType],
            ...filters,
            tab: newTab,
        });
        navigate({ search: `?${searchParams}` }, { replace: true });
    };

    const onLoadMore = () => {
        fetchAllBooksWorkflow(appliedFilters, loadedPages + 1);
    };

    const getAppliedFilters = () => {
        const parsedFilters = queryString.parse(window.location.search);
        let currentLanguage = localStorage.getItem('locale');

        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilterValues[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        let translatedLanguage = currentLanguage && languages[currentLanguage];
        if (!translatedLanguage) {
            translatedLanguage = 'English';
        } else {
            translatedLanguage = languages[currentLanguage];
        }

        defaultFilterValues.language = [translatedLanguage];

        return {
            ...defaultFilterValues,
            ...parsedFilters,
        };
    };

    const onBookSearch = (searchQueryValue) => {
        let appliedFilters = getAppliedFilters();
        setIsSearchModalOpen(false);

        appliedFilters.query = searchQueryValue;
        const searchParams = queryString.stringify(appliedFilters);
        navigate({ search: `?${searchParams}` }, { replace: true });
        fetchAllBooksWorkflow(appliedFilters);
    };

    const shouldShowLoadMore = loadedPages && loadedPages < totalBooksPage;

    return (
        <div className={classNames(classes)}>
            <DocumentTitle
                title={`${t('Readinglist.add-books')} - ${t(
                    'global.site-title'
                )}`}
            />
            <Block background="transparent">
                {showModal && (
                    <ErrorModal
                        t={t}
                        errorMessage={errorMessage}
                        setShowModal={setShowModal}
                    />
                )}
                <AddBooksHeader
                    t={t}
                    onOpenSearchModal={onOpenSearchModal}
                    newList={newList}
                    baseClassName={baseClassName}
                    disable={disable}
                    managingList={managingList}
                    setDisable={setDisable}
                    nextPath={nextPath}
                    setShowModal={setShowModal}
                    setErrorMessage={setErrorMessage}
                />
            </Block>

            <AddBooks
                BooksList={Bookslist}
                applyFilter={applyFilters}
                removeFilter={removeFilter}
                onSortOptionChanged={onSortOptionChanged}
                onTabChange={onTabChange}
                tags={appliedFilters.tags}
                appliedFilters={{ ...appliedFilters, tab: tab }}
                isAddBook
                newList={newList}
            />
            {shouldShowLoadMore && (
                <Pagination
                    onClick={onLoadMore}
                    loading={isFetchingMoreBooks}
                    dataGTM="addbooks-view-more"
                />
            )}
            {isSearchModalOpen ? (
                <SearchModal
                    viewport={viewport}
                    onClose={onCloseSearchModal}
                    onSubmit={onBookSearch}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = ({ allBooks, viewport, allFilters, readingLists }) => {
    return {
        Bookslist: allBooks.books,
        isFetchingMoreBooks: allBooks.isFetchingMoreBooks,
        totalBooksPage: allBooks.totalBooksPage,
        loadedPages: allBooks.loadedPages,
        appliedFilters: allFilters[allFilters.filterType],
        filterType: allFilters.filterType,
        newList: readingLists.newList,
        managingList: readingLists.managingList,
        allFilters,
        viewport,
        totalBooksCount: allBooks.totalBooksCount,
    };
};

const mapDispatchToProps = {
    fetchAllBooksWorkflow,
    createMyReadingListWorkflow,
    fetchMyReadingListWorkflow,
    fetchManageReadingListWorkflow,
    applyFilter,
    resetFilters,
};

AddBooksContainer.propTypes = {
    fetchAllBooksWorkflow: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    allFilters: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    filterType: PropTypes.string.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    loadedPages: PropTypes.number.isRequired,
    newList: PropTypes.bool.isRequired,
    parentClassName: PropTypes.string,
    Bookslist: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    totalBooksPage: PropTypes.number.isRequired,
    managingList: PropTypes.bool.isRequired,
    isFetchingMoreBooks: PropTypes.bool.isRequired,
    viewport: PropTypes.object.isRequired,
};

export default withRouter(
    translate()(connect(mapStateToProps, mapDispatchToProps)(AddBooksContainer))
);
