export const helpGuideSteps = (t) => ({
    steps: [
        {
            target: '.pb-book__favouriteCount',
            content: t('Book.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-book__offline-container',
            content: t('Book.saveOfflineHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__booktags',
            content: t('Book.bookTagsHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__comments',
            content: t('Book.disqusHelpGuide'),
            placementBeacon: 'left',
        },
    ],
    mobileSteps: [
        {
            target: '.book-like--count-wrapper',
            content: t('Book.favouriteHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-book__offline-container',
            content: t('Book.saveOfflineHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__booktags',
            content: t('Book.bookTagsHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.__comments',
            content: t('Book.disqusHelpGuide'),
            placementBeacon: 'left',
        },
    ],
});
