import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    toggleMenuState,
    closeMenu,
    openMenu,
} from '../../redux/menuToggleActions';
import useClickOutside from '../../hooks/useClickOutside';
import './Dropdown.scss';

const mapStateToProps = (state) => ({
    isDropdownOpen: state.menuToggle.ismenuOpen,
});

const mapDispatchToProps = {
    toggleMenuState,
    closeMenu,
    openMenu,
};

const Dropdown = ({
    children,
    parentClassName,
    toggleClassName,
    align = 'right',
    toggleEl,
    noPadding,
    disabled,
    minWidth = 'm',
    translation,
    menu = false,
    bookFilter,
    readAloudShare,
    bookTabletMode,
    isDropdownOpen: isMenuDropdownOpen, // Redux state
    openMenu,
    closeMenu,
    onClose,
}) => {
    const [localDropdownOpen, setLocalDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // Ref for the dropdown

    const isRightToLeftDirection =
        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur';

    const isDropdownOpen = menu ? isMenuDropdownOpen : localDropdownOpen;

    const openDropdown = useCallback(() => {
        if (menu) {
            openMenu();
        } else {
            setLocalDropdownOpen(true);
        }
        if (onClose) onClose();
    }, [menu, openMenu, onClose]);

    const closeDropdown = useCallback(() => {
        if (menu) {
            closeMenu();
        } else {
            setLocalDropdownOpen(false);
        }
        if (onClose) onClose();
    }, [menu, closeMenu, onClose]);

    const toggleDropdown = useCallback(
        (e) => {
            e.preventDefault();
            if (disabled) return;

            if (isDropdownOpen) {
                closeDropdown();
            } else {
                openDropdown();
            }
        },
        [disabled, isDropdownOpen, closeDropdown, openDropdown]
    );

    useEffect(() => {
        if (disabled) {
            closeDropdown();
        }
    }, [disabled, closeDropdown]);

    useClickOutside(dropdownRef, () => {
        if (isDropdownOpen) {
            closeDropdown();
        }
    });

    const childerenClasses = {
        [`pb-dropdown--menu`]: menu,
        [isRightToLeftDirection
            ? 'pb-dropdown__contents-arabic'
            : 'pb-dropdown__contents']: true,
        [isRightToLeftDirection
            ? 'pb-dropdown__download-rtl'
            : 'pb-dropdown__download']: bookTabletMode,
    };

    const baseClassName = 'pb-dropdown';
    const baseTransitionDuration = 100;

    let dropdownListEl;
    if (isDropdownOpen) {
        dropdownListEl = (
            <CSSTransition
                key="pb-dropdown__content"
                classNames={
                    isRightToLeftDirection
                        ? 'pb-dropdown__contents-arabic'
                        : 'pb-dropdown__contents'
                }
                timeout={baseTransitionDuration}
            >
                <div className={classNames(childerenClasses)}>{children}</div>
            </CSSTransition>
        );
    }

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--open`]: isDropdownOpen,
        [parentClassName]: parentClassName,
        [`${baseClassName}--align-${align}`]: align,
        [`${baseClassName}--min-width-${minWidth}`]: minWidth,
        [`${baseClassName}--no-padding`]: noPadding,
        [`${baseClassName}--translation`]: translation,
        [`${baseClassName}--book-filter`]: bookFilter,
        [`${baseClassName}--read-aloud`]: readAloudShare,
    };

    const toggleElClassNames = {
        [`${baseClassName}__toggle`]: true,
        [toggleClassName]: toggleClassName,
    };

    return (
        <div className={classNames(classes)} ref={dropdownRef}>
            <div
                className={classNames(toggleElClassNames)}
                onClick={toggleDropdown}
            >
                {toggleEl && React.cloneElement(toggleEl, { disabled })}
            </div>
            <TransitionGroup>{dropdownListEl}</TransitionGroup>
        </div>
    );
};

Dropdown.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    parentClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    noPadding: PropTypes.bool,
    minWidth: PropTypes.oneOf(['m', 'l', 'xl', 'xxl', 'auto']),
    align: PropTypes.oneOf(['right', 'left']),
    disabled: PropTypes.bool,
    menu: PropTypes.bool,
    isDropdownOpen: PropTypes.bool,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
    onClose: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
