import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import CreateNewReadingList from '../../CreateNewReadingList';

const EditReadingListModal = ({
    t,
    closeEditModal,
    readingListsBookDetail,
    updateReadingListDetailsWorkflow,
    navigate,
    fetchReadingListBooksWorkflow,
}) => {
    const handleEditSubmit = (updates = {}) => {
        closeEditModal();
        // Send updated reading list details to backend API
        const updatedData = {
            ...updates,
            id: readingListsBookDetail.id,
        };

        updateReadingListDetailsWorkflow(updatedData)
            .then((result) => {
                const { id, title = '' } = result.data.list;
                const newSlug = `${id}-${title
                    .toLowerCase()
                    .split(' ')
                    .join('-')}`;

                navigate.push(`/myreadinglistsdetail/${newSlug}`); // Update url with the new slug value
                fetchReadingListBooksWorkflow(newSlug); // Fetch updated details
            })
            .catch((err) => {
                console.log(
                    'Error: Updating title/description of a reading list'
                );
            });
    };

    return (
        <Modal>
            <div className="__edit-button-section">
                <CreateNewReadingList
                    edit
                    editSubmit={handleEditSubmit}
                    listDetails={readingListsBookDetail}
                />
                <span
                    className="edit-cancel-button"
                    onClick={closeEditModal}
                    data-gtm="no-button"
                >
                    X
                </span>
            </div>
        </Modal>
    );
};

EditReadingListModal.propTypes = {
    t: PropTypes.func.isRequired,
    closeEditModal: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    updateReadingListDetailsWorkflow: PropTypes.func.isRequired,
    navigate: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
};

export default EditReadingListModal;
