import React from 'react';
import PropTypes from 'prop-types';
import SectionBlock from '../../SectionBlock';
import BookShelf from '../../BookShelf';
import { sectionClicked } from '../../../lib/constants';

const SimilarBooks = ({
    offline,
    similarBooks,
    t,
    viewport,
    myLanguage,
    isBookReaderPage = false,
    lastPage,
}) => {
    if (offline || !similarBooks || !similarBooks.length) {
        return null;
    }

    return (
        <SectionBlock
            background="transparent"
            title={t('Book.related-books')}
            bookDetails
        >
            <BookShelf
                myLanguage={myLanguage}
                books={similarBooks}
                viewport={viewport}
                sectionClicked={sectionClicked.similarBooks}
                offline={offline}
                isBookReaderPage={isBookReaderPage}
                lastPage={lastPage}
            />
        </SectionBlock>
    );
};

SimilarBooks.propTypes = {
    offline: PropTypes.bool.isRequired,
    similarBooks: PropTypes.arrayOf(PropTypes.object).isRequired,
    t: PropTypes.func.isRequired,
    viewport: PropTypes.string,
    myLanguage: PropTypes.string.isRequired,
    isBookReaderPage: PropTypes.bool,
    lastPage: PropTypes.number,
};

export default SimilarBooks;
