import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isRightToLeftDirection } from '../../../util/utils';

const InputLabel = ({
    baseClassName,
    searchInput,
    createNewReadingList,
    id,
    label,
}) => {
    const labelClass = {
        [isRightToLeftDirection
            ? `${baseClassName}__label-arabic`
            : `${baseClassName}__label`]: true,
        [`${baseClassName}__label-search`]: searchInput,
        [`${baseClassName}__createNewReadingList`]: createNewReadingList,
    };
    return (
        <label className={classNames(labelClass)} htmlFor={id}>
            {label}
        </label>
    );
};

InputLabel.propTypes = {
    baseClassName: PropTypes.string.isRequired,
    searchInput: PropTypes.bool,
    createNewReadingList: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default InputLabel;
