import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';

const BookSummaryModal = ({ onCloseModal, bookData }) => {
    return (
        <Modal header={'Book Summary'} footer={''} onClose={onCloseModal}>
            <p>{bookData.synopsis}</p>
        </Modal>
    );
};

BookSummaryModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    bookData: PropTypes.shape({
        synopsis: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookSummaryModal;
