import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';

import Logo from '../Logo';
import SiteNavLink from '../SiteNavLink';
import Loader from '../Loader';
import SearchModal from '../SearchModal';
import Avatar from '../Avatar';
import UserInformation from '../UserInformation';
import TitleImage from '../TitleImage';
import classNames from 'classnames';
import SelectCountry from '../SelectCountry';
import { links } from '../../lib/constants';
import localisationIcon from '../../assets/localise_dc.svg';
import './SiteHeader.scss';
import searchDC from '../../assets/search_dc.svg'
import { getNavLinks } from '../../helpers/MenuItems'
import { getLanguageList } from './constants'
import LargeScreenHeader from './LargeScreenHeader/LargeScreenHeader';
import SmallScreenHeader from './SmallScreenHeader/SmallScreenHeader';
import { helpStatusActionAsync } from '../../redux/helpActions';

const SiteHeader = props => {
    let { t, isLoggedIn, user, isFetchingMe, viewport, offline, menuList, helpStatus, logout, changeLanguage, helpStatusActionAsync } = props;

    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const onMenuToggle = () => setMenuOpen(!menuOpen);

    const onLogout = () => {
        localStorage.setItem('status', false);
        logout().then(() => {
            window.location.href = links.home();
        });
    };

    const searchModalToggle = () => setSearchModalOpen(!isSearchModalOpen)
    const onOpenSearchModal = () => setSearchModalOpen(true)

    const handleMenuClose = () => setMenuOpen(false);
    const onOpenHelpGuide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const str = window.location.pathname.split('/');
        if (str[1] !== '') {
            handleMenuClose();
        }

        helpStatusActionAsync(
            helpStatus !== undefined ? !helpStatus : helpStatus
        );
    };

    const siteNavLinks = getNavLinks({
        t,
        menuList,
        viewport,
        user,
        isLoggedIn,
        onOpenHelpGuide,
        isHeader: true,
    })

    const onSearch = (query) => {
        if (query) {
            window.location.href = links.searchBy(query);
        } else {
            window.location.href = links.allBooks(
                void 0,
                user.languagePreferences,
                user.readingLevels
            );
        }
    };

    const baseClassName = 'pb-site-header';
    const classname = [baseClassName];

    const isRightToLeftDirection = ['ar', 'ur'].includes(localStorage.getItem('locale'));

    const avatarEl = (
        <Avatar
            parentClassName={`${baseClassName}__avatar`}
            url={user.profileImage}
            variant={'circular'}
            size={viewport.large ? 'm' : 's'}
            marginRight={!viewport.large}
        />
    );

    const loginLinkClass = [
        isRightToLeftDirection
            ? `${baseClassName}__box-right-login-arabic`
            : `${baseClassName}__box-right`,
        `${baseClassName}__no-padding`,
    ];

    const signUpButtonClass = [
        isRightToLeftDirection
            ? `${baseClassName}__box-right-arabic`
            : `${baseClassName}__box-right`,
    ];

    let languageSelectEl;
    languageSelectEl = (
        <SelectCountry
            parentClassName={loginLinkClass.join(
                `${baseClassName}__third-row-language`
            )}
            options={getLanguageList(localisationIcon)}
            onChange={changeLanguage}
            value={
                !localStorage.getItem('locale')
                    ? 'en'
                    : localStorage.getItem('locale')
            }
        />
    );

    if (menuOpen && !viewport.large) {
        classname.push(`${baseClassName}--menu-open`);
    }

    let userInfoEls = null;
    if (isFetchingMe) {
        userInfoEls = (
            <div className={`${baseClassName}__link-placeholder`}>
                <Loader />
            </div>
        );

    } else if (isLoggedIn) {
        userInfoEls = (
            <UserInformation
                title={user.first_name}
                avatar={avatarEl}
                caret="down"
                variant="deep-charcoal"
                userID={user.id}
                roles={user.roles}
                userEmail={user.email}
                logoutHandler={onLogout}
                shouldHide
            />
        );
    } else {
        userInfoEls = process.env.REACT_APP_FEATURE_AUTH
            ? [
                <SiteNavLink
                    key="sign-up-log-in"
                    respondFontSize
                    dataGTM="nav-sign-up"
                >
                    {t('global.sign-up-log-in')}
                </SiteNavLink>,
            ]
            : [
                <div className={loginLinkClass.join(' ')}>
                    <SiteNavLink
                        key="log_in"
                        light
                        respondFontSize
                        dataGTM="nav-log-in"
                        href={links.login()}
                        buttonTitle={t('global.log-in')}
                    />
                </div>,
                <div className={signUpButtonClass.join(' ')}>
                    <SiteNavLink
                        key="sign_up"
                        bold
                        dark
                        respondFontSize
                        dataGTM="nav-sign-up"
                        href={links.signup()}
                    >
                        {t('global.sign-up')}
                    </SiteNavLink>
                </div>,
            ];
    }

    userInfoEls = viewport.large ? userInfoEls : null;

    let searchEl = !isFetchingMe
        ? [
            <SiteNavLink
                key="search"
                //   onClick={onOpenSearchModal}
                respondWidth
                search
                variant={
                    viewport.large ? 'default' : 'bordered'
                }
                dataGTM="nav-search"
            >
                <img src={searchDC} alt="search" style={{ height: '18px', width: 'auto' }} />{' '}
                {viewport.large ? null : t('global.search')}
            </SiteNavLink>,
        ]
        : null;

    const searchClass = [
        `${baseClassName}__box-right`,
        `${baseClassName}__search`,
    ];

    const languageClass = [`${baseClassName}__language-container`];

    const searchMenuEl = (
        <div
            className={`${baseClassName}--menu-search`}
            onClick={onOpenSearchModal}
        >
            <TitleImage
                svgName={require('../../assets/search-white.svg')}
                title={t('global.search')}
                mobileMenuSearch
            />
        </div>
    );

    let menuEl = [];
    if (menuOpen) {
        if (isLoggedIn) {
            const userInfoMenuEl = [
                <UserInformation
                    title={user.first_name}
                    avatar={avatarEl}
                    caret="down"
                    userID={user.id}
                    roles={user.roles}
                    userEmail={user.email}
                    logoutHandler={onLogout}
                    mobileMenu
                />,
            ];
            menuEl = [...menuEl, userInfoMenuEl];
        }
        menuEl = [
            ...menuEl,
            siteNavLinks.map((child) => (
                child ? <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}--menu--row-rtl`
                            : `${baseClassName}--menu--row`
                    }
                >
                    <div />
                    {child}
                    <div className={`${baseClassName}--separator`} />

                </div> : null
            )),
        ];
    }

    const menuContainerClass = `${baseClassName}--menu-container`;

    const menuLoginEl = isLoggedIn ? null : (
        <div className={`${menuContainerClass}-login`}>
            <SiteNavLink
                key="log_in"
                light
                respondFontSize
                href={links.login()}
                buttonTitle={t('global.log-in')}
                logInButton
                dataGTM="nav-log-in"
            />
            <div className={`${menuContainerClass}-signup`}>
                <p className={`${menuContainerClass}-need-help`}>
                    {t('global.new-user')}
                </p>
                <SiteNavLink
                    key="sign_up"
                    bold
                    light
                    respondFontSize
                    dataGTM="nav-sign-up"
                    href={links.signup()}
                >
                    {t('global.sign-up')}
                </SiteNavLink>
            </div>
        </div>
    );

    const rightClasses = {
        [`${baseClassName}__right`]: true,
        [`${baseClassName}__hidden`]: offline,
    };

    return (
        <div>
            <div className={classname.join(' ')}>
                <div className={`${baseClassName}__left`}>
                    {menuOpen && !viewport.large ? null : (
                        <Logo
                            href={links.home()}
                            parentClassName={`${baseClassName}__logo`}
                        />
                    )}
                </div>
                {viewport.large ? (
                    <LargeScreenHeader
                        classNames={classNames}
                        baseClassName={baseClassName}
                        offline={offline}
                        siteNavLinks={siteNavLinks}
                        searchEl={searchEl}
                        searchClass={searchClass}
                        searchModalToggle={searchModalToggle}
                        rightClasses={rightClasses}
                        languageClass={languageClass}
                        languageSelectEl={languageSelectEl}
                        menuOpen={menuOpen}
                        onMenuToggle={onMenuToggle}
                        userInfoEls={userInfoEls}
                    />
                ) : (
                    <SmallScreenHeader
                        baseClassName={baseClassName}
                        menuOpen={menuOpen}
                        languageSelectEl={languageSelectEl}
                        onMenuToggle={onMenuToggle}
                    />
                )}
                {!offline && isSearchModalOpen && (
                    <SearchModal
                        viewport={viewport}
                        onClose={searchModalToggle}
                        onSubmit={onSearch}
                    />
                )}
            </div>
            {!menuOpen || viewport.large ? null : (
                <div className={menuContainerClass} onClick={onMenuToggle}>
                    {searchMenuEl}
                    {menuEl}
                    {menuLoginEl}
                </div>
            )}
        </div>
    );
}

SiteHeader.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string,
    }),
    viewport: PropTypes.object,
    logout: PropTypes.func.isRequired,
    offline: PropTypes.bool,
};

const mapStateToProps = ({ viewport, user, help }) => {
    return {
        viewport,
        menuList: user.menuList,
        helpStatus: help ? help.helpStatus : undefined,
    };
};

const mapDispatchToProps = {
    helpStatusActionAsync,
};

export default translate()(connect(mapStateToProps, mapDispatchToProps)(SiteHeader));