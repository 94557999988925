import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { isRightToLeftDirection } from '../../../util/utils';
import './PostComment.scss';

const PostComment = ({ parentClassName, id, t, postComment, postReply }) => {
    const [comment, setComment] = useState('');
    const baseClassName = 'pb-disqus-component';

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handlePostComment = (id, e, source) => {
        if (source === 'fromButton' && comment) {
            setComment('');
            if (postComment) {
                postComment(comment);
            } else {
                postReply(id, comment);
            }
            document.getElementById('commentTextField').blur();
        } else if (e.which === 13 && comment) {
            setComment('');
            if (postComment) {
                postComment(e.target.value);
            } else {
                postReply(id, e.target.value);
            }
            e.target.blur();
        }
    };

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)}>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__image-input-wrapper-rtl`
                        : `${baseClassName}__image-input-wrapper`
                }
            >
                <div className={`${baseClassName}__input-container`}>
                    <textarea
                        id="commentTextField"
                        className={`${baseClassName}__input`}
                        onChange={handleCommentChange}
                        value={comment}
                    ></textarea>
                </div>
            </div>
            <Button
                label={t('comments.post')}
                variant="postComment"
                onClick={() => handlePostComment(id, null, 'fromButton')}
            />
        </div>
    );
};

PostComment.propTypes = {
    parentClassName: PropTypes.string,
    id: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    postComment: PropTypes.func,
    postReply: PropTypes.func,
};

export default PostComment;
