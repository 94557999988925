import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import ShareMenu from '../../ShareMenu';
import { isRightToLeftDirection } from '../../../util/utils';

const MyReadingListDetailsInfo = ({ readingListsBookDetail }) => {
    return (
        <Block background="transparent">
            <ContentStyler>
                <div className="__header-section">
                    <div className="__title">
                        <h3>{readingListsBookDetail.title}</h3>
                        <h3 className="__readinglist-count">
                            ({readingListsBookDetail.count})
                        </h3>
                    </div>
                </div>
            </ContentStyler>
            {/* Socail Sharing for Reading List */}
            <div className="__socialShare-container">
                <ShareMenu
                    readingList
                    align="left"
                    title={readingListsBookDetail.title}
                    desc={readingListsBookDetail.description}
                    href={window.location.href}
                />
            </div>
            <div
                className={
                    isRightToLeftDirection
                        ? '__readinglist-header-text-arabic'
                        : '__readinglist-header-text'
                }
            >
                {readingListsBookDetail.description}
            </div>
        </Block>
    );
};

MyReadingListDetailsInfo.propTypes = {
    readingListsBookDetail: PropTypes.shape({
        title: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

export default MyReadingListDetailsInfo;
