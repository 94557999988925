import React from 'react';
import PropTypes from 'prop-types';

const LanguageIndicator = ({ language, baseClassName }) => {
    return (
        <div className={`${baseClassName}__language-indicator-container`}>
            <div className={`${baseClassName}__language-indicator`}>
                {language}
            </div>
        </div>
    );
};

LanguageIndicator.propTypes = {
    language: PropTypes.string.isRequired,
    baseClassName: PropTypes.string,
};

export default LanguageIndicator;
