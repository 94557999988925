import React from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../../../assets/closeIcon.png';
import Modal from '../../../Modal';
import Link from '../../../Link';
import VideoPlayer from '../../../VideoPlayer';

const ReadToMeModal = ({ readToMeModalClose, book, isReadToMeVideoPlay }) => {
    return (
        <Modal>
            <div className="__close">
                <Link onClick={() => readToMeModalClose()}>
                    <img
                        className="__profile-images"
                        src={closeIcon}
                        alt="close"
                    />
                </Link>
            </div>
            <div>
                <VideoPlayer
                    name={book.readAlouds[0].filename}
                    videoSrc={book.readAlouds[0].url}
                    videoPlay={isReadToMeVideoPlay}
                />
            </div>
        </Modal>
    );
};

ReadToMeModal.propTypes = {
    readToMeModalClose: PropTypes.func.isRequired,
    book: PropTypes.shape({
        readAlouds: PropTypes.arrayOf(
            PropTypes.shape({
                filename: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    isReadToMeVideoPlay: PropTypes.bool.isRequired,
};

export default ReadToMeModal;
