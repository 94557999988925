import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';

const CreateReadingListButton = ({ t, navigate }) => {
    const nextPath = (path) => {
        navigate(path);
    };

    return (
        <div>
            <div className="__reading-list-button">
                <Button
                    buttonBlue
                    fullWidth
                    label={t('Readinglist.create-new-reading-list')}
                    iconLeftOffline="plus"
                    variant="primaryRL"
                    onClick={() =>
                        nextPath('/myreadinglists/createreadinglist')
                    }
                    dataGTM="create-new-reading-list"
                />
            </div>
        </div>
    );
};

CreateReadingListButton.propTypes = {
    t: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default CreateReadingListButton;
