import React from 'react';
import PropTypes from 'prop-types';
import Caret from '../../Caret';
import { isRightToLeftDirection } from '../../../util/utils';
import Link from '../../Link';
import SvgIcon from '../../SvgIcon';

const InputIcon = ({
    isDropDownIcon,
    baseClassName,
    onIconClick,
    disabled,
    size,
    icon,
}) => {
    if (isDropDownIcon) {
        return (
            <Caret
                parentClassName={
                    isRightToLeftDirection
                        ? `${baseClassName}__caret-rtl`
                        : `${baseClassName}__caret`
                }
                direction="down"
            />
        );
    }
    return (
        <Link
            parentClassName={
                isRightToLeftDirection
                    ? `${baseClassName}__icon-wrapper-arabic`
                    : `${baseClassName}__icon-wrapper`
            }
            onClick={onIconClick}
            disabled={disabled || !onIconClick}
            tabIndex={onIconClick ? '0' : '-1'}
        >
            <SvgIcon
                parentClassName={`${baseClassName}__icon`}
                size={size}
                name={icon}
            />
        </Link>
    );
};

InputIcon.propTypes = {
    isDropDownIcon: PropTypes.bool,
    baseClassName: PropTypes.string.isRequired,
    onIconClick: PropTypes.func,
    disabled: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string.isRequired,
};

export default InputIcon;
