import React from 'react';
import PropTypes from 'prop-types';
import useDeviceType from '../../../hooks/useDeviceType';
import './BookDetails.scss';

const GovtApproved = ({ title_logos, isRightToLeftDirection }) => {
    const { isMobile, isLandscapeMobile } = useDeviceType();
    return (
        <div
            className={
                isMobile || isLandscapeMobile
                    ? 'govt-approved-container-mobile'
                    : isRightToLeftDirection
                    ? 'govt-approved-container-rtl'
                    : 'govt-approved-container'
            }
        >
            <img
                className="govt-approved"
                src={require('../../../assets/govt-approved.svg')}
                alt="Govt approved"
            />
            <p>
                <span>{title_logos[0].name}</span>
            </p>
        </div>
    );
};

GovtApproved.propTypes = {
    title_logos: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
};

export default GovtApproved;
