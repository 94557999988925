import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import BookCard from '../../BookCard';

const ThemedReadingListsBody = ({ t, readingListsBookDetail }) => {
    return (
        <div>
            <Grid variant="4up">
                {readingListsBookDetail.books &&
                    readingListsBookDetail.books.map((bookItem) => {
                        return (
                            <div className="__themedBookItem-section">
                                <BookCard
                                    key={bookItem.slug}
                                    id={bookItem.id}
                                    book={bookItem}
                                    isFavourite={bookItem.is_favourite}
                                    isThemedList
                                    isFavouriting={bookItem.isFavouriting}
                                    bookLikes={bookItem.likesCount}
                                    bookImageGTM="themed-book-image"
                                    bookTitleGTM="themed-book-title"
                                    bookAuthorGTM="themed-book-author"
                                    bookIllustratorsGTM="themed-book-Illustrators"
                                    bookLevelGTM="themed-book-Level"
                                />
                            </div>
                        );
                    })}
            </Grid>
        </div>
    );
};

ThemedReadingListsBody.propTypes = {
    t: PropTypes.func.isRequired,
    readingListsBookDetail: PropTypes.shape({
        books: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                is_favourite: PropTypes.bool,
                isFavouriting: PropTypes.bool,
                likesCount: PropTypes.number,
            })
        ),
    }).isRequired,
};

export default ThemedReadingListsBody;
