import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Block from '../Block';
import { isRightToLeftDirection } from '../../util/utils';
import './SectionBlock.scss';

const SectionBlock = ({
    children,
    title,
    noContentHorizontalPadding,
    background,
    theme,
    separateHeader,
    bookDetails,
    interviewDetails,
    viewVideos,
}) => {
    const baseClassName = 'pb-section-block';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--${theme}`]: theme,
        [`${baseClassName}--no-content-horizontal-padding`]: noContentHorizontalPadding,
        [`${baseClassName}--separate-header`]: separateHeader,
        [`${baseClassName}--book-details`]: bookDetails,
        [isRightToLeftDirection
            ? `${baseClassName}--interview-details-rtl`
            : `${baseClassName}--interview-details`]: interviewDetails,
        [`${baseClassName}--view-videos`]: viewVideos,
    };

    return (
        <div className={classNames(classes)}>
            <Block
                background={theme === 'light' ? 'transparent' : background}
            >
                {title && (
                    <div className={`${baseClassName}__header`}>
                        <h2
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__arabic ${baseClassName}__title`
                                    : `${baseClassName}__title`
                            }
                        >
                            {title}
                        </h2>
                    </div>
                )}
                <div className={`${baseClassName}__content`}>{children}</div>
            </Block>
        </div>
    );
}

SectionBlock.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    background: Block.propTypes.background,
    noContentHorizontalPadding: PropTypes.bool,
    theme: PropTypes.string,
    separateHeader: PropTypes.bool,
    bookDetails: PropTypes.bool,
    interviewDetails: PropTypes.bool,
    viewVideos: PropTypes.bool,
};

SectionBlock.defaultProps = {};

export default SectionBlock;
