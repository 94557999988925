import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../Link';

const CreateReadingListButton = ({
    createNewReadingListActionAsync,
    readingListName,
    readingListDesc,
    buttonText,
}) => {
    const addBookSubmitButton = (name, desc) => {
        createNewReadingListActionAsync(name, desc);
    };
    return (
        <div className="__add-book-button">
            <Link
                href={'/myreadinglists/addbooks'}
                isInternal
                paddingTop
                fullWidth
                dataGTM="add-books"
            >
                <span
                    className="__action-button-text"
                    onClick={() =>
                        addBookSubmitButton(readingListName, readingListDesc)
                    }
                >
                    {buttonText}
                </span>
            </Link>
        </div>
    );
};

CreateReadingListButton.propTypes = {
    createNewReadingListActionAsync: PropTypes.func.isRequired,
    readingListName: PropTypes.string.isRequired,
    readingListDesc: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default CreateReadingListButton;
