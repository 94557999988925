import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Button from '../Button';
import './Pagination.scss';

const Pagination = ({ t, label, onClick, loading, dataGTM }) => {
    const baseClassName = 'pb-pagination';
    const classNames = [baseClassName];

    const labelText = label || t('Pagination.load-more');

    return (
        <div className={classNames.join(' ')}>
            <Button
                variant="view-more"
                iconLeft="refresh"
                loading={loading}
                label={labelText}
                onClick={onClick}
                dataGTM={dataGTM}
            />
        </div>
    );
};

Pagination.propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    dataGTM: PropTypes.string,
};

export default translate()(Pagination);
