import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import './ListBox.scss';

// eslint-disable-next-line no-script-url
const noopStr = 'javascript:;';

const ListBox = ({ parentClassName, options, selectedIndex, onSelect }) => {
    const selectedItemRef = useRef(null);

    useEffect(() => {
        if (selectedItemRef.current && selectedIndex !== -1) {
            scrollIntoViewIfNeeded(selectedItemRef.current, false);
        }
    }, [selectedIndex]);

    const onClickHandler = (value) => {
        if (onSelect) {
            onSelect(value);
        }
    };

    const baseClassName = 'pb-list-box';

    const itemEls = options.map((option, i) => {
        const itemClasses = {
            [`${baseClassName}__item`]: true,
            [`${baseClassName}__item--selected`]: i === selectedIndex,
        };

        return (
            <a
                ref={i === selectedIndex ? selectedItemRef : null}
                key={`${baseClassName}__item--${i}`}
                className={classNames(itemClasses)}
                onClick={() => onClickHandler(option)}
                href={noopStr}
            >
                {option}
            </a>
        );
    });

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    return (
        <div className={classNames(classes)}>
            <div className={`${baseClassName}__list`}>{itemEls}</div>
        </div>
    );
};

ListBox.propTypes = {
    parentClassName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    selectedIndex: PropTypes.number,
    onSelect: PropTypes.func,
};

export default ListBox;
